import React, { useState } from "react";
import BorderedTextInput from "../../../../components/common/borderedTextInput";
import BorderedTextArea from "../../../../components/common/borderedTextArea";
import InternationalPhoneField from "../../../../components/common/internationalPhoneField";
import SearchableDropDown from "../../../../components/common/searchableDropDown";
import { getCountiesAction } from "../../../../commonData/counties/action";
import { useDispatch, useSelector } from "react-redux";
import BorderSelectInput from "../../../../components/common/borderSelectInput";

const AccountPrivacyAndSettings = () => {
  const dispatch = useDispatch();

  const countries = useSelector((state) => state.getCountries.payload.data);
  const counties = useSelector((state) => state.getCounties.payload.data);
  const countriesLoading = useSelector((state) => state.getCountries.loading);
  const countiesLoading = useSelector((state) => state.getCounties.loading);
  const [profileInfo, setProfileInfo] = useState({});
  const [formFilled, setFormFilled] = useState(true);

  const countriesOptions = [];
  const countiesOptions = [];

  countries &&
    countries.map((country) =>
      countriesOptions.push({ label: country.name, value: country.id })
    );

  counties &&
    counties.map((county) =>
      countiesOptions.push({ label: county.name, value: county.id })
    );

  const handleChange = (e) => {
    setProfileInfo({ ...profileInfo, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (phone) => {
    setProfileInfo({ ...profileInfo, phoneNumber: phone });
  };

  const handleNationalityChange = (code) => {
    setProfileInfo({ ...profileInfo, countryId: code });
  };

  const handleCountryChange = (code) => {
    dispatch(getCountiesAction(code));
  };

  const handleCountyChange = (countyId) => {
    setProfileInfo({ ...profileInfo, countyId: countyId });
  };

  const [switchToggle, setSwitchToggle] = useState(false);

  const handleToggle = () => {
    setSwitchToggle(!switchToggle);
  };

  return (
    <div className="surface rounded-md w-full">
      <div className="pt-12 pb-6 lg:pt-14 px-6 border-b-2 border-app-colors-border dark:border-app-colors-border-d">
        <h6>Account Privacy and Verification</h6>
      </div>
      <div className="px-6 py-8 flex flex-col gap-8">
        <div className="flex flex-col gap-6">
          <div className="flex gap-4 items-center">
            <button className="rounded-button primary-button w-fit">
              <span className="">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_63842_55760)">
                    <path
                      d="M6.5 0.5C3.1862 0.5 0.5 3.185 0.5 6.4958C0.5 9.1832 2.2682 11.456 4.7048 12.2192V10.9478C2.9462 10.2356 1.7048 8.5142 1.7048 6.5006C1.7048 3.8492 3.854 1.7 6.5048 1.7C9.1556 1.7 11.3048 3.8492 11.3048 6.5006C11.3048 8.5142 10.0628 10.2362 8.3048 10.9478V12.2138C10.7366 11.4494 12.5 9.179 12.5 6.4958C12.5 3.185 9.8126 0.5 6.5 0.5ZM5.9048 12.5006H7.1048V11.3006H5.9048V12.5006Z"
                      fill="white"
                    />
                    <path
                      d="M5.64977 8.60039L4.80017 7.76039L8.19977 4.40039L9.04997 5.24039L5.64977 8.60039Z"
                      fill="white"
                    />
                    <path
                      d="M3.94995 6.92008L5.64975 8.60008L6.49995 7.76008L4.80015 6.08008L3.94995 6.92008Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_63842_55760">
                      <rect width="13" height="13" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <span>Verify Account</span>
            </button>

            <div>
              <button
                className="flex gap-2 cursor-help"
                data-tooltip-target="tooltip-bottom"
                data-tooltip-placement="bottom"
                type="button"
              >
                <svg
                  className="icons mb-2"
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_61677_152926)">
                    <path d="M8 0C3.5816 0 0 3.5792 0 7.9944C0 11.5768 2.3568 14.6072 5.6064 15.6248V13.9304C3.2616 12.98 1.6064 10.6856 1.6064 8.0008C1.6064 4.4664 4.472 1.6016 8.0064 1.6016C11.5408 1.6016 14.4064 4.4664 14.4064 8.0008C14.4064 10.6856 12.7504 12.9816 10.4064 13.9304V15.6184C13.6488 14.5984 16 11.572 16 7.9944C16 3.5792 12.4168 0 8 0ZM7.2064 12.0008H8.8064V7.2008H7.2064V12.0008ZM7.2064 5.6008H8.8064V4.0008H7.2064V5.6008ZM7.2064 16H8.8064V14.4H7.2064V16Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_61677_152926">
                      <rect width="16" height="16" />
                    </clipPath>
                  </defs>
                </svg>{" "}
                <p className="smaller">Why verify?</p>
              </button>
              <div
                id="tooltip-bottom"
                role="tooltip"
                class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
              >
                Tooltip on bottom
                <div class="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-6">
            <div className="w-full">
              <BorderSelectInput
                id={"accountType"}
                name={"accountType"}
                title={"Account Type"}
                // textBg={'#fff'}
                options={["Student", "Teacher", "Other"]}
                handleInputChange={handleChange}
                borderText={`Account Type ${!formFilled && !profileInfo.accountType ? "*" : ""}`}
              />
            </div>
            <div className="w-full">
              <BorderSelectInput
                id={"privacy"}
                name={"privacy"}
                title={"privacy"}
                options={["Everyone"]}
                handleInputChange={handleChange}
                borderText={`Who can view your account ${!formFilled && !profileInfo.gender ? "*" : ""}`}
              />
            </div>
          </div>

          {/*<div className={"grid sm:grid-cols-2 gap-3 my-4"}>
            <div className="text-xs text-gray-700">
              <div className="font-semibold">Notification Sound:</div>
              <div>
                A sound will be played each time you receive a new activity
                notification.
              </div>
            </div>
            <div className="flex justify-end px-2">
              <button
                className={`relative inline-block w-12 h-6 rounded-full transition-colors duration-300 px-2 ml-auto ${switchToggle ? "bg-i-purple-600" : "bg-gray-300"}`}
                onClick={handleToggle}
              >
                <span
                  className={`absolute inset-y-0 left-0 inline-block w-6 h-6 bg-white rounded-full shadow-lg transform transition-transform duration-300 ${switchToggle ? "translate-x-full" : ""}`}
                />
              </button>
            </div>
          </div>*/}

          <div className="flex flex-col gap-4">
            <div className="flex flex-col sm:flex-row gap-6">
              <div className="w-full">
                <BorderedTextInput
                  id={"nationalID"}
                  name={"nationalID"}
                  type={"text"}
                  borderText={`National ID ${!formFilled && !profileInfo.employerID ? "*" : ""}`}
                  handleInputChange={handleChange}
                />
              </div>

              <div className="w-full">
                <BorderedTextInput
                  id={"employerID"}
                  name={"employerID"}
                  type={"text"}
                  borderText={`Employer ID ${!formFilled && !profileInfo.employerID ? "*" : ""}`}
                  handleInputChange={handleChange}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-6">
              <div className="w-full">
                <button className="flex gap-2 items-center">
                  <svg
                    className="icons"
                    width="11"
                    height="12"
                    viewBox="0 0 11 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2.88618 12C2.19105 12 1.52008 11.6992 0.913156 11.0924C-0.39551 9.7824 -0.28986 8.11814 1.18048 6.64104L7.16381 0.668455C7.50284 0.340142 7.93103 0.118888 8.3949 0.0321677C8.85607 -0.0507267 9.53919 -0.0132408 10.1848 0.632387C11.2194 1.66571 10.928 2.88476 10.1848 3.63479L4.16947 9.63818C4.00107 9.80797 3.60403 10.2063 3.05466 10.2076C2.6966 10.1952 2.35888 10.0378 2.11896 9.77178C1.89574 9.5604 1.76752 9.26771 1.76334 8.96038C1.76334 8.57939 1.95981 8.19037 2.34611 7.80546L5.51156 4.62522C5.61384 4.52983 5.75857 4.49478 5.89319 4.53254C6.02793 4.5703 6.13321 4.67557 6.17097 4.81032C6.20883 4.94505 6.17368 5.08967 6.07829 5.19205L2.90091 8.37205C2.59881 8.67416 2.55339 8.87063 2.55339 8.96153C2.55711 9.05608 2.60085 9.14457 2.67367 9.20479C2.76785 9.31753 2.90202 9.38942 3.04796 9.40536C3.26316 9.40536 3.48779 9.17943 3.59604 9.07117L9.61136 3.06779C9.83057 2.84858 10.4802 2.06254 9.61136 1.19641C9.33919 0.892993 8.93051 0.750155 8.52863 0.818018C8.22736 0.877973 7.94922 1.02213 7.72654 1.23379L1.74201 7.21306C1.07364 7.88143 0.147261 9.20344 1.47469 10.5308C2.70718 11.7633 3.93977 11.0481 4.75518 10.2314L7.9285 7.04328C8.00347 6.96738 8.10557 6.92448 8.21225 6.92383C8.31893 6.92318 8.42139 6.96495 8.49728 7.03992C8.57318 7.11489 8.61617 7.21699 8.61673 7.32367C8.61738 7.43034 8.57561 7.5329 8.50064 7.6087L5.32326 10.7968C4.51989 11.5988 3.68827 12 2.88618 12Z" />
                  </svg>
                  <span className="text-sm text-secondary-blue-500 hover:text-secondary-blue-600">
                    Upload National ID
                  </span>
                </button>
              </div>

              <div className="w-full">
                <button className="flex gap-2 items-center">
                  <svg
                    className="icons"
                    width="11"
                    height="12"
                    viewBox="0 0 11 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2.88618 12C2.19105 12 1.52008 11.6992 0.913156 11.0924C-0.39551 9.7824 -0.28986 8.11814 1.18048 6.64104L7.16381 0.668455C7.50284 0.340142 7.93103 0.118888 8.3949 0.0321677C8.85607 -0.0507267 9.53919 -0.0132408 10.1848 0.632387C11.2194 1.66571 10.928 2.88476 10.1848 3.63479L4.16947 9.63818C4.00107 9.80797 3.60403 10.2063 3.05466 10.2076C2.6966 10.1952 2.35888 10.0378 2.11896 9.77178C1.89574 9.5604 1.76752 9.26771 1.76334 8.96038C1.76334 8.57939 1.95981 8.19037 2.34611 7.80546L5.51156 4.62522C5.61384 4.52983 5.75857 4.49478 5.89319 4.53254C6.02793 4.5703 6.13321 4.67557 6.17097 4.81032C6.20883 4.94505 6.17368 5.08967 6.07829 5.19205L2.90091 8.37205C2.59881 8.67416 2.55339 8.87063 2.55339 8.96153C2.55711 9.05608 2.60085 9.14457 2.67367 9.20479C2.76785 9.31753 2.90202 9.38942 3.04796 9.40536C3.26316 9.40536 3.48779 9.17943 3.59604 9.07117L9.61136 3.06779C9.83057 2.84858 10.4802 2.06254 9.61136 1.19641C9.33919 0.892993 8.93051 0.750155 8.52863 0.818018C8.22736 0.877973 7.94922 1.02213 7.72654 1.23379L1.74201 7.21306C1.07364 7.88143 0.147261 9.20344 1.47469 10.5308C2.70718 11.7633 3.93977 11.0481 4.75518 10.2314L7.9285 7.04328C8.00347 6.96738 8.10557 6.92448 8.21225 6.92383C8.31893 6.92318 8.42139 6.96495 8.49728 7.03992C8.57318 7.11489 8.61617 7.21699 8.61673 7.32367C8.61738 7.43034 8.57561 7.5329 8.50064 7.6087L5.32326 10.7968C4.51989 11.5988 3.68827 12 2.88618 12Z" />
                  </svg>
                  <span className="text-sm text-secondary-blue-500 hover:text-secondary-blue-600">
                    Upload Employer ID
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row  gap-2 sm:gap-6">
          <button className="account-settings-button rounded-button gray-button w-full">
            <span className="">
              <svg
                width="13"
                height="13"
                viewBox="0 0 64 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M28.4454 36.0555H35.5565V28.9444H28.4454V36.0555ZM62.1698 7.35861L57.1423 2.33105L37.0285 22.4448L42.056 27.4724L62.1698 7.35861ZM1.83203 57.6413L6.85959 62.6688L26.9734 42.5586L21.9423 37.5311L1.83203 57.6413ZM37.0285 42.5586L57.1423 62.6688L62.1698 57.6413L42.056 37.5311L37.0285 42.5586ZM1.83203 7.36217L21.9458 27.4724L26.9769 22.4448L6.85959 2.33105L1.83203 7.36217Z"
                  fill="white"
                />
              </svg>
            </span>
            <span>Revert all Changes</span>
          </button>
          <button className="account-settings-button rounded-button green-button w-full">
            <span className="">
              <svg
                width="15"
                height="13"
                viewBox="0 0 75 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_52244_11754)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.4849 46.8678L7.63689 32.0251L0.212891 39.4491L22.4849 61.7211L59.5996 24.6064L52.1756 17.1771L22.4849 46.8678ZM67.0236 2.3291L59.5996 9.7531L67.0236 17.1771L74.4476 9.7531L67.0236 2.3291Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_52244_11754">
                    <rect
                      width="74.6667"
                      height="64"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span>Save all Changes</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountPrivacyAndSettings;

import {
  SET_SELECTED_SETTING_LOADING,
  SET_SELECTED_SETTING_FAILURE,
  SET_SELECTED_SETTING_SUCCESS
} from './constants';

const initialState = {
  loading: false,
  payload: 'Profile Information',
  error: null
};

export default function setSelectedSetting(state = initialState, action) {
  const { type, payload, error } = action;

  console.log('Selected item in reducer--', payload);
  switch(type) {
    case SET_SELECTED_SETTING_LOADING:
      return { ...state, loading: true };
    case SET_SELECTED_SETTING_SUCCESS:
      console.log('state in reducer--', state)
      return {
        ...state,
        loading: false,
        payload
      };
    case SET_SELECTED_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error
      };
    default:
      return state;
  }
}

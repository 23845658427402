import {
  SET_AFFILIATION_SUCCESS,
  SET_AFFILIATION_FAILURE,
  GET_AFFILIATION_SUCCESS
} from './constants'

const initState = {
  affiliations: [],
  error: null
}

export function setAffiliation(state=initState, action) {
  const { type, payload, error } = action
  console.log('Reducer affiliation--', payload);
  switch(type) {
    case SET_AFFILIATION_SUCCESS:
      return {
        ...state,
        affiliations: [...state.affiliations, payload]
      }

    case SET_AFFILIATION_FAILURE:
      return {
        ...state,
        loading: false,
        error
      }

    default:
      return state;
  }
}

export function getAffiliations(state=initState, action) {
  const { type, payload} = action

  switch(type) {
    case GET_AFFILIATION_SUCCESS:
      return {
        ...state,
        affiliations: state.affiliations
      }

    default:
      return state;
  }
}

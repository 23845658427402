import React from 'react'
import WorkSpaceItem from '../../../../components/common/workSpaceItem'

const Review = () => {
  return (
    <div>
      <div className="grid auto-rows-max gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
        <WorkSpaceItem
          item={"Worksheets"}
          img={"/images/worksheet-cover.jpeg"}
          noOfItems={0}
        />
        <WorkSpaceItem
          item={"Courses"}
          img={"/images/coming-soon.png"}
          noOfItems={0}
        />
        <WorkSpaceItem
          item={"Flashcards"}
          img={"/images/coming-soon.png"}
          noOfItems={0}
        />
        <WorkSpaceItem
          item={"Quizzes"}
          img={"/images/coming-soon.png"}
          noOfItems={0}
        />
        <WorkSpaceItem
          item={"Exercises"}
          img={"/images/coming-soon.png"}
          noOfItems={0}
        />

        <WorkSpaceItem
          item={"Lesson Plans & Schemes of work"}
          img={"/images/coming-soon.png"}
          noOfItems={0}
        />
      </div>
    </div>
  );
}

export default Review
import React, { useState, useEffect, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import SignedInNavBar from "../../../components/navbars/signedInNavbar";
import AccountSettingsHeader from "../../../components/common/accountSettingsHeader";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSettingAction } from "./action";
import ProfileInformation from "./Profile Settings/profileInformation";
import AccountPrivacyAndSettings from "./Profile Settings/accountPrivacyAndSettings";
import ChangePassword from "./Profile Settings/changePassword";
import InstitutionalAffiliations from "./Profile Settings/InstitutionalAffiliations";
import Library from "./Library";
import Connections from "./Connections";
import Badges from "./Badges";
import PaymentMethod from "./PaymentAndSubscription/PaymentMethod";
import LinkedAccounts from "./PaymentAndSubscription/LinkedAccounts";
import AccountDeletionSuspension from './Profile Settings/accountDeletionSuspension';
import Tokens from './PaymentAndSubscription/Tokens';

function ExpandableList({ data }) {
  const selectedSetting = useSelector(
    (state) => state.setSelectedSetting.payload
  );
  const [expandedItem, setExpandedItem] = useState(null);
  const [selectedSubItem, setSelectedSubItem] = useState(null);
  const dispatch = useDispatch();

  const handleItemClick = (item, index, subIndex) => {
    typeof item === "string" && dispatch(setSelectedSettingAction(item));
    if (subIndex !== null) {
      setSelectedSubItem(subIndex);
    } else {
      setExpandedItem(expandedItem === index ? null : index);
      setSelectedSubItem(null); // Reset selected sub-item when main item is clicked
    }
  };

  return (
    <ul>
      {data.map((item, index, icon) => (
        <li key={index}>
          <div
            onClick={() => handleItemClick(item, index, null)}
            className="cursor-pointer py-1 flex items-center justify-between"
          >
            <div className="flex items-center">
              <span className={`${item.icon && "mr-2"}`}>
                {item.icon && ReactHtmlParser(item.icon)}
              </span>
              <h6 className="text-xs md:text-sm lg:text-base line-clamp-1">
                {item.name}
              </h6>
            </div>

            <span>
              {expandedItem === index ? (
                <svg
                  className="icons"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.64019 7.86012H5.30686V9.19345H6.64019V7.86012ZM1.26019 2.87012L0.317526 3.81278L4.08819 7.58412L5.03086 6.64145L1.26019 2.87012ZM10.6875 2.87012L6.91686 6.64145L7.85953 7.58412L11.6309 3.81278L10.6875 2.87012Z"
                  />
                </svg>
              ) : (
                <svg
                  className="icons"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.86012 5.35981V6.69314H9.19345V5.35981H7.86012ZM2.87012 10.7398L3.81278 11.6825L7.58412 7.91181L6.64145 6.96914L2.87012 10.7398ZM2.87012 1.31247L6.64145 5.08314L7.58412 4.14047L3.81278 0.369141L2.87012 1.31247Z"
                  />
                </svg>
              )}
            </span>
          </div>
          {expandedItem === index && item.subItems && (
            <ul>
              {item.subItems.map((subItem, subIndex) => (
                <li
                  key={subIndex}
                  onClick={() => handleItemClick(subItem, index, subIndex)}
                  className={`small text-sm font-bold cursor-pointer text-hover pl-4 py-1 line-clamp-1 ${selectedSubItem === subIndex && expandedItem === index ? "text-secondary-blue-500" : ""}`}
                >
                  {subItem}
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
}

const AccountSettings = () => {
  const dispatch = useDispatch();

  const countries = useSelector((state) => state.getCountries.payload.data);
  const counties = useSelector((state) => state.getCounties.payload.data);
  const selectedSetting = useSelector(
    (state) => state.setSelectedSetting.payload
  );

  const countriesOptions = [];
  const countiesOptions = [];

  countries &&
    countries.map((country) =>
      countriesOptions.push({ label: country.name, value: country.id })
    );

  counties &&
    counties.map((county) =>
      countiesOptions.push({ label: county.name, value: county.id })
    );

  const profileSettingsData = [
    {
      name: "Profile Settings",
      subItems: [
        "Profile Information",
        "Account Privacy and Verification",
        "Change Password",
        // "Institutional" + " Affiliations",
        "Account Deletion/Suspension",
      ],
      icon: null,
    },
  ];

  const librarySettingsData = [
    {
      name: "Library",
      subItems: [
        "IGSE Cambridge Lower Primary",
        "CBC Upper Primary",
        "844 Secondary",
        "Add a new library",
      ],
      icon: null,
    },
  ];

  const paymentSubscriptionsData = [
    {
      name: "Payment and Subscriptions",
      subItems: [
        //"Payment Method",
        "Linked Accounts",
        "Tokens",
      ],
    },
  ];

  console.log("Selected setting---2", selectedSetting);

  const addSetting = (item) => dispatch(setSelectedSettingAction(item));

  const sidebarRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      window.innerWidth < 1280
    ) {
      setMenuOpen(false);
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      // Update the state or perform any necessary actions based on the new window.innerWidth value
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenuContent = () => {
    setMenuOpen(!menuOpen);
    document.getElementById("content-menu").classList.toggle("hidden");
  };

  return (
    <div className="body flex flex-col min-h-screen">
      <SignedInNavBar label={"settings"} title={"Settings"} />

      <div className="mt-0 mx-0 lg:ml-20 min-h-screen">
        <AccountSettingsHeader />
        <div
          className="z-10 fixed top-20 lg:top-24 left-4 md:left-8 lg:left-28 flex items-center xl:hidden"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <button
            id="content-menu-toogle"
            className="group flex gap-4 items-center justify-center rounded-md bg-app-colors-body-bg dark:bg-app-colors-body-bg-d surface-hover h-12 px-4 "
          >
            <svg
              className="icons text-hover-active"
              width="20"
              height="19"
              viewBox="0 0 24 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.66667 0H0V2.66667H2.66667V0ZM5.33333 0V2.66667H24V0H5.33333ZM8 8H0V10.6667H8V8ZM10.6667 8V10.6667H13.3333V8H10.6667ZM16 10.6667H24V8H16V10.6667ZM0 18.6667H18.6667V16.0013H0V18.6667ZM21.3333 18.6667H24V16.0013H21.3333V18.6667Z"
              />
            </svg>
            <p className="small font-semibold text-hover-active">Expand Menu</p>
          </button>
        </div>
        <div className="flex justify-center">
          <div className="w-full max-w-8xl mx-4 md:mx-6 xl:mx-8 2xl:mx-10 pt-2 md:pt-4 lg:pt-6 pb-2 md:pb-4 lg:pb-6 flex xl:gap-6 text-sm">
            <div>
              <div id="menu-div" className="xl:sticky xl:top-24">
                <div
                  ref={sidebarRef}
                  className={`content-menu rounded-md ${menuOpen || window.innerWidth >= 1280 ? "block" : "hidden"
                    } xl:flex xl:flex-col surface  ${menuOpen && window.innerWidth < 1280
                      ? " bg-app-colors-body-bg dark:bg-app-colors-body-bg-d md:w-80 lg:w-96 fixed top-20 lg:top-24 left-4 md:left-8 lg:left-28 z-20 flex flex-col w-72"
                      : ""
                    }`}
                >
                  <div className="relative h-full">
                    <div className="bg-app-colors-body-bg dark:bg-app-colors-body-bg-d xl:bg-app-colors-surface xl:dark:bg-app-colors-surface-d rounded-t-md w-72 md:w-80 lg:w-96 sticky pt-12 pb-6 lg:pt-14 lg:pb-8 px-4 border-b-2 border-app-colors-border dark:border-app-colors-border-d">
                      <h6 className="text-xs md:text-sm lg:text-base text-app-colors-p-small dark:text-app-colors-p-small-d">
                        Your account
                      </h6>
                    </div>
                    <div
                      className="relative pb-4 h-fill overflow-y-auto"
                      style={{ maxHeight: "60vh" }}
                    >
                      <div className=" surface-hover flex gap-6 items-center px-8 py-2 border-b border-app-colors-border dark:border-app-colors-border-d">
                        <svg
                          className="self-start mt-0.5 lg:mt-1.5 icons"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.55556 13.3333C4.10556 13.3333 2.88556 14.2633 2.42667 15.5556H0V17.7778H2.42667C2.88556 19.0689 4.10556 20 5.55556 20C7.00556 20 8.22556 19.0689 8.68444 17.7778H15.5556V15.5556H8.68444C8.22556 14.2633 7.00556 13.3333 5.55556 13.3333ZM6.66667 17.7778H4.44444V15.5556H6.66667V17.7778ZM14.4444 6.66667C12.9944 6.66667 11.7744 7.59778 11.3156 8.88889H0V11.1111H11.3156C11.7744 12.4033 12.9956 13.3333 14.4444 13.3333C15.8933 13.3333 17.1144 12.4022 17.5733 11.1111H20V8.88889H17.5733C17.1144 7.59667 15.8944 6.66667 14.4444 6.66667ZM15.5556 11.1111H13.3333V8.88889H15.5556V11.1111ZM8.68444 2.22222C8.22556 0.93 7.00556 0 5.55556 0C4.10556 0 2.88556 0.93 2.42667 2.22222H0V4.44444H2.42667C2.88556 5.73556 4.10556 6.66667 5.55556 6.66667C7.00556 6.66667 8.22556 5.73667 8.68444 4.44444H20V2.22222H8.68444ZM6.66667 4.44444H4.44444V2.22222H6.66667V4.44444ZM17.7778 17.7778H20V15.5556H17.7778V17.7778Z"
                          />
                        </svg>
                        <div className="w-full">
                          <ExpandableList data={profileSettingsData} />
                        </div>
                      </div>
                      <div className="surface-hover flex gap-6 items-center px-8 py-2 border-b border-app-colors-border dark:border-app-colors-border-d">
                        <svg
                          className="self-start mt-0.5 lg:mt-1.5 icons"
                          width="20"
                          height="20"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.996 20.0643V12.8655H18.664V20.0643H15.996ZM10.6627 20.0643V12.8655H13.3307V20.0643H10.6627ZM5.33333 20.0547V12.8547H8V20.0547H5.33333ZM6.66667 4.46426V6.86306H4V4.46426H6.66667ZM10.6667 4.46426V6.86306H8V4.46426H10.6667ZM14.6667 4.46426V6.86306H12V4.46426H14.6667ZM21.3333 10.4631H2.66667V8.06306H21.3333V10.4631ZM18.6667 24.8619H2.66667H0V21.2619V17.6607V3.26426C0 1.93826 1.19467 0.864258 2.66667 0.864258H21.3333C22.8053 0.864258 24 1.93826 24 3.26426V10.4643H21.3333V3.26426H2.66667V17.6619V22.4631H16L21.3333 22.4655V17.6619H24V20.0631V24.8655L18.6667 24.8619ZM21.3333 12.8631H24V15.2631H21.3333V12.8631Z"
                          />
                        </svg>

                        <div className="w-full">
                          <ExpandableList data={librarySettingsData} />
                        </div>
                      </div>
                      <div
                        className="surface-hover flex gap-6 items-center px-8 py-2 border-b border-app-colors-border dark:border-app-colors-border-d cursor-pointer"
                        onClick={() => addSetting("Badges")}
                      >
                        <div className="flex gap-6">
                          <svg
                            className="icons mt-0.5 lg:mt-1"
                            width="20"
                            height="20"
                            viewBox="0 0 64 64"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M51.2004 0H41.6004V6.4H51.2004V51.2L35.8404 39.68L32.0004 36.8L28.1604 39.68L12.8004 51.2V6.4H22.4004V0H12.8004C9.26759 0 6.40039 2.8672 6.40039 6.4V54.4V57.6V64L32.0004 44.8L57.6004 64V57.6V54.4V6.4C57.6004 2.8672 54.7332 0 51.2004 0Z" />
                            <path d="M35.2008 0H28.8008V6.4H35.2008V0Z" />
                          </svg>

                          <h6 className="text-base">Badges</h6>
                        </div>
                      </div>
                      <div className="surface-hover flex gap-6 items-center px-8 py-2 border-b border-app-colors-border dark:border-app-colors-border-d">
                        <svg
                          className="self-start mt-1.5 lg:mt-2.5 icons"
                          width="20"
                          height="14"
                          viewBox="0 0 20 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M19.2924 0.0390625H0.707582C0.520037 0.0390625 0.340055 0.113586 0.20729 0.246352C0.0745283 0.378949 0 0.558931 0 0.746645V13.4824C0 13.6702 0.074524 13.8501 0.20729 13.9827C0.340051 14.1155 0.520033 14.19 0.707582 14.19H19.2924C19.48 14.19 19.6599 14.1155 19.7927 13.9827C19.9255 13.8501 20 13.6702 20 13.4824V0.746645C20 0.558935 19.9255 0.378953 19.7927 0.246352C19.6599 0.11359 19.48 0.0390625 19.2924 0.0390625ZM18.5848 12.7749H1.41508V7.04375H18.5848V12.7749ZM18.5848 2.58622L1.41508 2.58639V1.45436H18.5848V2.58622Z" />
                        </svg>
                        <div className="w-full">
                          <ExpandableList data={paymentSubscriptionsData} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full">
              {selectedSetting === "Profile Information" && (
                <ProfileInformation />
              )}
              {selectedSetting === "Account Privacy and Verification" && (
                <AccountPrivacyAndSettings />
              )}
              {selectedSetting === "Change Password" && <ChangePassword />}
              {selectedSetting === "Institutional Affiliations" && (
                <InstitutionalAffiliations />
              )}
              {selectedSetting === "IGSE Cambridge Lower Primary" && (
                <Library />
              )}
              {selectedSetting === "Connections" && <Connections />}
              {selectedSetting === "Badges" && <Badges />}
              {selectedSetting === "Payment Method" && <PaymentMethod />}
              {selectedSetting === "Linked Accounts" && <LinkedAccounts />}
              {selectedSetting === "Account Deletion/Suspension" && <AccountDeletionSuspension />}
              {selectedSetting === "Tokens" && <Tokens />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;

import { combineReducers } from 'redux';

// Import reducers here
import {userRegistration, setUserInfo} from './pages/signup/register/reducer';
import userLogin from './pages/Login/reducer';
import getCountries from './commonData/countries/reducer';
import getCounties from './commonData/counties/reducer';
import getCategories from './commonData/categories/reducer';
import getCurricula from './commonData/curricula/reducer';
import getSchools from './commonData/schools/reducer';
import getGrades from './commonData/grades/reducer';
import setParentPersonalInfo from './pages/signup/parent/personalInfo/reducer';
import createParent from './pages/signup/parent/newParent/reducer';
import setStudentPersonalInfo from './pages/signup/student/personalInfo/reducer';
import createStudent from './pages/signup/student/newStudent/reducer';
import setTeacherPersonalInfo from './pages/signup/teacher/personalInfo/reducer';
import createTeacher from './pages/signup/teacher/newTeacher/reducer';
import setSchoolInfo from './pages/signup/school/schoolDetails/reducer';
import setAccountType from './pages/signup/selectAccount/reducer';
import uploadImage from './commonData/imageUpload/reducer'
import getNationalities from './commonData/nationalities/reducer';
import getUserDetails from './commonData/userDetails/reducer';
import createMissingSchool from './commonData/missingSchool/reducer';
import createSchool from './pages/signup/school/newSchool/reducer';
import getLibraries from './pages/userSpace/libraryCluster/reducer';
import getCurriculumSubjects from './pages/userSpace/personalLibrary/reducer';
import setSelectedSetting from './pages/Account/Settings/reducer';
import {setAffiliation,
        getAffiliations} from './pages/Account/Settings/Profile Settings/InstitutionalAffiliations/reducer';

const rootReducer = combineReducers({
  userRegistration,
  setUserInfo,
  userLogin,
  getCountries,
  getCounties,
  getCategories,
  getCurricula,
  getSchools,
  getGrades,
  setParentPersonalInfo,
  createParent,
  setStudentPersonalInfo,
  createStudent,
  setTeacherPersonalInfo,
  createTeacher,
  createSchool,
  setSchoolInfo,
  setAccountType,
  uploadImage,
  getNationalities,
  getUserDetails,
  createMissingSchool,
  getLibraries,
  getCurriculumSubjects,
  setSelectedSetting,
  setAffiliation,
  getAffiliations,
});

export default rootReducer;
import React, {useState} from 'react'
import AccountSetUpNavbar from '../../../components/navbars/accountSetUpNavBar';
import ConnectionListItem from '../../../components/common/connectionListItem';
import {useNavigate} from 'react-router-dom';
import ROUTES from '../../../utils/routes';
import LibrariesCard from '../../../components/common/librariesCard';
import AccountSetupTop from '../../../components/common/accountSetupTop';


export default function AccountSetUpLibraries() {

  const [toggleState, setToggleState] = useState(1);
  const navigate = useNavigate()

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const subjectsRoutePage = () => {
    let path = ROUTES.accountSetUpSubjects
    navigate(path)
  }

  const connectionsRoutePage = () => {
    let path = ROUTES.accountSetUpConnections
    navigate(path)
  }

  return (
      <div className="flex flex-col bg-gray-300 min-h-screen">
        <AccountSetUpNavbar />
        <div>
          <div className="bg-i-purple-400">
            <AccountSetupTop
              themeColor={'purple'}
              page={'libraries'}
              subjectsPageRoute={subjectsRoutePage}
              connectionsPageRoute={connectionsRoutePage}
              librariesPageRoute={null}
            />
          </div>


          <div className="mt-10 mx-4 lg:mx-10 text-sm">
            <div className="flex">
              <div>
                <button
                    className={`focus:outline-none rounded-l-lg p-4 text-xs flex ${toggleState === 1 ? 'tabs' +
                        ' bg-i-purple-500 text-white border border-i-purple-500' : 'border border-gray-400'}`}
                    onClick={() => toggleTab(1)}
                >
                  <span className="my-auto sm:mr-1">
                    <svg width="13" height="13" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 27.5C3 25.6144 3 24.6716 3.58579 24.0858C4.17157 23.5 5.11438 23.5 7 23.5H25.3431C26.1606 23.5 26.5694 23.5 26.9369 23.6522C27.3045 23.8045 27.5935 24.0935 28.1716 24.6716L37.8284 34.3284C38.4065 34.9065 38.6955 35.1955 39.0631 35.3478C39.4306 35.5 39.8394 35.5 40.6569 35.5H63C64.8856 35.5 65.8284 35.5 66.4142 36.0858C67 36.6716 67 37.6144 67 39.5V63.5C67 65.3856 67 66.3284 66.4142 66.9142C65.8284 67.5 64.8856 67.5 63 67.5H7C5.11438 67.5 4.17157 67.5 3.58579 66.9142C3 66.3284 3 65.3856 3 63.5V27.5Z" stroke={`${ toggleState === 1 ? 'white' : '#1e293b'}`} strokeWidth="6"/>
                      <path d="M59 35.5V7.5C59 5.61438 59 4.67157 58.4142 4.08579C57.8284 3.5 56.8856 3.5 55 3.5H15C13.1144 3.5 12.1716 3.5 11.5858 4.08579C11 4.67157 11 5.61438 11 7.5V23.5" stroke={`${ toggleState === 1 ? 'white' : '#1e293b'}`} strokeWidth="6"/>
                    </svg>
                  </span>
                  <span className="mr-1 hidden sm:block">Libraries Followed</span>
                  <span className="my-auto ml-2 sm:ml-0">0</span>
                </button>
              </div>

              <div>
                <button
                    className={`focus:outline-none p-4 text-xs flex ${toggleState === 2 ? 'tabs bg-i-purple-500' +
                        ' text-white border border-i-purple-500' : 'border-t border-b border-gray-400'}`}
                    onClick={() => toggleTab(2)}
                >
                  <span className="my-auto sm:mr-1">
                    <svg width="14" height="13" viewBox="0 0 80 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.2852 5.5C21.2852 4.55719 21.2852 4.08579 21.578 3.79289C21.8709 3.5 22.3423 3.5 23.2852 3.5H42.9062C43.5076 3.5 43.8083 3.5 44.05 3.64935C44.2916 3.7987 44.4261 4.06766 44.6951 4.60557L50.9994 17.2143H74.1423C75.0851 17.2143 75.5565 17.2143 75.8494 17.5072C76.1423 17.8001 76.1423 18.2715 76.1423 19.2143V47.2143C76.1423 48.1571 76.1423 48.6285 75.8494 48.9214C75.5565 49.2143 75.0851 49.2143 74.1423 49.2143H23.2852C22.3423 49.2143 21.8709 49.2143 21.578 48.9214C21.2852 48.6285 21.2852 48.1571 21.2852 47.2143V5.5Z" stroke={`${ toggleState === 2 ? 'white' : '#1e293b'}`} strokeWidth="6" strokeLinejoin="round"/>
                      <path d="M62.4286 49.2144V65.9001C62.4286 66.4601 62.4286 66.7401 62.3196 66.9541C62.2237 67.1422 62.0707 67.2952 61.8826 67.3911C61.6686 67.5001 61.3886 67.5001 60.8286 67.5001H4.6C4.03995 67.5001 3.75992 67.5001 3.54601 67.3911C3.35785 67.2952 3.20487 67.1422 3.10899 66.9541C3 66.7401 3 66.4601 3 65.9001V18.8144C3 18.2543 3 17.9743 3.10899 17.7604C3.20487 17.5722 3.35785 17.4192 3.54601 17.3233C3.75992 17.2144 4.03995 17.2144 4.6 17.2144H21.2857" stroke={`${ toggleState === 2 ? 'white' : '#1e293b'}`} strokeWidth="6" strokeLinejoin="round"/>
                    </svg>
                  </span>
                  <span className="hidden sm:block">Suggested Libraries</span>
                  <span className="invisible sm:hidden">0</span>
                </button>
              </div>

              <div>
                <button
                    className={`focus:outline-none rounded-r-lg p-4 text-xs flex ${toggleState === 3 ? 'tabs' +
                        ' bg-i-purple-500 text-white border border-i-purple-500' : 'border border-gray-400'}`}
                    onClick={() => toggleTab(3)}
                >
                  <span className="my-auto sm:mr-1">
                    <svg width="14" height="13" viewBox="0 0 75 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M63.2353 37.3824V18.7941C63.2353 16.9085 63.2353 15.9657 62.6495 15.3799C62.0637 14.7941 61.1209 14.7941 59.2353 14.7941H38.5392C37.7217 14.7941 37.313 14.7941 36.9454 14.6419C36.5779 14.4896 36.2888 14.2006 35.7108 13.6225L26.7598 4.67157C26.1817 4.09351 25.8927 3.80448 25.5252 3.65224C25.1576 3.5 24.7489 3.5 23.9314 3.5H7C5.11438 3.5 4.17157 3.5 3.58579 4.08579C3 4.67157 3 5.61438 3 7.5V52.2059C3 54.0915 3 55.0343 3.58579 55.6201C4.17157 56.2059 5.11438 56.2059 7 56.2059H44.4118" stroke={`${ toggleState === 3 ? 'white' : '#1e293b'}`} strokeWidth="6"/>
                      <path d="M51.9414 56.2057H63.2355M63.2355 56.2057H74.5296M63.2355 56.2057V67.4999M63.2355 56.2057V44.9116" stroke={`${ toggleState === 3 ? 'white' : '#1e293b'}`} strokeWidth="6"/>
                      <rect x="3.5" y="4" width="70.5294" height="63" stroke={`${ toggleState === 3 ? 'white' : '#1e293b'}`}/>
                    </svg>
                  </span>
                  <span className="hidden sm:block">Library Requests</span>
                  <span className="invisible sm:hidden">0</span>
                </button>
              </div>

              <div className="mt-auto mb-auto ml-auto flex">
                <button className="rounded-full text-white bg-i-purple-500 py-2 px-2.5 mr-3">
                  <svg width="13" height="13" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_52244_11695)">
                      <path fillRule="evenodd" clipRule="evenodd" d="M14.2222 7.76178V0H7.11111V7.76533C2.976 9.23378 0 13.1378 0 17.7778C0 22.4178 2.976 26.3218 7.11111 27.7902V49.7778H14.2222V27.7867C18.3573 26.3182 21.3333 22.4142 21.3333 17.7778C21.3333 13.1413 18.3573 9.23378 14.2222 7.76178ZM14.2222 21.3333H7.11111V14.2222H14.2222V21.3333ZM56.8889 7.76178V0H49.7778V7.76533C45.6462 9.23378 42.6667 13.1378 42.6667 17.7778C42.6667 22.4178 45.6462 26.3182 49.7778 27.7902V64H56.8889V27.7867C61.0204 26.3182 64 22.4142 64 17.7778C64 13.1413 61.0204 9.23378 56.8889 7.76178ZM56.8889 21.3333H49.7778V14.2222H56.8889V21.3333ZM35.5556 36.2098V0H28.4444V36.2098C24.3093 37.6782 21.3333 41.5822 21.3333 46.2222C21.3333 50.8587 24.3093 54.7627 28.4444 56.2347V64H35.5556V56.2347C39.6871 54.7627 42.6667 50.8551 42.6667 46.2222C42.6667 41.5822 39.6871 37.6782 35.5556 36.2098ZM35.5556 49.7742H28.4444V42.6667H35.5556V49.7742ZM7.11111 64H14.2222V56.8889H7.11111V64Z" fill="white"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_52244_11695">
                        <rect width="64" height="64" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <div className="flex">
                  <input type="text" id="small-input"
                         className="border-0 p-2 w-10 sm:w-full rounded bg-white text-xs focus:ring-i-purple-500"
                         placeholder={'Search Library ...'}
                  />
                </div>
              </div>

            </div>

            <div className="mt-4">
              <div className={`${(toggleState === 1) ? "block" : "hidden"}`}>
                <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 gap-6 mb-6">
                  <LibrariesCard
                      fullName="Daktari Dindi"
                      username="daktari"
                      libraryType="Public Library"
                      buttonLabel="Request Access"
                      role="Student"
                      noOfFollowers="200"
                  />
                  <LibrariesCard
                      fullName="Daktari Dindi"
                      username="daktari"
                      libraryType="Public Library"
                      buttonLabel="Cancel Request"
                      role="Student"
                      noOfFollowers="200"
                  />
                  <LibrariesCard
                      fullName="Daktari Dindi"
                      username="daktari"
                      libraryType="Public Library"
                      buttonLabel="Unfollow Library"
                      role="Student"
                      noOfFollowers="200"
                  />
                  <LibrariesCard
                      fullName="Daktari Dindi"
                      username="daktari"
                      libraryType="Public Library"
                      buttonLabel="Follow Library"
                      role="Student"
                      noOfFollowers="200"
                  />
                  <LibrariesCard
                      fullName="Daktari Dindi"
                      username="daktari"
                      libraryType="Public Library"
                      buttonLabel="Cancel Request"
                      role="Student"
                      noOfFollowers="200"
                  />
                  <LibrariesCard
                      fullName="Daktari Dindi"
                      username="daktari"
                      libraryType="Public Library"
                      buttonLabel="Request Access"
                      role="Student"
                      noOfFollowers="200"
                  />
                  <LibrariesCard
                      fullName="Daktari Dindi"
                      username="daktari"
                      libraryType="Public Library"
                      buttonLabel="Follow Library"
                      role="Student"
                      noOfFollowers="200"
                  />
                  <LibrariesCard
                      fullName="Daktari Dindi"
                      username="daktari"
                      libraryType="Public Library"
                      buttonLabel="Cancel Request"
                      role="Student"
                      noOfFollowers="200"
                  />
                </div>

              </div>

              <div className={`${(toggleState === 2) ? "block" : "hidden"}`}>
                <div className="bg-white p-4 border-l-8 border-iblue">
                  No libraries to show
                </div>
              </div>

              <div className={`${(toggleState === 3) ? "block" : "hidden"}`}>
                <div className="grid md:grid-cols-2 gap-2">
                  <div>
                    <div className="bg-white p-4 border-l-8 border-green-400 rounded-r">
                      Incoming Libraries
                    </div>
                    <div className="mt-4 px-1">
                      <div className="bg-white p-4 border-l-8 border-iblue ml-4 rounded-r">
                        No libraries to show
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="bg-white p-4 border-l-8 border-red-400 rounded-r">
                      Outgoing Libraries
                    </div>
                    <div className="mt-4 px-1 overflow-auto h-96 ml-4">
                      <ConnectionListItem fullName="Victor Jambo" username="jambo" noOfFollowers="200"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo" noOfFollowers="200"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo" noOfFollowers="200"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo" noOfFollowers="200"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo" noOfFollowers="200"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo" noOfFollowers="200"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo" noOfFollowers="200"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo" noOfFollowers="200"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo" noOfFollowers="200"/>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

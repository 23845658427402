import React from "react";

const SuggestedLibraryCard = ({
  fullName,
  username,
  curriculumLevel,
  noOfFollowers,
  noOfUploads,
}) => {
  return (
    <div className="cover-style">
      <div className="group surface surface-hover rounded cursor-pointer">
        <div
          className="py-1 bg-no-repeat bg-cover bg-center rounded-t h-20"
          style={{ backgroundImage: "url(/images/card-bg.jpeg)" }}
        >
          <div className="flex justify-end px-4 mt-4">
            <button type="button" className="pill-button green-button">
              Follow Library
            </button>
          </div>
        </div>
        <div className="flex flex-col py-2 px-4 -mt-9">
          <img
            className="surface object-cover w-14 h-14 rounded-full t-ring ring-2 "
            src="/images/test-avatar.png"
            alt="avatar"
          />
          <div className="flex flex-col ml-16 -mt-6">
            <div className="flex flex-col gap-0">
              <h6 className="text-hover text-sm line-clamp-1">{fullName}</h6>
              <p className="smaller">@{username}</p>
            </div>
            <div className="flex gap-4 justify-end items-end">
              <div className="flex justify-center content-center items-center gap-1">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 64 65"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_52244_11748)">
                    <path
                      d="M28.8262 58.0996H35.2262V64.4996H28.8262V58.0996Z"
                      fill="#A3A3A3"
                    />
                    <path
                      d="M63.9991 32.4776C63.9991 14.8168 49.6695 0.5 31.9991 0.5C14.3255 0.5 -0.0136719 14.8168 -0.0136719 32.4776C-0.0136719 46.8072 9.59913 58.9288 22.3991 62.996V56.4296C25.5991 54.8744 25.7623 52.8872 26.2263 51.4728C27.6087 47.2872 26.0311 42.1288 22.3895 38.9224C19.7847 36.6312 20.2423 34.9544 25.4359 27.716C26.4279 26.3336 27.4647 24.8904 28.4599 23.3672C30.5719 20.1416 30.1303 17.4472 29.3847 15.7608C28.0183 12.6568 24.5271 10.628 21.1831 9.3384C23.3143 8.34 25.5991 7.6328 27.9991 7.252C28.6455 9.7128 30.0247 13.9176 32.3319 15.876C35.0327 18.1672 40.0279 20.068 45.3335 20.068C48.0215 20.068 50.7735 19.5368 53.3591 18.3656C54.1655 19.5784 54.8663 20.868 55.4583 22.2152C49.1255 20.8424 39.7431 19.8728 33.6887 24.1384C29.8679 26.8296 26.6359 32.6216 27.8391 37.6584C28.6967 41.2488 31.6311 43.6616 35.8903 44.276C40.3991 44.932 45.3719 48.5992 45.9287 51.6808C46.0151 52.1544 46.2295 53.5016 43.8615 55.1112C43.1223 55.4984 41.5991 55.9016 41.5991 56.2184V62.9736C41.5991 62.5064 44.5463 61.9144 45.9383 61.2392L45.9831 61.3096C46.2135 61.1816 46.3863 61.044 46.6007 60.9128C56.9271 55.6136 63.9991 44.8776 63.9991 32.4776ZM23.5287 18.34C23.5927 18.484 23.7591 18.868 23.1095 19.8632C22.1623 21.3064 21.1799 22.6792 20.2391 23.988C15.7943 30.1864 10.7575 37.2104 18.1687 43.732C19.8871 45.2424 20.7447 47.7096 20.1655 49.4696C19.5799 51.2328 17.5991 51.924 15.7399 52.2088C10.0599 47.5176 6.42473 40.4424 6.42473 32.5C6.42473 25.2136 9.48393 18.6536 14.3703 13.9912C18.3959 14.868 22.8055 16.6984 23.5287 18.34ZM36.4695 10.9928C35.9607 10.5608 35.2183 8.9256 34.6039 7.0344C40.0183 7.5752 44.9175 9.8216 48.8023 13.2008C43.9127 14.4552 38.4759 12.6952 36.4695 10.9928ZM36.8055 37.94C34.4023 37.5912 34.1527 36.5544 34.0599 36.164C33.5895 34.1992 35.1991 30.8968 37.3719 29.3672C42.1239 26.02 51.8967 27.7608 57.4039 29.2936C57.5319 30.3464 57.6247 31.412 57.6247 32.5C57.6247 38.7112 55.3623 44.3592 51.6791 48.788C49.5735 43.2328 42.7127 38.7976 36.8055 37.94Z"
                      fill="#9a9fbf"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_52244_11748">
                      <rect
                        width="64"
                        height="64"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <p className="text-xs uppercase line-clamp-1">
                  {curriculumLevel}
                </p>
              </div>
              <div className="flex flex-col">
                <h6 className="text-xs text-center">{noOfFollowers}</h6>
                <p className="text-xs uppercase">Followers</p>
              </div>

              <div className="flex flex-col">
                <h6 className="text-xs text-center">{noOfUploads}</h6>
                <p className="text-xs uppercase">Uploads</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuggestedLibraryCard;

import React from "react";

const SubjectsCard = ({ subjectName, buttonLabel }) => {
  return (
    <div>
      <div className="bg-white h-full rounded">
        <div
          className="py-4 bg-no-repeat bg-cover bg-center h-28 w-full rounded-t"
          style={{ backgroundImage: "url(/images/card-bg.jpeg)" }}
        ></div>
        <div className="flex flex-col items-center pb-6 px-6">
          <h3 className="px-6 mb-1 text-sm uppercase font-medium text-gray-900 mt-6">
            {subjectName}
          </h3>
          <div className="mt-4 space-x-3 lg:mt-6">
            <button
              className={`inline-flex items-center py-2 px-4 text-sm font-medium text-center text-white ${buttonLabel === "Add Subject" ? "bg-igreen" : "bg-ired"} rounded-full`}
            >
              {buttonLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectsCard;

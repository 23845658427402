import {
  SET_PARENT_PERSONALINFO_FAILURE,
  SET_PARENT_PERSONALINFO_SUCCESS
} from './constants'

export const setParentPersonalInfo = payload => ({
  type: SET_PARENT_PERSONALINFO_SUCCESS,
  payload
});

export const setPersonalInfoFailure = error => ({
  type: SET_PARENT_PERSONALINFO_FAILURE,
  error
});
import React, { useState } from "react";
import BorderedTextInput from "../../../../components/common/borderedTextInput";
import BorderedTextArea from "../../../../components/common/borderedTextArea";
import InternationalPhoneField from "../../../../components/common/internationalPhoneField";
import SearchableDropDown from "../../../../components/common/searchableDropDown";
import { getCountiesAction } from "../../../../commonData/counties/action";
import { useDispatch, useSelector } from "react-redux";
import BorderDateInput from "../../../../components/common/borderDateInput";
import BorderSelectInput from "../../../../components/common/borderSelectInput";

const ProfileInformation = () => {
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState({});
  const countries = useSelector((state) => state.getCountries.payload.data);
  const counties = useSelector((state) => state.getCounties.payload.data);
  const countriesLoading = useSelector((state) => state.getCountries.loading);
  const countiesLoading = useSelector((state) => state.getCounties.loading);
  const [profileInfo, setProfileInfo] = useState({});
  const [formFilled, setFormFilled] = useState(true);

  const countriesOptions = [];
  const countiesOptions = [];

  countries &&
    countries.map((country) =>
      countriesOptions.push({ label: country.name, value: country.id })
    );

  counties &&
    counties.map((county) =>
      countiesOptions.push({ label: county.name, value: county.id })
    );

  const handleChange = (e) => {
    setProfileInfo({ ...profileInfo, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (phone) => {
    setProfileInfo({ ...profileInfo, phoneNumber: phone });
  };

  const handleNationalityChange = (code) => {
    setProfileInfo({ ...profileInfo, countryId: code });
  };

  const handleCountryChange = (code) => {
    dispatch(getCountiesAction(code));
  };

  const handleCountyChange = (countyId) => {
    setProfileInfo({ ...profileInfo, countyId: countyId });
  };

  return (
    <div className="surface rounded-md w-full">
      <div className="pt-12 pb-6 lg:pt-14 px-6 border-b-2 border-app-colors-border dark:border-app-colors-border-d">
        <h6>Profile Information</h6>
      </div>
      <div className="px-6 py-8 flex flex-col gap-8">
        <div className="flex flex-col gap-6">
          <div className="flex flex-row gap-6">
            <div className="w-full">
              <BorderedTextInput
                id={"schoolName"}
                name={"schoolName"}
                type={"text"}
                borderText={`School Name ${!formFilled && !profileInfo.schoolName ? "*" : ""}`}
                handleInputChange={handleChange}
              />
            </div>
            <button className="text-secondary-green-500 min-w-max">
              Request Change
            </button>
          </div>

          <div className="flex flex-col sm:flex-row  gap-6">
            <div className="flex w-full gap-6">
              <div className="min-w-max">
                <BorderSelectInput
                  id={"title"}
                  name={"title"}
                  title={"title"}
                  // textBg={'#fff'}
                  options={["None", "Dr.", "Prof."]}
                  handleInputChange={handleChange}
                  borderText={`Title ${!formFilled && !profileInfo.title ? "*" : ""}`}
                />
              </div>

              <div className="w-full">
                <BorderedTextInput
                  id={"firstName"}
                  name={"firstName"}
                  type={"text"}
                  borderText={`First Name ${!formFilled && !profileInfo.firstName ? "*" : ""}`}
                  handleInputChange={handleChange}
                />
              </div>
            </div>
            <div className="w-full">
              <BorderedTextInput
                id={"otherNames"}
                name={"otherNames"}
                type={"text"}
                borderText={`Other Names ${!formFilled && !profileInfo.otherNames ? "*" : ""}`}
                handleInputChange={handleChange}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-6">
            <div className="w-full">
              <BorderedTextInput
                id={"username"}
                name={"username"}
                type={"text"}
                borderText={`Username ${!formFilled && !profileInfo.username ? "*" : ""}`}
                handleInputChange={handleChange}
              />
            </div>
            <div className="w-full">
              <BorderedTextInput
                id={"email"}
                name={"email"}
                type={"text"}
                borderText={`Email ${!formFilled && !profileInfo.email ? "*" : ""}`}
                handleInputChange={handleChange}
              />
            </div>
          </div>

          {/*
          <div className="w-full">
            <BorderedTextArea
              id={"bio"}
              name={"bio"}
              borderText={`Bio ${!formFilled && !profileInfo.bio ? "*" : ""}`}
              handleInputChange={handleChange}
            />
          </div>
          */}

          <div className="flex flex-col sm:flex-row gap-6">
            <div className="w-full">
              <InternationalPhoneField
                value={profileInfo.phoneNumber ? profileInfo.phoneNumber : ""}
                defaultCountry="KE"
                borderText={`Phone Number ${!formFilled && !profileInfo.phone ? "*" : ""}`}
                handlePhoneChange={handlePhoneChange}
              />
            </div>
            <div className="w-full">
              <BorderSelectInput
                id={"gender"}
                name={"gender"}
                title={"gender"}
                // textBg={'#fff'}
                options={["Male", "Female"]}
                handleInputChange={handleChange}
                borderText={`Gender ${!formFilled && !profileInfo.gender ? "*" : ""}`}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-6">
            <div className="w-full">
              <BorderedTextInput
                id={"username"}
                name={"username"}
                type={"text"}
                borderText={`Nationality ${!formFilled && !profileInfo.username ? "*" : ""}`}
                handleInputChange={handleChange}
              />
            </div>
            <div className="w-full">
              <BorderDateInput
                id={"dateOfBirth"}
                name={"dateOfBirth"}
                borderText={`Date of Birth ${!formFilled && !userInfo.dateOfBirth ? "*" : ""}`}
                handleInputChange={handleChange}
                value={userInfo.dateOfBirth ? userInfo.dateOfBirth : ""}
              />
            </div>
          </div>

          {/*<div className="flex flex-col sm:flex-row  gap-6">
            <div className="w-full">
              <SearchableDropDown
                id="countryIso2Code"
                name="countryIso2Code"
                options={countriesOptions.length > 0 ? countriesOptions : []}
                handleChange={handleCountryChange}
                loading={countriesLoading}
                borderText={`School Country ${!formFilled && !profileInfo.countyId ? "*" : ""}`}
              />
            </div>
            <div className="w-full">
              <SearchableDropDown
                id="countyId"
                name="countyId"
                options={countiesOptions.length > 0 ? countiesOptions : []}
                handleChange={handleCountyChange}
                loading={countiesLoading}
                borderText={`County/State ${!formFilled && !profileInfo.countyId ? "*" : ""}`}
              />
            </div>
          </div>*/}
        </div>

        <div className="flex flex-col sm:flex-row gap-2 sm:gap-6">
          <button className="account-settings-button rounded-button gray-button w-full">
            <span className="">
              <svg
                width="13"
                height="13"
                viewBox="0 0 64 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M28.4454 36.0555H35.5565V28.9444H28.4454V36.0555ZM62.1698 7.35861L57.1423 2.33105L37.0285 22.4448L42.056 27.4724L62.1698 7.35861ZM1.83203 57.6413L6.85959 62.6688L26.9734 42.5586L21.9423 37.5311L1.83203 57.6413ZM37.0285 42.5586L57.1423 62.6688L62.1698 57.6413L42.056 37.5311L37.0285 42.5586ZM1.83203 7.36217L21.9458 27.4724L26.9769 22.4448L6.85959 2.33105L1.83203 7.36217Z"
                  fill="white"
                />
              </svg>
            </span>
            <span>Revert all Changes</span>
          </button>
          <button className="account-settings-button rounded-button green-button w-full">
            <span className="">
              <svg
                width="15"
                height="13"
                viewBox="0 0 75 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_52244_11754)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.4849 46.8678L7.63689 32.0251L0.212891 39.4491L22.4849 61.7211L59.5996 24.6064L52.1756 17.1771L22.4849 46.8678ZM67.0236 2.3291L59.5996 9.7531L67.0236 17.1771L74.4476 9.7531L67.0236 2.3291Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_52244_11754">
                    <rect
                      width="74.6667"
                      height="64"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span>Save all Changes</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileInformation;

import {
  CREATE_MISSING_SCHOOL_FAILURE,
  CREATE_MISSING_SCHOOL_SUCCESS,
  CREATE_MISSING_SCHOOL_LOADING
} from './constants';

const initState = {
  loading: false,
  result: {data: {}, msg: "", success: false},
  error: null
}

export default function createMissingSchool(state=initState, action) {
  const { type, result, error } = action

  switch(type) {
    case CREATE_MISSING_SCHOOL_LOADING:
      return { ...state, loading: true }

    case CREATE_MISSING_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        result
      }

    case CREATE_MISSING_SCHOOL_FAILURE:
      return {
        ...state,
        loading: false,
        error
      }

    default:
      return state;
  }
}
import {
  USER_LOGIN_FAILURE,
  USER_LOGIN_LOADING,
  USER_LOGIN_SUCCESS
} from './constants'

const initState = {
  loading: false,
  result: {data: {}, msg: "", success: false},
  error: null
}

export default function userLogin(state=initState, action) {
  const { type, result, error } = action

  switch(type) {
    case USER_LOGIN_LOADING:
      return { ...state, loading: true }

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        result
      }

    case USER_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error
      }

    default:
      return state;
  }
}
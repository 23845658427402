import React from "react";

const VideosHeader = () => {
  return (
    <div className="mt-16 lg:mt-20 ml-0">
      <div
        className={`pt-8 flex h-full px-4 md:px-6 lg:px-8 text-center bg-primary-main-700 justify-center bg-cover bg-center animate-pulse`}
        style={{
          backgroundImage: `url("/images/header-bg-ticet_videos.png")`,
          backgroundRepeat: "repeat-x",
          animation: "slide 150s linear infinite",
        }}
      >
        <div className="flex flex-col items-center max-w-8xl">
          <div className="flex flex-col items-center gap-4 max-w-5xl">
            <title className="text-app-colors-heading-d dark:text-app-colors-heading-d mb-2 font-medium">
              ticet edu VIDEOS
            </title>
            <p className=" text-app-colors-heading-d dark:text-app-colors-heading-d mb-2">
              ticet Videos is your gateway to a dynamic, visual learning
              experience. Users can upload and share educational videos,
              creating a rich repository of multimedia content that enhances
              learning. Dive into a world where knowledge comes to life through
              videos, breaking down traditional classroom barriers and bringing
              education to your fingertips.
            </p>
          </div>
          <img src="/images/videos-bottom.webp" alt="" className="flex w-full" />
        </div>
      </div>
    </div>
  );
};

export default VideosHeader;

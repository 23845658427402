import React, { useState } from "react";
import moment from "moment";

const BorderDateInput = ({
  borderText,
  id,
  name,
  type = "date",
  value = undefined,
  handleInputChange,
  bgColor = "dark:bg-app-colors-surface-d bg-app-colors-surface",
}) => {
  const [focused, setFocused] = useState(false);

  // Function to trigger the native date picker
  const triggerDatePicker = () => {
    document.getElementById(id).showPicker();
  };

  return (
    <div>
      <span className="block">
        <div
          className={`relative borders flex flex-col sm:h-10 lg:h-12 text-app-colors-p-small dark:text-app-colors-p-small-d focus-within:border-primary-main-700 focus:text-primary-main-700 rounded ${borderText.includes("*") ? "border-secondary-red-500 focus-within:border-secondary-red-500" : ""}`}
        >
          <div className="relative">
            <span
              className={`absolute inline-flex ml-2 ${bgColor} py-0 px-1 -top-3 text-xs md:text-sm ${focused ? "text-primary-main-700" : ""} ${borderText.includes("*") ? "text-secondary-red-500" : ""}`}
            >
              {borderText}
            </span>
          </div>

          <div className="flex flex-grow pt-3">
            <input
              id={id}
              name={name}
              type={type}
              onChange={handleInputChange}
              max={moment().format("YYYY-MM-DD")}
              className={
                "pl-3 text-base font-medium text-app-colors-p-normal dark:text-app-colors-p-normal-d bg-transparent border-0 px-1 pt-0 focus:ring-0 w-full rounded line-clamp-1 date-input-custom"
              }
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              defaultValue={value}
            />
            <div
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
              onClick={triggerDatePicker}
            >
              <svg
                className="icons"
                width="20"
                height="20"
                viewBox="0 0 53 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_52244_11740)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M46.5455 64.5H52.3636V58.6847H46.5455V64.5ZM46.5455 6.32109H40.7273V3.40909C40.7273 1.80327 39.4269 0.5 37.8182 0.5C36.2095 0.5 34.9091 1.80327 34.9091 3.40909V6.31818H17.4545V3.40909C17.4545 1.80327 16.1513 0.5 14.5455 0.5C12.9367 0.5 11.6364 1.80327 11.6364 3.40909V6.31818H5.81818C2.60655 6.31818 0 8.92182 0 12.1364V58.6818C0 61.8964 2.60655 64.5 5.81818 64.5H40.7273V58.6847H5.81818V12.1364H11.6364V15.0455C11.6364 16.6513 12.9367 17.9545 14.5455 17.9545C16.1513 17.9545 17.4545 16.6513 17.4545 15.0455V12.1364H34.9091V15.0455C34.9091 16.6513 36.2095 17.9545 37.8182 17.9545C39.4269 17.9545 40.7273 16.6513 40.7273 15.0455V12.1364H46.5455V52.8636H52.3636V12.1364C52.3636 8.92473 49.7571 6.32109 46.5455 6.32109ZM14.5455 52.8636H20.3636V47.0455H14.5455V52.8636ZM23.2727 52.8636H29.0909V47.0455H23.2727V52.8636ZM32 52.8636H37.8182V47.0455H32V52.8636ZM20.3636 38.3182H14.5455V44.1393H20.3636V38.3182ZM29.0909 38.3182H23.2727V44.1393H29.0909V38.3182ZM37.8182 38.3182H32V44.1393H37.8182V38.3182ZM20.3636 29.5909H14.5455V35.4091H20.3636V29.5909ZM29.0909 29.5909H23.2727V35.4091H29.0909V29.5909ZM37.8182 29.5909H32V35.4091H37.8182V29.5909Z"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_52244_11740">
                    <rect
                      width="52.3636"
                      height="64"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </span>
    </div>
  );
};

export default BorderDateInput;

import React from 'react';

const subjectsBgImage = {
  background: `url("/images/account_setup_subjects_bg.jpg")`
}

const connectionsBgImage = {
  background: `url("/images/account_setup_connections_bg.jpg")`
}

const librariesBgImage = {
  background: `url("/images/account_setup_libraries_bg.jpg")`
}

const AccountSetupTop = ({themeColor, page, subjectsPageRoute, connectionsPageRoute, librariesPageRoute}) => {
  return (
      <div
        style={(page === 'subjects' && subjectsBgImage) || (page === 'connections' && connectionsBgImage) || (page === 'libraries' && librariesBgImage)}
        className={`bg-i-${themeColor}-400 text-white text-center mt-0 py-3 px-5 sm:px-9 md:px-20 lg:px-32`}
      >
        <h2 className="mt-8 sm:mt-12 lg:mt-16 uppercase text-2xl lg:text-4xl">You are almost there</h2>
        <p className="text-sm mt-3 sm:mt-5">
          Welcome to eLIMU INFOBRIX. Kindly set up your account by choosing subjects that are of interest to you, adding connections and following libaries. These are optional but highly recommended at this stage. Click ‘✓ COMPLETE’ to finish the process
        </p>

        <div className="mt-12 mx-7 sm:mx-16 lg:mx-24">
          <div className="text-sm mb-12 md:mb-16 flex flex-nowrap justify-center">
            <button
              className={`flex rounded-full ${ page === 'subjects' ? 'bg-i-purple-500 text-white' : 'bg-transparent text-gray-200 border border-white' } px-4 py-2 mr-4`}
              onClick={subjectsPageRoute}
            >
              <span className="my-auto md:mr-1">
                <svg width="12" height="13" viewBox="0 0 63 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.2227 39.0557L38.556 39.0557" stroke={`${ page === 'subjects' ? 'white' : '#cbd5e1'}`} strokeWidth="6" strokeLinecap="round"/>
                  <path d="M17.2227 24.8335L31.4449 24.8335" stroke={`${ page === 'subjects' ? 'white' : '#cbd5e1'}`} strokeWidth="6" strokeLinecap="round"/>
                  <path d="M17.2227 53.2778L31.4449 53.2778" stroke={`${ page === 'subjects' ? 'white' : '#cbd5e1'}`} strokeWidth="6" strokeLinecap="round"/>
                  <path d="M52.7778 39.0556V61.5C52.7778 64.3284 52.7778 65.7426 51.8991 66.6213C51.0204 67.5 49.6062 67.5 46.7778 67.5H8.99999C6.17157 67.5 4.75736 67.5 3.87868 66.6213C3 65.7426 3 64.3284 3 61.5V9.5C3 6.67157 3 5.25736 3.87868 4.37868C4.75736 3.5 6.17157 3.5 9 3.5H24.3333" stroke={`${ page === 'subjects' ? 'white' : '#cbd5e1'}`} strokeWidth="6"/>
                  <path d="M49.2227 3.5L49.2227 24.8333" stroke={`${ page === 'subjects' ? 'white' : '#cbd5e1'}`} strokeWidth="6" strokeLinecap="round"/>
                  <path d="M59.8887 14.1665L38.5553 14.1665" stroke={`${ page === 'subjects' ? 'white' : '#cbd5e1'}`} strokeWidth="6" strokeLinecap="round"/>
                </svg>
              </span>
              <span className={`${page === 'subjects' ? 'block ml-1' : 'hidden'} md:block text-xs md:text-sm`}>Choose Subjects</span>
            </button>

            {/*<button*/}
            {/*  className={`flex rounded-full ${ page === 'connections' ? 'bg-i-purple-500 text-white' : 'bg-transparent text-gray-300 border border-white' } px-4 py-2 mr-4`}*/}
            {/*  onClick={connectionsPageRoute}*/}
            {/*>*/}
            {/*  <span className="my-auto md:mr-1">*/}
            {/*    <svg width="13" height="13" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-white">*/}
            {/*      <g clipPath="url(#clip0_52244_11757)">*/}
            {/*        <path fillRule="evenodd" clipRule="evenodd" d="M48.0256 4.8264V12.532C53.8752 17.2232 57.6256 24.4168 57.6256 32.4968C57.6256 43.236 51.008 52.4168 41.6256 56.2184V62.9736C54.5952 58.8936 64 46.788 64 32.4744C64 20.6568 57.568 10.3624 48.0256 4.8264ZM6.4256 32.5C6.4256 24.42 10.1792 17.2264 16.0256 12.532V4.7912C6.4576 10.3208 0 20.6344 0 32.4744C0 46.8072 9.4304 58.932 22.4256 62.9992V56.2184C13.0496 52.42 6.4256 43.236 6.4256 32.5ZM16.0256 32.4968H22.4256V26.1H16.0256V32.4968ZM41.6256 32.4968H48.0256V26.1H41.6256V32.4968ZM22.3968 39.2264C22.3968 42.5768 26.688 45.2904 31.984 45.2904C37.2768 45.2904 41.5712 42.5768 41.5712 39.2264V38.9064H22.3968V39.2264ZM28.8256 19.7H35.2256V13.3H41.6256V6.9H35.2256V0.5H28.8256V6.9H22.4256V13.3H28.8256V19.7Z" fill={`${ page === 'connections' ? 'white' : '#cbd5e1'}`}/>*/}
            {/*        <path fillRule="evenodd" clipRule="evenodd" d="M28.8008 58.1001H35.2008V64.5001H28.8008V58.1001Z" fill={`${ page === 'connections' ? 'white' : '#cbd5e1'}`}/>*/}
            {/*      </g>*/}
            {/*      <defs>*/}
            {/*        <clipPath id="clip0_52244_11757">*/}
            {/*          <rect width="64" height="64" fill="white" transform="translate(0 0.5)"/>*/}
            {/*        </clipPath>*/}
            {/*      </defs>*/}
            {/*    </svg>*/}
            {/*  </span>*/}
            {/*  <span className={`${page === 'connections' ? 'block' : 'hidden'} md:block text-xs md:text-sm`}>Add Connections</span>*/}
            {/*</button>*/}

            <button
              className={`flex rounded-full ${ page === 'libraries' ? 'bg-i-purple-500 text-white' : 'bg-transparent text-gray-300 border border-white' } px-4 py-2 mr-4`}
              onClick={librariesPageRoute}
            >
              <span className="my-auto md:mr-1">
                <svg width="14" height="13" viewBox="0 0 75 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M63.2353 37.3824V18.7941C63.2353 16.9085 63.2353 15.9657 62.6495 15.3799C62.0637 14.7941 61.1209 14.7941 59.2353 14.7941H38.5392C37.7217 14.7941 37.313 14.7941 36.9454 14.6419C36.5779 14.4896 36.2888 14.2006 35.7108 13.6225L26.7598 4.67157C26.1817 4.09351 25.8927 3.80448 25.5252 3.65224C25.1576 3.5 24.7489 3.5 23.9314 3.5H7C5.11438 3.5 4.17157 3.5 3.58579 4.08579C3 4.67157 3 5.61438 3 7.5V52.2059C3 54.0915 3 55.0343 3.58579 55.6201C4.17157 56.2059 5.11438 56.2059 7 56.2059H44.4118" stroke={`${ page === 'libraries' ? 'white' : '#cbd5e1'}`} strokeWidth="6"/>
                  <path d="M51.9414 56.2057H63.2355M63.2355 56.2057H74.5296M63.2355 56.2057V67.4999M63.2355 56.2057V44.9116" stroke={`${ page === 'libraries' ? 'white' : '#cbd5e1'}`} strokeWidth="6"/>
                  <rect x="3.5" y="4" width="70.5294" height="63" stroke={`${ page === 'libraries' ? 'white' : '#cbd5e1'}`}/>
                </svg>
              </span>
              <span className={`${page === 'libraries' ? 'block' : 'hidden'} md:block text-xs md:text-sm`}>Follow Libraries</span>
            </button>

            <button className="flex rounded-full bg-white text-gray-500 px-4 py-2">
              <span className="my-auto md:mr-1">
                <svg width="14" height="13" viewBox="0 0 75 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_52244_11754)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M22.4849 46.8678L7.63689 32.0251L0.212891 39.4491L22.4849 61.7211L59.5996 24.6064L52.1756 17.1771L22.4849 46.8678ZM67.0236 2.3291L59.5996 9.7531L67.0236 17.1771L74.4476 9.7531L67.0236 2.3291Z" fill="#64748b"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_52244_11754">
                    <rect width="74.6667" height="64" fill="white" transform="translate(0 0.5)"/>
                  </clipPath>
                  </defs>
                </svg>
              </span>
              <span className={`hidden md:block text-xs md:text-sm my-auto`}>Complete</span>
            </button>
          </div>
        </div>
      </div>
  );
};

export default AccountSetupTop;
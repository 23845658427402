import {
  USER_REGISTRATION_FAILURE,
  USER_REGISTRATION_LOADING,
  USER_REGISTRATION_SUCCESS,
  SET_USER_INFO_SUCCESS,
  SET_USER_INFO_FAILURE
} from './constants'

const registrationInitState = {
  loading: false,
  message: [],
  error: null
}

const userInitialState = {
  payload: [],
  error: null
}

export function userRegistration(state=registrationInitState, action) {
  const { type, message, error } = action

  switch(type) {
    case USER_REGISTRATION_LOADING:
      return { ...state, loading: true }

    case USER_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        message
      }

    case USER_REGISTRATION_FAILURE:
      return {
        ...state,
        loading: false,
        error
      }

    default:
      return state;
  }
}

export function setUserInfo(state=userInitialState, action) {
  const { type, payload, error } = action

  switch(type) {
    case SET_USER_INFO_SUCCESS:
      console.log("parent reducer-->", payload)
      return {
        ...state, payload
      };
    case SET_USER_INFO_FAILURE:
      return {
        ...state, error
      };
    default:
      return state;
  }
}
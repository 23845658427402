import {
  UPLOAD_IMAGE_LOADING,
  UPLOAD_IMAGE_FAILURE,
  UPLOAD_IMAGE_SUCCESS
} from './constants';

const initialState = {
  loading: false,
  payload: [],
  error: null
};

export default function UploadImage(state = initialState, action) {
  const { type, payload, error } = action;

  switch(type) {
    case UPLOAD_IMAGE_LOADING:
      return { ...state, loading: true };
    case UPLOAD_IMAGE_SUCCESS:
      let index = state.payload.findIndex(x => x.id === payload.id);
      return {
        ...state,
        loading: false,
        // payload: [...state.payload, payload]
        payload: index === -1 ? [...state.payload, payload] : state.payload
      };
    case UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error
      };
    default:
      return state;
  }
}

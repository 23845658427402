import { createStore, compose, applyMiddleware } from 'redux';
import { logger } from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from '../rootReducer';

const configStore = () => {
  const enhancers = [];
  const middlewares = [
    thunk,
  ];

  if (process.env.NODE_ENV === 'development') {
    enhancers.push(applyMiddleware(logger));
  }

  const composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers);

  return createStore(rootReducer, composedEnhancers)
}

export default configStore;

import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  GET_CURRICULA_LOADING,
  GET_CURRICULA_FAILURE,
  GET_CURRICULA_SUCCESS
} from './constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const getCurriculaLoading = () => ({
  type: GET_CURRICULA_LOADING
});

export const getCurriculaSuccess = payload => ({
  type: GET_CURRICULA_SUCCESS,
  payload
});

export const getCurriculaFailure = error => ({
  type: GET_CURRICULA_FAILURE,
  error
});

export const getCurriculaAction = (countryId) => async dispatch => {
  console.log("country Id-->", countryId)
  dispatch(getCurriculaLoading());

  axios.get(`${CLIENT_API_URL}/curricula?countryId=${countryId}`, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
      .then(res => {
        console.log('Curricula action called --');
        console.log('curricula -->', res.data);
        dispatch(getCurriculaSuccess(res.data));
      })
      .catch(error => {
        console.log('Curricula action failed --');
        console.log('Curricula action failed --', error);
        dispatch(getCurriculaFailure({
          message: 'An error occurred while fetching curricula'
        }))
      })
}
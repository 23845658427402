import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NavBar from '../../../../components/navbars/signedOutNavbar';
import Footer from '../../../../components/footer';
import ROUTES from '../../../../utils/routes';
import {backgroundImage, reverseDateString} from '../../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { setTeacherPersonalInfo } from './action';
import SearchableDropDown from '../../../../components/common/searchableDropDown';
import moment from 'moment';
import InternationalPhoneField from '../../../../components/common/internationalPhoneField';
import InformationAccordion from '../../../../components/common/informationAccordion';
import BorderedTextInput from '../../../../components/common/borderedTextInput';
import BorderSelectInput from '../../../../components/common/borderSelectInput';
import BorderDateInput from '../../../../components/common/borderDateInput';
import {getNationalitiesAction} from '../../../../commonData/nationalities/action';

export default function TeacherteacherPInfo() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userInfo = useSelector(state => state.setUserInfo.payload)
  const countries = useSelector(state => state.getNationalities.payload.data);
  const countriesOptions = [];

  countries && countries.map(country => (
    countriesOptions.push({ label: country.name, value: country })
  ))

  const [teacherPInfo, setTeacherPInfo] = useState({});
  const [formFilled, setFormFilled] = useState(true);

  const nextRouteChange = e => {
    e.preventDefault();
    if (teacherPInfo.phoneNumber && teacherPInfo.dateOfBirth && teacherPInfo.gender && teacherPInfo.countryIso2Code &&
      teacherPInfo.nationalIdNumber && teacherPInfo.tscNumber) {
      setFormFilled(true)
    } else {
      return setFormFilled(false)
    }
    const info = {
      phoneNumber: teacherPInfo.phoneNumber,
      dateOfBirth: reverseDateString(teacherPInfo.dateOfBirth),
      gender: teacherPInfo.gender,
      countryIso2Code: teacherPInfo.countryIso2Code,
      nationalIdNumber: teacherPInfo.nationalIdNumber,
      tscNumber: teacherPInfo.tscNumber
    };
    dispatch(setTeacherPersonalInfo(info))
    let path = ROUTES.teacherSignUpSelectSchool;
    navigate(path);
  }

  const backRouteChange = () => {
    let path = ROUTES.accountType;
    navigate(path);
  }

  useEffect(() => {
    dispatch(getNationalitiesAction());
  }, [dispatch])

  const handleChange = e => {
    setTeacherPInfo({ ...teacherPInfo, [e.target.name]: e.target.value });
  }

  const handleCountryChange = code => {
    teacherPInfo["countryIso2Code"] = code;
  }

  const handlePhoneChange = phone => {
    setTeacherPInfo({ ...teacherPInfo, 'phoneNumber': phone });
    dispatch(setTeacherPersonalInfo(teacherPInfo));
  }

  return (
    <div className="bg-i-purple-500 bg-no-repeat bg-cover bg-center flex flex-col w-full h-full min-h-screen bg-blend-overlay"
         style={{backgroundImage: `url(${backgroundImage})`}}>
      <NavBar />
      <div className="flex flex-col-reverse lg:flex-row lg:space-x-2">
        <div className="lg:mt-52">
          <div>
            <InformationAccordion
              subTittle={'SOCIAL MEDIA LEARNING PLATFORM'}
              title1={'Collaborate'}
              title2={'Network'}
              title3={'Engage'}
              content1={<Fragment>
                <p>
                  eLIMU INFOBRIX incorporates social, collaboration, and education technologies into one platform
                  tailored for students, teachers, and school administrators in Kenya.
                </p>
                <p>
                  Access unlimited learning/teaching resources, share your thoughts, write blogs, earn badges,
                  and so much more.
                </p>
              </Fragment>}
              content2={<Fragment>
                <p>
                  eLIMU INFOBRIX incorporates social, collaboration, and education technologies into one platform
                  tailored for students, teachers, and school administrators in Kenya.
                </p>
                <p>
                  Access unlimited learning/teaching resources, share your thoughts, write blogs, earn badges,
                  and so much more.
                </p>
              </Fragment>}
              content3={<Fragment>
                <p>
                  eLIMU INFOBRIX incorporates social, collaboration, and education technologies into one platform
                  tailored for students, teachers, and school administrators in Kenya.
                </p>
                <p>
                  Access unlimited learning/teaching resources, share your thoughts, write blogs, earn badges,
                  and so much more.
                </p>
              </Fragment>}
            />
          </div>
          <div></div>
        </div>
        <div className="w-full">
          <div className="w-80 sm:w-96 md:w-128 ml-auto mr-auto lg:mr-14 mt-16 sm:mt-32">
            {!formFilled ? <div className="text-xs text-white text-center bg-ired mb-2 py-1">Please fill in the missing fields</div> : null}
            <div className="px-4 py-5 bg-i-purple-50 rounded-md">
              <h3 className="uppercase text-xl text-center">Personal Information</h3>
              <div className="mt-4">
                <form action="">
                  <div className="grid sm:grid-cols-2 lg:grid-cols-5">
                    <div className="lg:col-span-2">
                      <BorderedTextInput
                        type={'text'}
                        borderText={'First Name'}
                        value={userInfo.firstName && userInfo.firstName}
                        disabled={true}
                      />
                    </div>
                    <div className="lg:col-span-3">
                      <BorderedTextInput
                        type={'text'}
                        borderText={'Other Names'}
                        value={userInfo.lastName && userInfo.lastName}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="mt-3 grid sm:grid-cols-2">
                    <BorderedTextInput
                      type={'text'}
                      borderText={'Username'}
                      value={userInfo.username && userInfo.username}
                      disabled={true}
                    />
                    <BorderedTextInput
                      type={'text'}
                      borderText={'Email Address'}
                      value={userInfo.email && userInfo.email}
                      disabled={true}
                    />
                  </div>
                  <div className="mt-3 grid sm:grid-cols-2">
                    <InternationalPhoneField
                      value={teacherPInfo.phoneNumber ? teacherPInfo.phoneNumber : ""}
                      defaultCountry="KE"
                      borderText={`Phone Number ${(!formFilled && !teacherPInfo.phoneNumber) ? '*' : ''}`}
                      handlePhoneChange={handlePhoneChange}
                    />
                    <BorderDateInput
                      id={'dateOfBirth'}
                      name={'dateOfBirth'}
                      borderText={`Date of Birth ${(!formFilled && !teacherPInfo.dateOfBirth) ? '*' : ''}`}
                      handleInputChange={handleChange}
                      value={teacherPInfo.dateOfBirth ? teacherPInfo.dateOfBirth : ""}
                    />
                  </div>

                  <div className="mt-3 grid sm:grid-cols-2">
                    <BorderSelectInput
                      id={'gender'}
                      name={'gender'}
                      title={'gender'}
                      options={['male', 'female']}
                      handleInputChange={handleChange}
                      borderText={`Gender ${(!formFilled && !teacherPInfo.gender) ? '*' : ''}`}
                    />
                    <SearchableDropDown
                      id="countryIso2Code"
                      name="countryIso2Code"
                      placeholder="NATIONALITY"
                      options={countriesOptions.length > 0 ? countriesOptions : []}
                      handleChange={handleCountryChange}
                      borderText={`Nationality ${(!formFilled && !teacherPInfo.countryIso2Code) ? '*' : ''}`}
                    />
                  </div>
                  <div className="mt-3 grid sm:grid-cols-2">
                    <BorderedTextInput
                      type={'text'}
                      name={'tscNumber'}
                      borderText={`TSC Number ${(!formFilled && !teacherPInfo.tscNumber) ? '*' : ''}`}
                      value={teacherPInfo.tscNumber ? teacherPInfo.tscNumber : ""}
                      handleInputChange={handleChange}
                    />
                    <BorderedTextInput
                      type={'text'}
                      name={'nationalIdNumber'}
                      borderText={`National ID Number ${(!formFilled && !teacherPInfo.nationalIdNumber) ? '*' : ''}`}
                      value={teacherPInfo.nationalIdNumber ? teacherPInfo.nationalIdNumber : ""}
                      handleInputChange={handleChange}
                    />
                  </div>
                  <div className="mt-3 flex mx-4">
                    <div className="mr-auto">
                      <button className="bg-i-purple-50 px-9 py-2 text-xs text-i-purple-500 ml-auto rounded-full border border-gray-500" onClick={backRouteChange}>Back</button>
                    </div>
                    <div className="ml-auto">
                      <button className="bg-i-purple-500 px-9 py-2 text-xs text-white ml-auto rounded-full"
                        onClick={e => nextRouteChange(e)}
                      >Next</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="mt-3 grid sm:grid-cols-2 sm:gap-2">
              <div className="mx-auto"></div>
              <div className="text-right">
                <button className="bg-i-purple-50 px-9 py-2 text-xs ml-auto rounded-full" onClick={nextRouteChange}>Log Out</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto flex-end">
        <Footer />
      </div>
    </div>
  )
}

import React from "react";

const ConnectionsCard = ({ fullName, username, buttonLabel }) => {
  return (
    <div>
      <div className="bg-white h-full rounded">
        <div
          className="py-4 bg-no-repeat bg-cover bg-center h-28 w-full rounded-t"
          style={{ backgroundImage: "url(/images/card-bg.jpeg)" }}
        ></div>
        <div className="flex flex-col items-center pb-10 px-6 -mt-12">
          <img
            className="mb-3 w-24 h-24 rounded-full shadow-lg border-4 shadow-none border-white"
            src="/images/test-avatar.png"
            alt="avatar"
          />
          <h3 className="mb-1 text-xl font-medium text-gray-900">{fullName}</h3>
          <span className="text-sm text-gray-500">{`@${username}`}</span>
          <div className="mt-4 space-x-3 lg:mt-6">
            <button className="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-white bg-iblue rounded-lg">
              {buttonLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionsCard;

import React from "react";
import WorkSpacePublishedItem from "../../../../components/common/workSpacePublishedItem";

const Published = () => {
  return (
    <div>
      <div className="grid auto-rows-max gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
        <WorkSpacePublishedItem
          item={"English - First Language (0500)"}
          noOfItems={241}
        />
        <WorkSpacePublishedItem
          item={"English - Literature in English (0475)"}
          noOfItems={301}
        />
        <WorkSpacePublishedItem
          item={"English"}
          img={"/images/bg-birthdays-2.jpg"}
          noOfItems={125}
        />
        <WorkSpacePublishedItem
          item={"Swahili - Foreign Language (0546)"}
          noOfItems={82}
        />
        <WorkSpacePublishedItem
          item={"Mathematics - International (0607"}
          noOfItems={174}
        />
        <WorkSpacePublishedItem item={"Biology (0610)"} />
      </div>
    </div>
  );
};

export default Published;

import axios from 'axios';
import { CLIENT_API_URL } from '../../../../utils/constants';

import {
  CREATE_TEACHER_FAILURE,
  CREATE_TEACHER_LOADING,
  CREATE_TEACHER_SUCCESS
} from './constants'
import {getJwtTokenCookie} from '../../../../utils/cookieHelper';

export const createTeacherLoading = () => {
  return {
    type: CREATE_TEACHER_LOADING
  }
}

export const createTeacherSuccess = message => {
  return {
    type: CREATE_TEACHER_SUCCESS,
    message
  }
}

export const createTeacherFailure = error => {
  return {
    type: CREATE_TEACHER_FAILURE,
    error
  }
}

export const createTeacher = ({ institutionId, curriculaLevelsIds }) => async dispatch => {
  const teacherDetails = {institutionId, curriculaLevelsIds}

  dispatch(createTeacherLoading())
  console.log('teacherDetails---', teacherDetails)
  axios.post(`${CLIENT_API_URL}/account-setup/teachers`, teacherDetails, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
  .then(res => {
    console.log('New Teacher -->', res);
    dispatch(createTeacherSuccess(['Teacher registered successfully', res.data]));
  })
  .catch(error => {
    console.log('Teacher registration failed --');
    console.log("Teacher error ==>", error)
    dispatch(createTeacherFailure({
      message: 'An error occurred while registering teacher'
    }))
  })
}
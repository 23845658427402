import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ROUTES from '../../utils/routes';
import {useNavigate} from 'react-router-dom';

// Styled component for the sidebar container
const SidebarDiv = styled.div`
  position: fixed;
  height: 100%;
`;

const Sidebar = ({ navWidth, label }) => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
  const sidebarRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsExpanded(false);
        document.getElementById("icons-labels").classList.add("hidden");
      }
    };

    window.addEventListener("resize", handleResize);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
    document.getElementById("icons-labels").classList.toggle("hidden");
  };

  return (
    <SidebarDiv
      ref={sidebarRef}
      className={`z-30 sidebar ${isExpanded ? "sidebar-expanded" : "sidebar-collapsed"}`}
    >
      <div
        id="sidebar"
        className={`lg:h-screen fixed min-w-max flex flex-col ${isExpanded ? "h-screen w-fit" : "w-16 lg:w-20"}`}
      >
        {isLargeScreen ? (
          <div id="logo-header" className="block">
            <button className="flex justify-start pl-4 items-center w-full h-16 lg:h-20 py-4 bg-primary-800 dark:bg-primary-900"
                    onClick={() => navigate(ROUTES.userSpaceLibrary)}
            >
              <img
                id="ticet-logo"
                className="h-full"
                src={isExpanded ? "/images/logo-light.svg" : "/images/logo.svg"}
                alt="ticet logo"
              />
            </button>
          </div>
        ) : (
          <div id="logo-header" className="block" onClick={toggleSidebar}>
            <button className="flex justify-start pl-4 lg:pl-6 items-center w-full h-16 lg:h-20 py-4 bg-primary-800 dark:bg-primary-900">
              <img
                id="ticet-logo"
                className="h-full"
                src={isExpanded ? "/images/logo-light.svg" : "/images/logo.svg"}
                alt="ticet logo"
              />
            </button>
          </div>
        )}
        <div
          id="icons-labels"
          className="hidden lg:block surface flex-grow py-6"
        >
          <div className="flex flex-col justify-between h-full items-start pl-8 pr-0">
            <div className="h-full">
              <button
                id="expand-collapse-sidebar"
                className="group cursor-pointer hidden lg:flex gap-4 items-center justify-start w-full h-14 rounded-lg"
                onClick={toggleSidebar}
              >
                <div className="flex justify-center w-5">
                  {isExpanded ? (
                    <svg
                      id="expanded-icon"
                      className="icons"
                      viewBox="0 0 64 65"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.4454 36.0555H35.5565V28.9444H28.4454V36.0555ZM62.1698 7.35861L57.1423 2.33105L37.0285 22.4448L42.056 27.4724L62.1698 7.35861ZM1.83203 57.6413L6.85959 62.6688L26.9734 42.5586L21.9423 37.5311L1.83203 57.6413ZM37.0285 42.5586L57.1423 62.6688L62.1698 57.6413L42.056 37.5311L37.0285 42.5586ZM1.83203 7.36217L21.9458 27.4724L26.9769 22.4448L6.85959 2.33105L1.83203 7.36217Z"
                      />
                    </svg>
                  ) : (
                    <svg
                      id="minimized-icon"
                      className="icons"
                      viewBox="0 0 83 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.14286 0H0V9.14286H9.14286V0ZM18.2857 0V9.14286H82.2857V0H18.2857ZM27.4286 27.4286H0V36.5714H27.4286V27.4286ZM36.5714 27.4286V36.5714H45.7143V27.4286H36.5714ZM54.8571 36.5714H82.2857V27.4286H54.8571V36.5714ZM0 64H64V54.8617H0V64ZM73.1429 64H82.2857V54.8617H73.1429V64Z"
                      />
                    </svg>
                  )}
                </div>
                <span
                  className={`menu-label pl-4 pr-10 ${isExpanded || window.innerWidth < 1024 ? "block" : "hidden"}`}
                >
                  <p className="small font-semibold text-hover-active">
                    Collapse Sidebar
                  </p>
                </span>
              </button>
              <button
                className="group cursor-pointer flex gap-4 items-center justify-start w-full h-14 rounded-lg"
                onClick={() => navigate(ROUTES.userSpaceLibrary)}
              >
                <span className="flex justify-center w-5">
                  <svg
                    className="icons"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_52244_11744)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M41.5896 51.1971V32.0003H47.9928V51.1971H41.5896ZM28.7896 51.1971V32.0003H35.1928V51.1971H28.7896ZM15.9992 51.1715V31.9715H22.3992V51.1715H15.9992ZM19.1992 9.59707V15.9939H12.7992V9.59707H19.1992ZM28.7992 9.59707V15.9939H22.3992V9.59707H28.7992ZM38.3992 9.59707V15.9939H31.9992V9.59707H38.3992ZM54.3992 25.5939H9.59922V19.1939H54.3992V25.5939ZM47.9992 63.9907H9.59922H3.19922V54.3907V44.7875V6.39707C3.19922 2.86107 6.06642 -0.00292969 9.59922 -0.00292969H54.3992C57.932 -0.00292969 60.7992 2.86107 60.7992 6.39707V25.5971H54.3992V6.39707H9.59922V44.7907V57.5939H41.5992L54.3992 57.6003V44.7907H60.7992V51.1939V64.0003L47.9992 63.9907ZM54.3992 31.9939H60.7992V38.3939H54.3992V31.9939Z"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_52244_11744">
                        <rect width="64" height="64" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span
                  className={`menu-label pl-4 pr-10 ${isExpanded || window.innerWidth < 1024 ? "block" : "hidden"}`}
                >
                  <p className="small font-semibold text-hover-active">
                    Library
                  </p>
                </span>
              </button>

              <button
                className="group cursor-pointer flex gap-4 items-center justify-start w-full h-14 rounded-lg"
                onClick={() => navigate(ROUTES.myWorkspace)}
                href="/user_space/my_workspace"
              >
                <span className="flex justify-center w-5">
                  <svg
                    className="icons"
                    viewBox="0 0 71 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_52244_11732)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M64 44.8H6.4V6.4H70.4C70.4 2.8672 67.5328 0 64 0H6.4C2.8672 0 0 2.864 0 6.4V44.8C0 48.3328 2.8672 51.2032 6.4 51.2032H25.6V57.6H16V64H54.4V57.6H44.8V51.2032H64C67.5328 51.2032 70.4 48.336 70.4 44.8V25.6032H64V44.8ZM38.4 57.6H32V51.2032H38.4V57.6ZM64 19.2H70.4V12.8032H64V19.2ZM25.6 28.8032V22.4032H19.2V28.8032H25.6ZM38.4 28.8032V22.4032H32V28.8032H38.4ZM51.2 28.8032V22.4032H44.8V28.8032H51.2Z"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_52244_11732">
                        <rect width="70.4" height="64" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span
                  className={`menu-label pl-4 pr-10 ${isExpanded || window.innerWidth < 1024 ? "block" : "hidden"}`}
                >
                  <p className="small font-semibold text-hover-active">
                    My Workspace
                  </p>
                </span>
              </button>

              <button
                className="group cursor-pointer flex gap-4 items-center justify-start w-full h-14 rounded-lg"
                onClick={() => navigate(ROUTES.communityVideos)}
              >
                <span className="flex justify-center w-5">
                  <svg
                    className="icons"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 14 16"
                    enableBackground="new 0 0 14 16"
                  >
                    <path
                      d="M12.355,5.332L4.548,0.545C3.975,0.196,3.392,0.021,2.809,0.021C1.413,0.021,0,1.115,0,3.205V6h2
                       c0-2.392,0-2.796,0-2.796c0-0.749,0.314-1.184,0.809-1.184c0.204,0,0.438,0.074,0.694,0.229l7.815,4.792
                       c0.879,0.533,0.924,1.406,0.044,1.939l-7.859,4.79C3.247,13.927,3.012,14,2.808,14C2.314,14,2,13.566,2,12.818c0,0,0-0.3,0-0.793
                       c0-0.006,0-0.018,0-0.024H0v0.024v0.793C0,14.907,1.413,16,2.808,16c0.583,0,1.167-0.175,1.735-0.521l7.86-4.79
                       C13.416,10.074,14,9.107,14,8.038C14.001,6.952,13.401,5.966,12.355,5.332z"
                    />
                    <rect y="8" width="2" height="2" />
                  </svg>
                </span>
                <span
                  className={`menu-label pl-4 pr-10 ${isExpanded || window.innerWidth < 1024 ? "block" : "hidden"}`}
                >
                  <p className="small font-semibold text-hover-active">
                    ticet Videos
                  </p>
                </span>
              </button>
            </div>
            <div>
              <button
                className="group cursor-pointer flex gap-4 items-center justify-start w-full h-14 rounded-lg"
                onClick={() => navigate(ROUTES.home)}
              >
                {/*TODO: Add Announcement link*/}
                <span className="flex justify-center w-5">
                  <svg
                    className="icons"
                    viewBox="0 0 26 25"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.51721 13.5531L7.78824 12.6901H6.44604L8.39727 20.3103C8.55314 20.9222 9.10445 21.3494 9.73369 21.3494C10.1638 21.3494 10.5621 21.1531 10.8248 20.8154C11.0874 20.4748 11.1769 20.0418 11.0701 19.6262L9.51721 13.5531Z" />
                    <path d="M18.6069 0.855713C18.1277 0.855713 17.741 1.2425 17.741 1.72164V16.7311C17.741 17.2074 18.1277 17.597 18.6069 17.597C19.0832 17.597 19.4728 17.2074 19.4728 16.7311V1.72164C19.4728 1.2425 19.0832 0.855713 18.6069 0.855713Z" />
                    <path d="M8.50439 11.755L16.5864 15.796V2.65405L8.50439 6.69506V11.755Z" />
                    <path d="M24.6685 8.64905H22.9367C22.6192 8.64905 22.3594 8.90883 22.3594 9.22633C22.3594 9.54384 22.6192 9.80362 22.9367 9.80362H24.6685C24.986 9.80362 25.2458 9.54384 25.2458 9.22633C25.2458 8.90883 24.986 8.64905 24.6685 8.64905Z" />
                    <path d="M22.359 5.76262C22.5062 5.76262 22.6534 5.70489 22.766 5.59232L23.9206 4.43774C24.1457 4.2126 24.1457 3.84602 23.9206 3.62088C23.6954 3.39574 23.3288 3.39574 23.1037 3.62088L21.9491 4.77546C21.724 5.0006 21.724 5.36718 21.9491 5.59232C22.0617 5.70489 22.2118 5.76262 22.359 5.76262Z" />
                    <path d="M22.766 12.8575C22.5408 12.6323 22.1743 12.6323 21.9491 12.8575C21.724 13.0826 21.724 13.4492 21.9491 13.6743L23.1037 14.8289C23.2163 14.9415 23.3635 14.9992 23.5107 14.9992C23.6579 14.9992 23.8051 14.9415 23.9177 14.8289C24.1428 14.6037 24.1428 14.2372 23.9177 14.012L22.766 12.8575Z" />
                    <path d="M0.422363 9.2263C0.422363 10.023 1.06892 10.6695 1.86558 10.6695H2.15422V7.78308H1.86558C1.06892 7.78308 0.422363 8.42964 0.422363 9.2263Z" />
                    <path d="M7.34984 6.91724H3.88612C3.56573 6.91724 3.30884 7.17702 3.30884 7.49452V10.9582C3.30884 11.2758 3.56573 11.5355 3.88612 11.5355H7.34984V6.91724Z" />
                  </svg>
                </span>
                <span
                  className={`menu-label pl-4 pr-10 ${isExpanded || window.innerWidth < 1024 ? "block" : "hidden"}`}
                >
                  <p className="small font-semibold text-hover-active">
                    Announcement
                  </p>
                </span>
              </button>
              {/*  small popup with links */}
              <button
                className="group cursor-pointer flex gap-4 items-center justify-start w-full h-14 rounded-lg"
              >
                <div className="flex justify-center w-5">
                  <svg
                    className="icons"
                    width="16"
                    height="5"
                    viewBox="0 0 16 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5" clipPath="url(#clip0_59897_42726)">
                      <path
                        d="M12.3685 2.5166C12.3685 1.61514 13.0985 0.885144 14 0.885144C14.9015 0.885144 15.6315 1.61514 15.6315 2.5166C15.6315 3.41694 14.9016 4.14706 14 4.14706C13.0984 4.14706 12.3685 3.41694 12.3685 2.5166ZM6.36854 2.5166C6.36854 1.61514 7.09854 0.885144 8 0.885144C8.90146 0.885144 9.63146 1.61514 9.63146 2.5166C9.63146 3.41694 8.90158 4.14706 8 4.14706C7.09842 4.14706 6.36854 3.41694 6.36854 2.5166ZM0.368542 2.5166C0.368542 1.61526 1.09942 0.885144 2 0.885144C2.90146 0.885144 3.63146 1.61514 3.63146 2.5166C3.63146 3.41682 2.9007 4.14706 2 4.14706C1.09918 4.14706 0.368542 3.4177 0.368542 2.5166Z"
                        stroke="#343946"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_59897_42726">
                        <rect
                          width="16"
                          height="4"
                          fill="white"
                          transform="translate(0 0.516602)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <span
                  className={`menu-label pl-4 pr-10 ${isExpanded || window.innerWidth < 1024 ? "block" : "hidden"}`}
                >
                  <p className="small font-semibold text-hover-active">More</p>
                </span>
                <div className="hidden">
                  <div className="px-8 py-2">
                    <h6>About ticet edu</h6>
                  </div>

                  <div className="px-8 py-2">
                    <a href="https://www.google.com/">
                      <p className="ease-in-out transition duration-300 w-full py-1 font-semibold hover:text-primary-main-700 dark:hover:text-primary-main-700">
                        Official Website
                      </p>
                    </a>

                    <a href="https://www.google.com/">
                      <p className="ease-in-out transition duration-300 w-full py-1 font-semibold hover:text-primary-main-700 dark:hover:text-primary-main-700">
                        Data Protection Policy
                      </p>
                    </a>

                    <a href="https://www.google.com/">
                      <p className="ease-in-out transition duration-300 w-full py-1 font-semibold hover:text-primary-main-700 dark:hover:text-primary-main-700">
                        Terms of Use Policy
                      </p>
                    </a>

                    <a href="https://www.google.com/">
                      <p className="ease-in-out transition duration-300 w-full py-1 font-semibold hover:text-primary-main-700 dark:hover:text-primary-main-700">
                        Privacy Policy
                      </p>
                    </a>

                    <a href="https://www.google.com/">
                      <p className="ease-in-out transition duration-300 w-full py-1 font-semibold hover:text-primary-main-700 dark:hover:text-primary-main-700">
                        FAQs
                      </p>
                    </a>

                    <a href="https://www.google.com/">
                      <p className="ease-in-out transition duration-300 w-full py-1 font-semibold hover:text-primary-main-700 dark:hover:text-primary-main-700">
                        Careers
                      </p>
                    </a>

                    <a href="https://www.google.com/">
                      <p className="ease-in-out transition duration-500 w-full py-1 font-semibold hover:text-primary-main-700 dark:hover:text-primary-main-700">
                        Contact
                      </p>
                    </a>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </SidebarDiv>
  );
};

export default Sidebar;

import {
  SET_STUDENT_PERSONALINFO_FAILURE,
  SET_STUDENT_PERSONALINFO_SUCCESS
} from './constants'

export const setStudentPersonalInfo = payload => ({
  type: SET_STUDENT_PERSONALINFO_SUCCESS,
  payload
});

export const setStudentPersonalInfoFailure = error => ({
  type: SET_STUDENT_PERSONALINFO_FAILURE,
  error
});
import {
  SET_ACCOUNT_TYPE_FAILURE,
  SET_ACCOUNT_TYPE_SUCCESS
} from './constants'

export const setAccountType = payload => ({
  type: SET_ACCOUNT_TYPE_SUCCESS,
  payload
});

export const setAccountTypeFailure = error => ({
  type: SET_ACCOUNT_TYPE_FAILURE,
  error
});
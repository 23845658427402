import {
  CREATE_STUDENT_FAILURE,
  CREATE_STUDENT_LOADING,
  CREATE_STUDENT_SUCCESS
} from './constants'

const initState = {
  loading: false,
  message: [],
  error: null
}

export default function createStudent(state=initState, action) {
  const { type, message, error } = action

  switch(type) {
    case CREATE_STUDENT_LOADING:
      return { ...state, loading: true }

    case CREATE_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        message
      }
    
    case CREATE_STUDENT_FAILURE:
      return {
        ...state,
        loading: false,
        error
      }
    
    default:
      return state;
  }
}
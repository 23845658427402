import {
  GET_GRADES_LOADING,
  GET_GRADES_SUCCESS,
  GET_GRADES_FAILURE
} from './constants';

const initState = {
  loading: false,
  payload: [],
  error: null
}

export default function getGrades(state=initState, action) {
  const {type, payload, error} = action;

  switch(type) {
    case GET_GRADES_LOADING:
      return { ...state, loading: true };
    case GET_GRADES_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      };
    case GET_GRADES_FAILURE:
      return {
        ...state,
        loading: false,
        error
      };
    default:
      return state;
  }
}
import React from "react";

const AccountSettingsHeader = () => {
  return (
    <div className="mt-16 lg:mt-20 ml-0">
      <div
        className={`pt-8 flex h-full px-4 md:px-6 lg:px-8 text-center bg-primary-main-700 justify-center bg-cover bg-center animate-pulse`}
        style={{
          backgroundImage: `url("/images/header-bg-settings.png")`,
          backgroundRepeat: "repeat-x",
          animation: "slide 150s linear infinite",
        }}
      >
        <div className="flex flex-col items-center max-w-8xl">
          <div className="flex flex-col items-center gap-4 max-w-5xl">
            <title className="text-app-colors-heading-d dark:text-app-colors-heading-d mb-2 font-medium">
              Your Account Settings
            </title>
            <p className=" text-app-colors-heading-d dark:text-app-colors-heading-d mb-2">
              Welcome to your account dashboard! Here you’ll find everything you
              need to change your profile information, settings, read
              notifications and requests, view your latest messages, change your
              password, change your library settings, view your badges, update
              your payment information and much more!
            </p>
          </div>
          <img
            src="/images/account-bottom.webp"
            alt=""
            className="flex w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default AccountSettingsHeader;

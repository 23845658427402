import React, { useState } from "react";

const BorderedTextInput = ({
  borderText,
  id,
  name,
  type,
  value = undefined,
  handleInputChange,
  disabled = false,
  bgColor = "dark:bg-app-colors-surface-d bg-app-colors-surface",
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <div>
      <span className="block">
        <div
          className={`relative borders flex flex-col sm:h-10 lg:h-12 text-app-colors-p-small dark:text-app-colors-p-small-d focus-within:border-primary-main-700 focus:text-primary-main-700 rounded ${borderText.includes("*") ? "border-secondary-red-500 focus-within:border-secondary-red-500" : ""}`}
        >
          <div className="relative">
            <span
              className={`absolute inline-flex ml-2 ${bgColor} py-0 px-1 -top-3 text-xs md:text-sm ${focused ? "text-primary-main-700" : ""} ${borderText.includes("*") ? "text-secondary-red-500" : ""}`}
            >
              {borderText}
            </span>
          </div>

          <div className="flex flex-grow pt-3">
            <input
              id={id}
              name={name}
              type={type}
              className="pl-3 text-base font-medium text-app-colors-p-normal dark:text-app-colors-p-normal-d bg-transparent border-0 px-1 pt-0 focus:ring-0 w-full rounded line-clamp-1"
              onChange={handleInputChange}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              defaultValue={value}
              disabled={disabled}
            />
          </div>
        </div>
      </span>
    </div>
  );
};

export default BorderedTextInput;

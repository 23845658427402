import React from "react";
import { useSelector } from "react-redux";
import { getImageData } from "../../utils/common";

const UserSpaceHeader = ({
  page,
  name,
  username,
  location,
  timelineRoute,
  membersRoute,
  libraryRoute,
  eventsRoute,
}) => {
  const uploadInfo = useSelector((state) => state.uploadImage);
  const profileImageData = getImageData(uploadInfo.payload, "profile");

  return (
    <div className="bg-white">
      <div className="bg-i-purple-300">
        <div
          className="h-72 md:h-80 lg:h-96 mt-20"
          style={{ background: `url("/images/library_bg.png")` }}
        ></div>
      </div>
      <div>
        <div className="absolute">
          <div className="h-32 w-32 md:h-40 md:w-40 rounded-full bg-white -bottom-4 md:-bottom-10 left-7 absolute p-0.5">
            <img
              src={
                profileImageData
                  ? profileImageData["assetUrl"]
                  : "/images/test-avatar.png"
              }
              alt="profile"
              className="mr-2 rounded-full"
            />
          </div>
        </div>
        <div className="absolute">
          <div className="absolute bottom-5 left-44 md:left-56 text-white w-44 md:w-96">
            <div className="font-bold">{name}</div>
            <div className="text-xs">{`@${username} | ${location}`}</div>
          </div>
        </div>
      </div>
      <div className="mt-8 flex">
        <div className="mx-auto flex text-sm text-gray-500 mb-8">
          <div
            className={`${page === "timeline" ? "font-bold" : "cursor-pointer"} mr-5 md:mr-16`}
            onClick={timelineRoute}
          >
            Timeline
          </div>
          <div
            className={`${page === "members" ? "font-bold" : "cursor-pointer"} mr-5 md:mr-16`}
            onClick={membersRoute}
          >
            Members
          </div>
          <div
            className={`${page === "library" ? "font-bold" : "cursor-pointer"} mr-5 md:mr-16`}
            onClick={libraryRoute}
          >
            Library
          </div>
          <div
            className={`${page === "events" ? "font-bold" : "cursor-pointer"}`}
            onClick={eventsRoute}
          >
            Events
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSpaceHeader;

import React, {useState} from 'react';
import BorderedTextInput from '../../../../components/common/borderedTextInput';
import BorderedTextArea from '../../../../components/common/borderedTextArea';
import InternationalPhoneField from '../../../../components/common/internationalPhoneField';
import SearchableDropDown from '../../../../components/common/searchableDropDown';
import {getCountiesAction} from '../../../../commonData/counties/action';
import {useDispatch, useSelector} from 'react-redux';
import BorderSelectInput from '../../../../components/common/borderSelectInput';

const ChangePassword = () => {

  const [showPassword, setShowPassword] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const dispatch = useDispatch();

  const countries = useSelector(state => state.getCountries.payload.data);
  const counties = useSelector(state => state.getCounties.payload.data);
  const [profileInfo, setProfileInfo] = useState({});
  const [formFilled, setFormFilled] = useState(true);

  const countriesOptions = [];
  const countiesOptions = [];

  countries && countries.map(country => (
      countriesOptions.push({ label: country.name, value: country.id })
  ));

  counties && counties.map(county => (
      countiesOptions.push({ label: county.name, value: county.id })
  ));

  const handleChange = e => {
    setProfileInfo({ ...profileInfo, [e.target.name]: e.target.value });
  }

  const [switchToggle, setSwitchToggle] = useState(false);

  const handleToggle = () => {
    setSwitchToggle(!switchToggle);
  };

  return (
    <div className="surface rounded-md w-full">
      <div className="pt-12 pb-6 lg:pt-14 px-6 border-b-2 border-app-colors-border dark:border-app-colors-border-d">
        <h6>Change Password</h6>
      </div>
      <div className="px-6 py-8 flex flex-col gap-8">
        <div className={"grid sm:grid-cols-2 gap-6"}>
          <div>
            <div className="relative">
              <BorderedTextInput
                id={"currentPassword"}
                name={"currentPassword"}
                type={showPassword ? "text" : "password"}
                borderText={`Confirm Current Password ${!formFilled && !profileInfo.currentPassword ? "*" : ""}`}
                handleInputChange={handleChange}
                value={userInfo.password || ""}
              />
              <button
                id="show-hide"
                type="button"
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <svg
                    className="icons"
                    width="20"
                    height="20"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M35.2008 48H28.8008V54.4H35.2008V48Z" />
                    <path d="M32 9.59961C14.3264 9.59961 0 31.9996 0 31.9996C0 31.9996 9.4176 46.7196 22.4 52.2908V45.1388C16.4416 41.798 11.152 36.1948 7.8656 31.9932C12.8352 25.6092 22.384 15.9996 32 15.9996C41.5616 15.9996 51.1456 25.6284 56.1344 32.0092C52.8544 36.2204 47.5744 41.8236 41.6 45.158V52.2908C54.5824 46.7196 64 31.9996 64 31.9996C64 31.9996 49.6704 9.59961 32 9.59961Z" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M32.0004 22.4004C26.698 22.4004 22.4004 26.698 22.4004 32.0004C22.4004 37.3028 26.698 41.6004 32.0004 41.6004C37.3028 41.6004 41.6004 37.3028 41.6004 32.0004C41.6004 26.698 37.3028 22.4004 32.0004 22.4004ZM35.2004 35.2004H28.8004V28.8004H35.2004V35.2004Z"
                    />
                  </svg>
                ) : (
                  <svg
                    className="icons"
                    width="20"
                    height="20"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_61712_221726)">
                      <path d="M35.2008 48H28.8008V54.4H35.2008V48Z" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M35.1996 0H41.5996L15.9996 64H9.59961L35.1996 0Z"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M33.334 41.4658C37.9996 40.813 41.5996 36.8482 41.5996 32.0002C41.5996 29.7474 40.7932 27.7026 39.494 26.0674L33.334 41.4658Z"
                      />
                      <path d="M22.4 18.8418V11.709C9.4176 17.2802 0 32.0002 0 32.0002C0 32.0002 3.6768 37.7442 9.6 43.4114V34.0962C8.9792 33.3698 8.3872 32.6594 7.8656 31.9874C11.1456 27.7794 16.4256 22.173 22.4 18.8418Z" />
                      <path d="M41.5996 11.709V18.8642C47.558 22.205 52.8476 27.805 56.134 32.0098C52.854 36.221 47.574 41.8242 41.5996 45.1586V52.2914C54.582 46.7202 63.9996 32.0002 63.9996 32.0002C63.9996 32.0002 54.582 17.2834 41.5996 11.709Z" />
                    </g>
                    <defs>
                      <clipPath id="clip0_61712_221726">
                        <rect width="64" height="64" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div />
        </div>
        <div className={"flex flex-col sm:flex-row gap-6"}>
          <div className="relative w-full">
            <BorderedTextInput
              id={"newPassword"}
              name={"newPassword"}
              type={showPassword ? "text" : "password"}
              borderText={`Enter New Password ${!formFilled && !profileInfo.newPassword ? "*" : ""}`}
              handleInputChange={handleChange}
            />
            <button
              id="show-hide"
              type="button"
              className="absolute right-3 top-1/2 transform -translate-y-1/2"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <svg
                  className="icons"
                  width="20"
                  height="20"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M35.2008 48H28.8008V54.4H35.2008V48Z" />
                  <path d="M32 9.59961C14.3264 9.59961 0 31.9996 0 31.9996C0 31.9996 9.4176 46.7196 22.4 52.2908V45.1388C16.4416 41.798 11.152 36.1948 7.8656 31.9932C12.8352 25.6092 22.384 15.9996 32 15.9996C41.5616 15.9996 51.1456 25.6284 56.1344 32.0092C52.8544 36.2204 47.5744 41.8236 41.6 45.158V52.2908C54.5824 46.7196 64 31.9996 64 31.9996C64 31.9996 49.6704 9.59961 32 9.59961Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32.0004 22.4004C26.698 22.4004 22.4004 26.698 22.4004 32.0004C22.4004 37.3028 26.698 41.6004 32.0004 41.6004C37.3028 41.6004 41.6004 37.3028 41.6004 32.0004C41.6004 26.698 37.3028 22.4004 32.0004 22.4004ZM35.2004 35.2004H28.8004V28.8004H35.2004V35.2004Z"
                  />
                </svg>
              ) : (
                <svg
                  className="icons"
                  width="20"
                  height="20"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_61712_221726)">
                    <path d="M35.2008 48H28.8008V54.4H35.2008V48Z" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M35.1996 0H41.5996L15.9996 64H9.59961L35.1996 0Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M33.334 41.4658C37.9996 40.813 41.5996 36.8482 41.5996 32.0002C41.5996 29.7474 40.7932 27.7026 39.494 26.0674L33.334 41.4658Z"
                    />
                    <path d="M22.4 18.8418V11.709C9.4176 17.2802 0 32.0002 0 32.0002C0 32.0002 3.6768 37.7442 9.6 43.4114V34.0962C8.9792 33.3698 8.3872 32.6594 7.8656 31.9874C11.1456 27.7794 16.4256 22.173 22.4 18.8418Z" />
                    <path d="M41.5996 11.709V18.8642C47.558 22.205 52.8476 27.805 56.134 32.0098C52.854 36.221 47.574 41.8242 41.5996 45.1586V52.2914C54.582 46.7202 63.9996 32.0002 63.9996 32.0002C63.9996 32.0002 54.582 17.2834 41.5996 11.709Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_61712_221726">
                      <rect width="64" height="64" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </button>
          </div>

          <div className="relative w-full">
            <BorderedTextInput
              id={"confirmNewPassword"}
              name={"confirmNewPassword"}
              type={showPassword ? "text" : "password"}
              borderText={`Confirm New Password ${!formFilled && !profileInfo.confirmNewPassword ? "*" : ""}`}
              handleInputChange={handleChange}
            />
            <button
              id="show-hide"
              type="button"
              className="absolute right-3 top-1/2 transform -translate-y-1/2"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <svg
                  className="icons"
                  width="20"
                  height="20"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M35.2008 48H28.8008V54.4H35.2008V48Z" />
                  <path d="M32 9.59961C14.3264 9.59961 0 31.9996 0 31.9996C0 31.9996 9.4176 46.7196 22.4 52.2908V45.1388C16.4416 41.798 11.152 36.1948 7.8656 31.9932C12.8352 25.6092 22.384 15.9996 32 15.9996C41.5616 15.9996 51.1456 25.6284 56.1344 32.0092C52.8544 36.2204 47.5744 41.8236 41.6 45.158V52.2908C54.5824 46.7196 64 31.9996 64 31.9996C64 31.9996 49.6704 9.59961 32 9.59961Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32.0004 22.4004C26.698 22.4004 22.4004 26.698 22.4004 32.0004C22.4004 37.3028 26.698 41.6004 32.0004 41.6004C37.3028 41.6004 41.6004 37.3028 41.6004 32.0004C41.6004 26.698 37.3028 22.4004 32.0004 22.4004ZM35.2004 35.2004H28.8004V28.8004H35.2004V35.2004Z"
                  />
                </svg>
              ) : (
                <svg
                  className="icons"
                  width="20"
                  height="20"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_61712_221726)">
                    <path d="M35.2008 48H28.8008V54.4H35.2008V48Z" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M35.1996 0H41.5996L15.9996 64H9.59961L35.1996 0Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M33.334 41.4658C37.9996 40.813 41.5996 36.8482 41.5996 32.0002C41.5996 29.7474 40.7932 27.7026 39.494 26.0674L33.334 41.4658Z"
                    />
                    <path d="M22.4 18.8418V11.709C9.4176 17.2802 0 32.0002 0 32.0002C0 32.0002 3.6768 37.7442 9.6 43.4114V34.0962C8.9792 33.3698 8.3872 32.6594 7.8656 31.9874C11.1456 27.7794 16.4256 22.173 22.4 18.8418Z" />
                    <path d="M41.5996 11.709V18.8642C47.558 22.205 52.8476 27.805 56.134 32.0098C52.854 36.221 47.574 41.8242 41.5996 45.1586V52.2914C54.582 46.7202 63.9996 32.0002 63.9996 32.0002C63.9996 32.0002 54.582 17.2834 41.5996 11.709Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_61712_221726">
                      <rect width="64" height="64" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </button>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex">
            <button
              className={`relative inline-block w-12 h-6 rounded-full transition-colors duration-300 px-2 ${switchToggle ? "bg-i-purple-600" : "bg-gray-300"}`}
              onClick={handleToggle}
            >
              <span
                className={`absolute inset-y-0 left-0 inline-block w-6 h-6 bg-white rounded-full shadow-lg transform transition-transform duration-300 ${switchToggle ? "translate-x-full" : ""}`}
              />
            </button>
            <div className="text-gray-500 dark:text-gray-300 cursor-pointer ml-3 mt-1">
              Remember me on this device
            </div>
          </div>
          <div className="text-xs text-gray-700 flex justify-end px-2">
            <div className="text-secondary-blue-500 hover:text-secondary-blue-600 dark:text-secondary-blue-500 dark:hover:text-secondary-blue-600 cursor-pointer">
              Forgot my password
            </div>
          </div>
        </div>
        <div className={"w-full"}>
          <button className="account-settings-button rounded-button green-button w-full">
            <span className="">
              <svg
                width="15"
                height="13"
                viewBox="0 0 75 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_52244_11754)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.4849 46.8678L7.63689 32.0251L0.212891 39.4491L22.4849 61.7211L59.5996 24.6064L52.1756 17.1771L22.4849 46.8678ZM67.0236 2.3291L59.5996 9.7531L67.0236 17.1771L74.4476 9.7531L67.0236 2.3291Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_52244_11754">
                    <rect
                      width="74.6667"
                      height="64"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span>Change Password</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
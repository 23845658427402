import {
  CREATE_MISSING_SCHOOL_FAILURE,
  CREATE_MISSING_SCHOOL_SUCCESS,
  CREATE_MISSING_SCHOOL_LOADING
} from './constants';
import axios from '../../utils/api/axios'
import {CLIENT_API_URL} from '../../utils/constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const createMissingSchoolLoading = () => {
  return {
    type: CREATE_MISSING_SCHOOL_LOADING
  }
}

export const createMissingSchoolSuccess = result => {
  return {
    type: CREATE_MISSING_SCHOOL_SUCCESS,
    result
  }
}

export const createMissingSchoolFailure = error => {
  return {
    type: CREATE_MISSING_SCHOOL_FAILURE,
    error
  }
}

export const createMissingSchoolAction = ({ countyId, name }) => async dispatch => {
  const schoolDetails = { countyId, name }
  console.log('School create hit---')
  dispatch(createMissingSchoolLoading())

  try {
    await axios.post(`${CLIENT_API_URL}/institutions`,
        JSON.stringify(schoolDetails),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await getJwtTokenCookie()}`
          },
        }).then(resp => {
      console.log('School created Successfully!', resp.data);
      dispatch(createMissingSchoolSuccess(resp.data));
    }).catch(() => {
      dispatch(createMissingSchoolFailure({
        message: 'An error occurred while creating school'
      }))
    })
  } catch (err) {
    if(!err?.response) {
      dispatch(createMissingSchoolFailure({
        message: 'No server response'
      }))
    } else {
      dispatch(createMissingSchoolFailure({
        message: 'An error occurred while creating school'
      }))
    }
  }
}
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

import NavBar from '../../../../components/navbars/signedOutNavbar';
import Footer from '../../../../components/footer';
import ROUTES from '../../../../utils/routes';
import { getSchoolsAction } from '../../../../commonData/schools/action';
import { getCountriesAction } from '../../../../commonData/countries/action';
import { getCountiesAction } from '../../../../commonData/counties/action';
import { getCurriculaAction } from '../../../../commonData/curricula/action';
import { getCategoriesAction } from '../../../../commonData/categories/action';
import { setParentPersonalInfo } from '../personalInfo/action';
import SearchableDropDown from '../../../../components/common/searchableDropDown';
import InformationAccordion from '../../../../components/common/informationAccordion';
import {backgroundImage, removeItemFromArray} from '../../../../utils/common';

export default function ParentSelectSchool() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const countries = useSelector(state => state.getCountries.payload.data);
  const counties = useSelector(state => state.getCounties.payload.data);
  const schools = useSelector(state => state.getSchools.payload.content);
  const personalInfo = useSelector(state => state.setParentPersonalInfo.payload);
  const curricula = useSelector(state => state.getCurricula.payload);
  const categories = useSelector(state => state.getCategories.payload);

  const [formFilled, setFormFilled] = useState(true);
  let [schoolList, setSchoolList] = useState([]);

  const countriesOptions = [];
  const countiesOptions = [];
  const schoolsOptions = [];
  const curriculaOptions = [];
  const categoriesOptions = [];

  countries && countries.map(country => (
    countriesOptions.push({ label: country.name, value: country.id })
  ));

  counties && counties.map(county => (
    countiesOptions.push({ label: county.countyName, value: county.id })
  ));

  schools && schools.map(school => (
    schoolsOptions.push({ label: school.name, value: school })
  ));

  curricula['data'] && curricula['data'].map(curriculum => (
      curriculaOptions.push({label: curriculum.name, value: curriculum.id})
  ))

  categories['data'] && categories['data'].map(category => (
      categoriesOptions.push({label: category.name, value: category.id})
  ))

  const nextRouteChange = e => {
    e.preventDefault()
    if (schoolList.length > 0) {
      setFormFilled(true)
    } else {
      return setFormFilled(false)
    }
    dispatch(setParentPersonalInfo({ ...personalInfo, 'schoolIds': schoolList }))
    let path = ROUTES.parentSignUpAuthentication;
    navigate(path);
  }

  const backRouteChange = () => {
    let path = ROUTES.parentSignUpPersonalInfo;
    navigate(path);
  }

  const handleCountryChange = code => {
    dispatch(getCountiesAction(code));
    dispatch(getCurriculaAction(code));
  }

  const handleCountyChange = countyId => {
    dispatch(getSchoolsAction(countyId));
  }

  const handleSchoolChange = schoolId => {
    setSchoolList(schoolList => [...schoolList, schoolId])
  }

  const handleCurriculumChange = curriculumId => {
    dispatch(getCategoriesAction(curriculumId))
  }

const removeSchoolFromList = index => {
  const filteredSchools = removeItemFromArray(schoolList, (index + 1));
  setSchoolList(filteredSchools);
}

  useEffect(() => {
    dispatch(getCountriesAction());
  }, [dispatch])

  return (
    <div className="bg-i-purple-500 bg-no-repeat bg-cover bg-center flex flex-col w-full h-full min-h-screen bg-blend-overlay"
         style={{backgroundImage: `url(${backgroundImage})`}}>
      <NavBar />
      <div className="flex flex-col-reverse lg:flex-row lg:space-x-2">
        <div className="lg:mt-64">
          <div>
            <InformationAccordion
              subTittle={'SOCIAL MEDIA LEARNING PLATFORM'}
              title1={'Collaborate'}
              title2={'Network'}
              title3={'Engage'}
              content1={<Fragment>
                <p>
                  eLIMU INFOBRIX incorporates social, collaboration, and education technologies into one platform
                  tailored for students, teachers, and school administrators in Kenya.
                </p>
                <p>
                  Access unlimited learning/teaching resources, share your thoughts, write blogs, earn badges,
                  and so much more.
                </p>
              </Fragment>}
              content2={<Fragment>
                <p>
                  eLIMU INFOBRIX incorporates social, collaboration, and education technologies into one platform
                  tailored for students, teachers, and school administrators in Kenya.
                </p>
                <p>
                  Access unlimited learning/teaching resources, share your thoughts, write blogs, earn badges,
                  and so much more.
                </p>
              </Fragment>}
              content3={<Fragment>
                <p>
                  eLIMU INFOBRIX incorporates social, collaboration, and education technologies into one platform
                  tailored for students, teachers, and school administrators in Kenya.
                </p>
                <p>
                  Access unlimited learning/teaching resources, share your thoughts, write blogs, earn badges,
                  and so much more.
                </p>
              </Fragment>}
            />
          </div>
          <div></div>
        </div>
        <div className="w-full">
          <div className="w-80 sm:w-96 md:w-128 ml-auto mr-auto lg:mr-14 mt-8 sm:mt-10">
            {!formFilled ? <div className="text-xs text-white text-center bg-ired mb-2 py-1">Please fill in the missing fields</div> : null}
            <div className="px-4 py-5 bg-i-purple-50 rounded-md">
              <h3 className="uppercase text-xl text-center">Select Your Child's School</h3>
              <p className="text-xs text-center">Select multiple if you have children in multiple schools</p>
              <div className="mt-4">
                <form action="">
                  <div className="mt-3">
                    <SearchableDropDown
                      id="country"
                      name="country"
                      options={countriesOptions.length > 0 ? countriesOptions : []}
                      handleChange={handleCountryChange}
                      borderText={`School Country ${(!formFilled) ? '*' : ''}`}
                    />
                  </div>
                  <div className="mt-3">
                    <SearchableDropDown
                      id="curriculum"
                      name="curriculum"
                      options={curriculaOptions.length >= 1 ? curriculaOptions : []}
                      handleChange={handleCurriculumChange}
                      borderText={`Curriculum ${(!formFilled) ? '*' : ''}`}
                    />
                  </div>
                  <div className="mt-3">
                    <SearchableDropDown
                      id="category"
                      name="category"
                      options={categoriesOptions.length >= 1 ? categoriesOptions : []}
                      handleChange={handleCurriculumChange}
                      borderText={`Level ${(!formFilled) ? '*' : ''}`}
                    />
                  </div>
                  <div className={"mt-3"}>
                    <SearchableDropDown
                      id="county"
                      name="county"
                      options={countiesOptions.length > 0 ? countiesOptions : []}
                      handleChange={handleCountyChange}
                      borderText={`County ${(!formFilled) ? '*' : ''}`}
                    />
                  </div>
                  <div className={"mt-3"}>
                    <SearchableDropDown
                      id="schoolId"
                      name="schoolId"
                      options={schoolsOptions.length > 0 ? schoolsOptions : []}
                      handleChange={handleSchoolChange}
                      borderText={`School ${(!formFilled) ? '*' : ''}`}
                    />
                  </div>
                  <div className="text-xs w-full mt-1 italic ml-2 text-gray-700">Select 'Other' if you can't find your school</div>
                </form>
                <form action="" className="mt-5">
                  <div>
                    <span className="line block m-1">
                      <div className="text-gray-400 border border-gray-400 focus-within:border-i-purple-500 focus:text-i-purple-500 rounded relative">
                        <span className="ml-2 bg-i-purple-50 relative py-0 px-1 bottom-4 text-xs">
                          Selected School(s)
                        </span>
                        <ol className={`${schoolList.length > 0 ? 'list-decimal pl-5' : ''} text-xs bg-transparent p-2`}>
                          {schoolList.length > 0 ? (
                              schoolList.map((singleSchool, index) => (
                                  <div key={index+1} className="flex mt-1">
                                    <li className="text-black">{singleSchool.name}</li>
                                    <div onClick={() => removeSchoolFromList(index)} className="bg-i-orange-500 mr-2 text-white py-1 px-3 rounded-full cursor-pointer ml-auto"><span className="sm:hidden">-</span><span className="hidden sm:flex">- Remove</span></div>
                                  </div>
                              ))
                          ) : (
                              <li className="text-black">Select school to appear here</li>
                          )}
                        </ol>
                      </div>
                    </span>
                  </div>

                  <div className="mt-3 flex mx-4">
                    <div className="mr-auto">
                      <button className="bg-i-purple-50 px-9 py-2 text-xs text-i-purple-500 ml-auto rounded-full border border-gray-500" onClick={backRouteChange}>Back</button>
                    </div>
                    <div className="ml-auto">
                      <button className="bg-i-purple-500 px-9 py-2 text-xs text-white ml-auto rounded-full"
                        onClick={e => nextRouteChange(e)}
                      >Next</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="mt-3 grid sm:grid-cols-2 sm:gap-2">
              <div className="mx-auto"></div>
              <div className="text-right">
                <button className="bg-i-purple-50 px-9 py-2 text-xs ml-auto rounded-full" onClick={nextRouteChange}>Log Out</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto flex-end">
        <Footer />
      </div>
    </div>
  )
}

import {
  GET_USER_LOADING,
  GET_USER_FAILURE,
  GET_USER_SUCCESS
} from './constants';

const initialState = {
  loading: false,
  payload: {},
  error: null
};

export default function getUserDetails(state = initialState, action) {
  const { type, payload, error } = action;

  switch(type) {
    case GET_USER_LOADING:
      return { ...state, loading: true };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error
      };
    default:
      return state;
  }
}
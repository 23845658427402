import React from "react";
import SignedInNavBar from "../../../components/navbars/signedInNavbar";
import VideoItem from "./videoItem";
import VideosHeader from '../../../components/common/videosHeader';
import {useNavigate} from 'react-router-dom';

const Videos = () => {

  const navigate = useNavigate();

  const videoItems = [
    {
      id: 1,
      title: "What is ticet edu?",
      videoLink: "https://www.youtube.com/watch?v=fS1TfANV0WA",
      duration: "5:00",
      views: "1.7M",
      dateUploaded: "5/02/2001",
    },
    {
      id: 2,
      title: "Ep.1 Introduction to My Workspace",
      videoLink: "https://www.youtube.com/watch?v=DGaz8UkLFQo",
      duration: "17:00",
      views: "12.4K",
      dateUploaded: "9/10/2021",
    },
    {
      id: 3,
      title: "Ep.2 Introduction to My Workspace?",
      videoLink: "https://vimeo.com/292574354",
      duration: "10:00",
      views: "2.8K",
      dateUploaded: "3/11/2024",
    },
    {
      id: 4,
      title: "Introduction to community library",
      videoLink: "https://www.youtube.com/watch?v=vjTH7ZN9DIU",
      duration: "10:00",
      views: "4.7K",
      dateUploaded: "7/02/2023",
    },
    {
      id: 4,
      title: "How do I create a worksheet?",
      videoLink: "https://vimeo.com/143772346",
      duration: "10:00",
      views: "12.4K",
      dateUploaded: "13/12/2023",
    },
  ];

  const handleVideoItemClick = videoId => {
    let path = `/community/videos/${videoId}`;
    navigate(path);
  }

  return (
    <div className="body flex flex-col min-h-screen">
      <SignedInNavBar label={"videos"} title={"Videos"} />
      <div className="mt-0 mx-0 lg:ml-20 min-h-screen">
        <VideosHeader />
        <div className="max-w-8xl mx-4 md:mx-8 lg:mx-20 pt-2 md:pt-4 lg:pt-6 grid auto-rows-max grid-cols-auto-fill-180 gap-6 py-8">
          {videoItems.length > 0 ? (
            videoItems.map((videoItem) => (
              <VideoItem
                title={videoItem.title}
                videoLink={videoItem.videoLink}
                key={videoItem.id}
                handleVideoItemClick={() => handleVideoItemClick(videoItem.id)}
                duration={videoItem.duration}
                dateUploaded={videoItem.dateUploaded}
                views={videoItem.views}
              />
            ))
          ) : (
            <div className="small">There are no videos</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Videos;

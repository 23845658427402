import React from 'react'

const ConnectionListItem = ({fullName, username, noOfFollowers}) => {
  return (
      <div className="bg-white p-4 flex justify-between mb-3 rounded">
        <div className="flex">
          <img src="/images/test-avatar.png" alt="avatar" width="45px" className="mr-2" />
          <div>
            <div className="w-full">{fullName}</div>
            <div className="flex gap-2">
              <div className="text-xs italic mt-0">{`@${username}`}</div>
              <div className="text-xs italic mt-0 flex items-center gap-1">
                <span>
                  <img src="/images/test-avatar.png" alt="flag" width="10px" />
                </span>
                <div>TEACHER</div>
              </div>
            </div>
            
          </div>
        </div>

        <div className="text-right">
          <button className="text-white text-xs bg-i-yellow-500 rounded px-4 py-2 mt-2 flex gap-2 items-center">
              <span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M15.008 1.35201V3.76002C16.836 5.22603 18.008 7.47404 18.008 9.99906C18.008 13.3551 15.94 16.2241 13.008 17.4121V19.5231C17.061 18.2481 20 14.4651 20 9.99206C20 6.29904 17.99 3.08202 15.008 1.35201ZM2.00789 10.0001C2.00789 7.47504 3.1809 5.22703 5.00791 3.76002V1.34101C2.01789 3.06902 -0.00012207 6.29204 -0.00012207 9.99206C-0.00012207 14.4711 2.9469 18.2601 7.00792 19.5311V17.4121C4.0779 16.2251 2.00789 13.3551 2.00789 10.0001ZM5.00791 9.99906H7.00792V8.00005H5.00791V9.99906ZM13.008 9.99906H15.008V8.00005H13.008V9.99906ZM6.99892 12.1021C6.99892 13.1491 8.33993 13.9971 9.99494 13.9971C11.6489 13.9971 12.991 13.1491 12.991 12.1021V12.0021H6.99892V12.1021Z" fill="white"/>
                  <path d="M13.008 4.24267L11.5937 5.65689L10.1795 4.24267L8.76529 5.65689L7.35107 4.24267L8.76529 2.82844L7.35107 1.41422L8.76529 0L10.1795 1.41422L11.5937 0L13.008 1.41422L11.5937 2.82844L13.008 4.24267Z" fill="white"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M9.00012 18H11.0001V20H9.00012V18Z" fill="white"/>
                </svg>  
              </span>
            <div className="">Cancel Request</div>
          </button>
        </div>
      </div>
  )
}

export default ConnectionListItem;
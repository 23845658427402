import {getJwtTokenCookie, getRefreshTokenCookie, setJwtTokenCookie} from '../cookieHelper';
import axios from '../api/axios';
import {CLIENT_API_URL} from '../constants';

export const authVerify = async () => {
  let token = 'missing token';
  await checkToken(1000, async function (result) {
    if (!result || result === 'null') {
      token = 'missing token';
    } else {
      token = result;
      let decodedToken = parseJwt(token);
      if (!decodedToken?.exp) {
        await setJwtTokenCookie(null);
        token = 'missing token'; // log out user
      }
      if (decodedToken?.exp * 1000 > Date.now()) {
        console.log('Token found...')
        return token;
      } else {
        //refresh token
        await axios.post(`${CLIENT_API_URL}/users/auth/token`,
          JSON.stringify({
            refreshToken: await getRefreshTokenCookie(),
          }),
          {
            headers: {'Content-Type': 'application/json'},
          }).then(async resp => {
            await setJwtTokenCookie(resp.data.data.access_token);
            token = resp.data.data.access_token;
            console.log('Token refreshed...')
        }).catch(async () => {
          await setJwtTokenCookie(null);
          token = 'missing token';
          console.log('Token refresh failed...')
        })
      }
    }
  })
  return token;
}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

async function checkToken(maxWaitTime, callback) {
  let token = await getJwtTokenCookie();
  if (token !== undefined && token !== null) {
    callback(token);
    return;
  }
  if (maxWaitTime <= 0) {
    callback(null);
    return;
  }
  setTimeout(() => {
    checkToken(token, maxWaitTime - 100, callback);
  }, 100);
}
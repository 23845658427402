import React from "react";

const LibrariesCard = ({
  fullName,
  username,
  role,
  noOfFollowers,
  buttonLabel,
}) => {
  const background = (label) => {
    if (label === "Request Access") {
      return "bg-i-blue-500";
    } else if (label === "Unfollow Library") {
      return "bg-ired";
    } else if (label === "Cancel Request") {
      return "bg-i-yellow-500";
    } else {
      return "bg-i-green-500";
    }
  };
  return (
    <div className="min-w-72">
      <div className="bg-white h-full rounded">
        <div
          className="py-4 bg-no-repeat bg-cover bg-center h-1/3 w-full rounded-t"
          style={{ backgroundImage: "url(/images/card-bg.jpeg)" }}
        ></div>
        <div className="flex flex-col items-center pb-24 px-6 -mt-12">
          <img
            className="mb-3 w-24 h-24 rounded-full border-4  border-white"
            src="/images/test-avatar.png"
            alt="avatar"
          />
          <h3 className="text-lg font-medium text-gray-900 -mt-4">
            {fullName}
          </h3>
          <div className="text-xs text-gray-500 -mt-1">@{username}</div>
          <span className="text-xs text-gray-500 uppercase my-1">{role}</span>
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="25"
              viewBox="0 96 960 960"
              width="20"
              fill="#fab600"
            >
              <path d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="25"
              viewBox="0 96 960 960"
              width="20"
              fill="#fab600"
            >
              <path d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="25"
              viewBox="0 96 960 960"
              width="20"
              fill="#fab600"
            >
              <path d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="25"
              viewBox="0 96 960 960"
              width="20"
              fill="#fab600"
            >
              <path d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="25"
              viewBox="0 96 960 960"
              width="20"
              fill="#fab600"
            >
              <path d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z" />
            </svg>
          </div>
          <span className="text-xs text-gray-500">{`${noOfFollowers} Followers`}</span>
          <div className="mt-4 space-x-3 lg:mt-3">
            <button
              className={`inline-flex items-center py-2 px-4 text-sm font-medium text-center text-white ${background(buttonLabel)}  rounded-full`}
            >
              {buttonLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LibrariesCard;

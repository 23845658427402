import React, {useEffect} from 'react';
import {getImageData, isObjEmpty} from '../../../utils/common';
import {useDispatch, useSelector} from 'react-redux';
import {getUserDetailsAction} from '../../../commonData/userDetails/action';
import {setJwtTokenCookie, setRefreshTokenCookie} from '../../../utils/cookieHelper';
import ROUTES from '../../../utils/routes';
import {useNavigate} from 'react-router-dom';

export default function AccountSetUpNavBar() {
  const dispatch = useDispatch()
  const uploadInfo = useSelector(state => state.uploadImage);
  const profileImageData = getImageData(uploadInfo.payload, 'profile')
  const userDetails = useSelector(state => state.getUserDetails.payload)

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserDetailsAction());
  }, [dispatch])

  const signOut = async () => {
    await setJwtTokenCookie(null);
    await setRefreshTokenCookie(null);
    let path = ROUTES.home
    navigate(path);
  }

  return (
    <nav className="bg-i-purple-500">
      <div className="mx-auto lg:pr-8 lg:pl-0">
        <div className="relative flex justify-between h-16">
          <div className="flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center bg-white h-16 mr-auto px-2">
              <img className="block md:hidden h-8 w-auto mr-1" src="/images/purple_small_logo.png" alt="Infobrix-logo"/>
              <img className="hidden md:block h-8 w-auto" src="/images/purple_logo.png" alt="Infobrix-logo"/>
            </div>
          </div>
          <div className="my-auto uppercase text-white mr-auto text-sm ml-4 sm:ml-6">
            Setup your account
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6">
            <div className="ml-3 relative">
              <div className="flex justify-center">
                <div className="flex space-x-4">
                  <button id="avatarDropdownButton" data-dropdown-toggle="avatarDropdown"
                          className="text-white bg-transparent font-medium focus:ring-0 focus:outline-none text-sm px-0 py-2.5 text-center inline-flex items-center"
                          type="button"> <img src={profileImageData ? profileImageData['assetUrl'] : "/images/test-avatar.png"} width="45px" alt="profile" className="mr-2 rounded-full"/>
                    <div className="hidden md:block">
                      <div>{`${isObjEmpty(userDetails) ? 'Jane' : userDetails.data.firstName} ${isObjEmpty(userDetails) ? 'Doe' : userDetails.data.lastName}`}</div>
                      <span className="text-xs italic mt-0">{`@${isObjEmpty(userDetails) ? 'janedoe' : userDetails.data.username}`}</span>
                    </div>
                    <svg className="hidden md:block ml-2 w-4 h-4" fill="none" stroke="currentColor"
                                                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M19 9l-7 7-7-7"></path>
                  </svg></button>

                  <div id="avatarDropdown"
                       className="hidden z-10 w-32 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
                    <ul className="py-1" aria-labelledby="avatarDropdownButton">
                      <li>
                        <div onClick={signOut}
                           className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign
                          out</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="hidden md:hidden">
        <div className="px-2 pt-2 pb-3 space-y-1">
          <a href="/" className="border-b-2 border-white text-white px-3 py-2 text-sm font-medium">Home</a>
          <a href="/product" className="text-gray-300 border-b-2 border-transparent hover:border-white hover:text-white px-3 py-2 text-sm font-medium">Product</a>
          <a href="/about" className="text-gray-300 border-b-2 border-transparent hover:border-white hover:text-white px-3 py-2 text-sm font-medium">About Us</a>
          <a href="/blog" className="text-gray-300 border-b-2 border-transparent hover:border-white hover:text-white px-3 py-2 text-sm font-medium">Blog</a>
        </div>
      </div>
    </nav>
  )
}

import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import NavBar from '../../../../components/navbars/signedOutNavbar';
import Footer from '../../../../components/footer';
import ROUTES from '../../../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { createParent } from '../newParent/action';
import {backgroundImage, getFullName, getImageData, getOptions} from '../../../../utils/common';
import BorderImageInput from '../../../../components/common/borderImageInput';
import InformationAccordion from '../../../../components/common/informationAccordion';
import BorderedTextInput from '../../../../components/common/borderedTextInput';
import UploadIcon from '@mui/icons-material/Upload';

export default function ParentAuthentication() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let personalInfo = useSelector(state => state.setParentPersonalInfo.payload);
  const userInfo = useSelector(state => state.setUserInfo.payload);
  const uploadInfo = useSelector(state => state.uploadImage);
  const nationalIdImageData = getImageData(uploadInfo.payload, 'nationalIdNumber')

  const backRouteChange = () => {
    let path = ROUTES.parentSignUpSelectSchool;
    navigate(path);
  }

  const registerParent = e => {
    e.preventDefault()
    const details = {
      ...personalInfo,
      countryIso2Code: personalInfo.countryIso2Code.iso2,
      schoolIds: getOptions(personalInfo.schoolIds, 'id'),
      nationalIdUrl: nationalIdImageData && 'assetUrl' in nationalIdImageData ? nationalIdImageData['assetUrl'] : null
    }
    !uploadInfo.loading && dispatch(createParent(details))
  }

  return (
    <div className="bg-i-purple-500 bg-no-repeat bg-cover bg-center flex flex-col w-full h-full min-h-screen bg-blend-overlay"
         style={{backgroundImage: `url(${backgroundImage})`}}>
      <NavBar />
      <div className="flex flex-col-reverse lg:flex-row lg:space-x-2">
        <div className="lg:mt-52">
          <div>
            <InformationAccordion
              subTittle={'SOCIAL MEDIA LEARNING PLATFORM'}
              title1={'Collaborate'}
              title2={'Network'}
              title3={'Engage'}
              content1={<Fragment>
                <p>
                  eLIMU INFOBRIX incorporates social, collaboration, and education technologies into one platform
                  tailored for students, teachers, and school administrators in Kenya.
                </p>
                <p>
                  Access unlimited learning/teaching resources, share your thoughts, write blogs, earn badges,
                  and so much more.
                </p>
              </Fragment>}
              content2={<Fragment>
                <p>
                  eLIMU INFOBRIX incorporates social, collaboration, and education technologies into one platform
                  tailored for students, teachers, and school administrators in Kenya.
                </p>
                <p>
                  Access unlimited learning/teaching resources, share your thoughts, write blogs, earn badges,
                  and so much more.
                </p>
              </Fragment>}
              content3={<Fragment>
                <p>
                  eLIMU INFOBRIX incorporates social, collaboration, and education technologies into one platform
                  tailored for students, teachers, and school administrators in Kenya.
                </p>
                <p>
                  Access unlimited learning/teaching resources, share your thoughts, write blogs, earn badges,
                  and so much more.
                </p>
              </Fragment>}
            />
          </div>
          <div></div>
        </div>
        <div className="w-full">
          <div className="w-80 sm:w-96 md:w-128 ml-auto mr-auto lg:mr-14 mt-16 sm:mt-32">
            <div className="px-4 py-5 bg-i-purple-50 rounded-md">
              <h3 className="uppercase text-xl text-center">Aunthentication</h3>
              <div className="mt-4">
                <form action="">
                  <div className="mt-3 grid sm:grid-cols-2">
                    <BorderedTextInput
                      type={'text'}
                      borderText={'Name'}
                      value={getFullName(userInfo.firstName, userInfo.lastName)}
                      disabled={true}
                    />
                    <BorderedTextInput
                      type={'text'}
                      borderText={'Username'}
                      value={userInfo.username}
                      disabled={true}
                    />
                  </div>
                  <div className="mt-3 grid sm:grid-cols-2">
                    <BorderedTextInput
                      type={'text'}
                      borderText={'Email Address'}
                      value={userInfo.email}
                      disabled={true}
                    />
                    <BorderedTextInput
                      type={'text'}
                      borderText={'Date of Birth'}
                      value={personalInfo.dateOfBirth && personalInfo.dateOfBirth}
                      disabled={true}
                    />
                  </div>
                  <div className="mt-3 grid sm:grid-cols-2">
                    <BorderedTextInput
                      type={'text'}
                      borderText={'Gender'}
                      value={personalInfo.gender && personalInfo.gender}
                      disabled={true}
                    />
                    <BorderedTextInput
                      type={'text'}
                      borderText={'Nationality'}
                      value={personalInfo.countryIso2Code && personalInfo.countryIso2Code.name}
                      disabled={true}
                    />
                  </div>

                  <div className="mt-3 grid sm:grid-cols-2">
                    <BorderedTextInput
                      type={'text'}
                      borderText={'Phone Number'}
                      value={personalInfo.phoneNumber}
                      disabled={true}
                    />
                    <BorderedTextInput
                      type={'text'}
                      borderText={'National ID Number'}
                      value={personalInfo.nationalIdNumber}
                      disabled={true}
                    />
                  </div>
                  <div className="mt-3">
                    <div>
                    <span className="line block m-1">
                      <div className="text-gray-400 border border-gray-400 focus-within:border-i-purple-500 focus:text-i-purple-500 rounded relative">
                        <span className="ml-2 bg-i-purple-50 relative py-0 px-1 bottom-4 text-xs">
                          Selected School(s)
                        </span>
                        <ol className={`${personalInfo.schoolIds && personalInfo.schoolIds.length > 0 ? 'list-decimal' +
                            ' pl-5' : ''} text-xs bg-transparent p-2`}>
                          {personalInfo.schoolIds && personalInfo.schoolIds.length > 0 ? (
                              personalInfo.schoolIds.map((singleSchool, index) => (
                                  <li className="text-black" key={index+1}>{singleSchool.name}</li>
                              ))
                          ) : (
                              <li className="text-black">Select school to appear here</li>
                          )}
                        </ol>
                      </div>
                    </span>
                    </div>
                  </div>
                  <div className="mt-3 flex mx-4">
                    <div className="mr-auto">
                      <button className="bg-i-purple-50 px-9 py-2 text-xs text-i-purple-500 ml-auto rounded-full border border-gray-500" onClick={backRouteChange}>Back</button>
                    </div>
                    <div className="ml-auto">
                      <button className="bg-i-purple-500 px-9 py-2 text-xs text-white ml-auto rounded-full"
                        onClick={e => registerParent(e)}
                      >Next</button>
                    </div>
                  </div>
                </form>
                <div className="hidden grid sm:grid-cols-2">
                  <div></div>
                  <div>
                    <BorderImageInput
                        borderText={'Upload National ID'}
                        labelText={(uploadInfo && uploadInfo.loading) ? 'Uploading image ...' :
                            (uploadInfo.payload.length !== 0 && nationalIdImageData) ?
                                <span><UploadIcon fontSize="small" /> {nationalIdImageData['fileName']}</span> :
                                (<span><UploadIcon fontSize="small" /> Upload</span>)}
                        id="nationalIdNumber"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 grid sm:grid-cols-2 sm:gap-2">
              <div className="mx-auto"></div>
              <div className="text-right">
                <button className="bg-i-purple-50 px-9 py-2 text-xs ml-auto rounded-full" onClick={backRouteChange}>Log Out</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto flex-end">
        <Footer />
      </div>
    </div>
  )
}

import {
  CREATE_SCHOOL_FAILURE,
  CREATE_SCHOOL_LOADING,
  CREATE_SCHOOL_SUCCESS
} from './constants'

const initState = {
  loading: false,
  message: [],
  error: null
}

export default function createSchool(state=initState, action) {
  const { type, message, error } = action

  switch(type) {
    case CREATE_SCHOOL_LOADING:
      return { ...state, loading: true }

    case CREATE_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        message
      }
    
    case CREATE_SCHOOL_FAILURE:
      return {
        ...state,
        loading: false,
        error
      }
    
    default:
      return state;
  }
}
import React, { Fragment, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import NavBar from "../../components/navbars/signedOutNavbar";
import Footer from "../../components/footer";
import ROUTES from "../../utils/routes";
import { useDispatch, useSelector } from "react-redux";
import { userLoginAction } from "./action";
import InformationAccordion from "../../components/common/informationAccordion";
import { backgroundImage } from "../../utils/common";
import BorderedTextInput from "../../components/common/borderedTextInput";
import userLogin from "./reducer";

export default function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({});
  const [loginErrors, setLoginErrors] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const loginMessage = useSelector((state) => state.userLogin.result);
  const apiLoginError = useSelector((state) => state.userLogin.error);
  // const loginMessage = "Not logged in";

  const [dark, setDark] = useState(
    localStorage.getItem("color-theme") === "dark" ||
      (!("color-theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
  );

  const darkModeHandler = () => {
    const newDark = !dark;
    setDark(newDark);
    document.body.classList.toggle("dark", newDark);
    localStorage.setItem("color-theme", newDark ? "dark" : "light");
  };

  useEffect(() => {
    if (dark) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [dark]);

  useEffect(() => {
    const savedCredentials = JSON.parse(
      localStorage.getItem("userCredentials")
    );
    if (savedCredentials) {
      setUserInfo(savedCredentials);
      setRememberMe(true);
    }
  }, []);

  const registerRouteChange = () => {
    let path = ROUTES.accountType;
    navigate(path);
  };

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const resetCodeCorrect = false;

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const loginUser = (e) => {
    e.preventDefault();
    if (userInfo.username && userInfo.password) {
      if (rememberMe) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);
        const userCredentials = {
          ...userInfo,
          expiration: expirationDate.toISOString(),
        };
        localStorage.setItem(
          "userCredentials",
          JSON.stringify(userCredentials)
        );
      } else {
        localStorage.removeItem("userCredentials");
      }
      dispatch(userLoginAction(userInfo));
    } else {
      setLoginErrors([...loginErrors, "Enter login details"]);
    }
  };

  useEffect(() => {
    const storedCredentials = localStorage.getItem("userCredentials");
    if (storedCredentials) {
      const { username, password, expiration } = JSON.parse(storedCredentials);
      if (new Date(expiration) > new Date()) {
        setUserInfo({ username, password });
        setRememberMe(true);
      } else {
        localStorage.removeItem("userCredentials");
      }
    }
  }, []);

  const submitResetEmail = () => {
    console.log("submitted");
  };

  console.log({ apiLoginError });

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  return loginMessage && loginMessage.msg === "Login Successful" ? (
    <Navigate to={ROUTES.userSpaceLibrary} />
  ) : (
    <div
      className="container_bg  bg-primary-900 dark:bg-primary-950 bg-no-repeat bg-cover bg-center w-full h-full min-h-screen bg-blend-overlay"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "repeat-x",
        animation: "slide 30s linear infinite",
      }}
    >
      {showModal && (
        <div className="z-30 fixed inset-0 bg-primary-950 bg-opacity-90 w-full h-full flex items-center justify-center">
          <div className="surface w-full lg:w-1/2 xl:w-1/3 rounded-lg shadow-lg p-4 py-4 md:py-6 lg:py-8 mx-8 md:px-6 lg:px-8">
            <div className="flex justify-between items-center w-full py-2 border-b border-app-colors-border dark:border-app-colors-border-d">
              <div className="flex flex-col justify-center">
                <img
                  id="logo-light"
                  className={`h-8 md:h-8 lg:h-16 ${dark ? "block" : "hidden"}`}
                  src="/images/logo-light.svg"
                  alt="Dark mode logo"
                />
                <img
                  id="logo-dark"
                  className={`h-8 md:h-8 lg:h-16 ${dark ? "hidden" : "block"}`}
                  src="/images/logo-dark.svg"
                  alt="Light mode logo"
                />
                <h5>Forgot Password</h5>
              </div>
              <svg
                onClick={handleHideModal}
                className="icons hover:text-secondary-red-500 cursor-pointer"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.94167 11.1639H11.1639V8.94167H8.94167V11.1639ZM19.4806 2.19611L17.9094 0.625L11.6239 6.91056L13.195 8.48167L19.4806 2.19611ZM0.625 17.9094L2.19611 19.4806L8.48167 13.1961L6.90944 11.625L0.625 17.9094ZM11.6239 13.1961L17.9094 19.4806L19.4806 17.9094L13.195 11.625L11.6239 13.1961ZM0.625 2.19722L6.91056 8.48167L8.48278 6.91056L2.19611 0.625L0.625 2.19722Z"
                />
              </svg>
            </div>
            <div className="overflow-y-auto py-8">
              {resetCodeCorrect ? (
                <div className="text-sm mt-2 text-center">
                  <p>
                    We’ve sent a rest code to your recovery email:
                    jane***@gmail.com. The code is valid for 30 minutes. Enter
                    the code below and proceed to set a new password.
                  </p>
                  <div className="w-80 mx-auto mt-3">
                    <input
                      id="resetEmail"
                      name="resetEmail"
                      type="text"
                      placeholder="6-Digit Code"
                      className="p-2 mt-2 border-1 text-xs rounded-full text-center"
                      value={userInfo.resetEmail ? userInfo.resetEmail : ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-80 mx-auto mt-3">
                    <input
                      id="resetEmail"
                      name="resetEmail"
                      type="text"
                      placeholder="6-Digit Code"
                      className="p-2 mt-2 border-1 text-xs rounded-full text-center w-full"
                      value={userInfo.resetEmail ? userInfo.resetEmail : ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-80 mx-auto mt-1">
                    <input
                      id="resetEmail"
                      name="resetEmail"
                      type="text"
                      placeholder="6-Digit Code"
                      className="p-2 mt-2 border-1 text-xs rounded-full text-center w-full"
                      value={userInfo.resetEmail ? userInfo.resetEmail : ""}
                      onChange={handleChange}
                    />
                  </div>
                  <button
                    className="bg-i-purple-500 text-white text-center focus:outline-none flex-2 py-2 px-4 mt-2 mr-0 sm:grid-cols-1 text-xs rounded-full cursor-pointer"
                    onClick={submitResetEmail}
                  >
                    Change my password
                  </button>
                </div>
              ) : (
                <div>
                  <p className="font-medium">
                    Follow these simple steps to set a new password
                  </p>
                  <ul className="list-decimal pl-8">
                    <li>
                      Enter your{" "}
                      <span className="font-medium text-primary-main-700">
                        ticet edu
                      </span>{" "}
                      username or email address linked to your account
                    </li>
                    <li>Check your recevery email for a reset code</li>
                    <li>Enter the reset code</li>
                    <li>Set the new password</li>
                  </ul>
                  <div className="mt-4 flex justify-center w-full">
                    <div className="flex items-center justify-between rounded-full borders w-fit max-w-">
                      <input
                        id="resetEmail"
                        name="resetEmail"
                        type="text"
                        placeholder="Username or email address"
                        className="flex text-xs bg-transparent px-0 mx-2 w-fit border-0 ring-offset-0 ring-offset-transparent line-clamp-1 input-no-focus"
                        value={userInfo.resetEmail ? userInfo.resetEmail : ""}
                        onChange={handleChange}
                      />

                      <div>
                        <button
                          className="w-fit pill-button primary-button cursor-pointer"
                          onClick={submitResetEmail}
                        >
                          Send me the code
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 text-secondary-red-500 text-center text-12px md:text-12.5px lg:text-13px font-medium leading-snug tracking-normal">
                    Sorry, we could not find an account associated with that
                    email address. Please double-check the spellin. If you are a
                    new member, kindly{" "}
                    <span
                      className="font-medium text-secondary-blue-500 hover:text-secondary-blue-600 cursor-pointer"
                      onClick={registerRouteChange}
                    >
                      REGISTER
                    </span>{" "}
                    to access our services.
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col items-center w-full h-full min-h-screen">
        <NavBar />
        <div className="flex flex-grow w-full max-w-9xl px-4">
          <div className="flex flex-col lg:flex-row gap-12">
            <div className=" lg:basis-2/3 lg:order-last flex flex-1 h-full justify-center lg:justify-end items-center">
              <div className="flex flex-col gap-4 w-full sm:max-w-md">
                {loginErrors.length !== 0 || apiLoginError ? (
                  <div className="surface text-center text-secondary-red-500 text-md py-4 rounded-md">
                    {apiLoginError ? (
                      <div className="small">{apiLoginError.message}</div>
                    ) : null}
                    {loginErrors.length !== 0 &&
                      loginErrors.map((error, index) => (
                        <div key={index}>{error}</div>
                      ))}
                  </div>
                ) : null}
                <div className="border border-gray-400 dark:border-gray-700 surface flex flex-col gap-6 p-4 sm:p-5 md:p-6 lg:p-7 rounded-md">
                  <h5 className="py-2">Sign in to your account</h5>
                  <div>
                    <form action="" className="flex flex-col gap-4">
                      <BorderedTextInput
                        id={"username"}
                        name={"username"}
                        type={"text"}
                        borderText={"Username or Email Address"}
                        handleInputChange={handleChange}
                        value={userInfo.username || ""}
                      />

                      <div className="flex justify-between"></div>

                      <div className="relative">
                        <BorderedTextInput
                          id={"password"}
                          name={"password"}
                          type={showPassword ? "text" : "password"}
                          borderText={"Password"}
                          handleInputChange={handleChange}
                          value={userInfo.password || ""}
                        />
                        <button
                          id="show-hide"
                          type="button"
                          className="absolute right-3 top-1/2 transform -translate-y-1/2"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <svg
                              className="icons"
                              width="20"
                              height="20"
                              viewBox="0 0 64 64"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M35.2008 48H28.8008V54.4H35.2008V48Z" />
                              <path d="M32 9.59961C14.3264 9.59961 0 31.9996 0 31.9996C0 31.9996 9.4176 46.7196 22.4 52.2908V45.1388C16.4416 41.798 11.152 36.1948 7.8656 31.9932C12.8352 25.6092 22.384 15.9996 32 15.9996C41.5616 15.9996 51.1456 25.6284 56.1344 32.0092C52.8544 36.2204 47.5744 41.8236 41.6 45.158V52.2908C54.5824 46.7196 64 31.9996 64 31.9996C64 31.9996 49.6704 9.59961 32 9.59961Z" />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M32.0004 22.4004C26.698 22.4004 22.4004 26.698 22.4004 32.0004C22.4004 37.3028 26.698 41.6004 32.0004 41.6004C37.3028 41.6004 41.6004 37.3028 41.6004 32.0004C41.6004 26.698 37.3028 22.4004 32.0004 22.4004ZM35.2004 35.2004H28.8004V28.8004H35.2004V35.2004Z"
                              />
                            </svg>
                          ) : (
                            <svg
                              className="icons"
                              width="20"
                              height="20"
                              viewBox="0 0 64 64"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_61712_221726)">
                                <path d="M35.2008 48H28.8008V54.4H35.2008V48Z" />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M35.1996 0H41.5996L15.9996 64H9.59961L35.1996 0Z"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M33.334 41.4658C37.9996 40.813 41.5996 36.8482 41.5996 32.0002C41.5996 29.7474 40.7932 27.7026 39.494 26.0674L33.334 41.4658Z"
                                />
                                <path d="M22.4 18.8418V11.709C9.4176 17.2802 0 32.0002 0 32.0002C0 32.0002 3.6768 37.7442 9.6 43.4114V34.0962C8.9792 33.3698 8.3872 32.6594 7.8656 31.9874C11.1456 27.7794 16.4256 22.173 22.4 18.8418Z" />
                                <path d="M41.5996 11.709V18.8642C47.558 22.205 52.8476 27.805 56.134 32.0098C52.854 36.221 47.574 41.8242 41.5996 45.1586V52.2914C54.582 46.7202 63.9996 32.0002 63.9996 32.0002C63.9996 32.0002 54.582 17.2834 41.5996 11.709Z" />
                              </g>
                              <defs>
                                <clipPath id="clip0_61712_221726">
                                  <rect width="64" height="64" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                        </button>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id="remember"
                              aria-describedby="remember"
                              type="checkbox"
                              className="w-6 h-6 text-secondary-blue-600 rounded focus:ring-0 focus:outline-none"
                              checked={rememberMe}
                              onChange={handleRememberMeChange}
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="remember"
                              className="text-gray-500 dark:text-gray-300 hover:text-secondary-blue-600 dark:hover:text-secondary-blue-600 cursor-pointer"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>

                        <div
                          className="text-secondary-blue-500 hover:text-secondary-blue-600 dark:text-secondary-blue-500 dark:hover:text-secondary-blue-600 cursor-pointer"
                          onClick={handleShowModal}
                        >
                          Forgot my password
                        </div>
                      </div>

                      <div className="flex w-full justify-center text-center">
                        <button
                          className="primary-button pill-button px-12 uppercase cursor-pointer"
                          onClick={loginUser}
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <span className="flex gap-2 px-4 lg:px-6">
                  <p className="text-white dark:text-white">
                    Don’t have an account yet?
                  </p>
                  <button
                    href="#"
                    className="font-medium text-white hover:text-secondary-yellow-500 cursor-pointer"
                    onClick={registerRouteChange}
                  >
                    Sign up
                  </button>
                </span>
              </div>
            </div>

            <div className="flex flex-1 lg:basis-1/3 justify-center self-end">
              <InformationAccordion />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

import React from "react";

const TermsAndConditions = () => {
  return (
    <div>
      <p>
        Welcome to Ticet Edu Limited. By accessing and using our platform, you
        agree to comply with and be bound by the following terms and conditions.
        Please read them carefully before using our services.
      </p>

      <h6>1. Acceptance of Terms</h6>
      <p>
        By accessing or using the Ticet platform, you agree to be bound by these
        Terms of Use, as well as our{" "}
        <a
          href="data_protection_policy.html"
          target="_blank"
          className="text-secondary-blue-500"
        >
          Data Protection Policy
          <svg
            className="icons inline-block ml-1"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.58881 0C0.710133 0 0 0.711944 0 1.58881V6.40911C0 7.28779 0.712166 8 1.58881 8H6.40911C7.28779 8 7.99792 7.28806 7.99792 6.41119V4.14114C7.99792 4.0645 7.93971 4.00658 7.86209 4.00658C7.78544 4.00658 7.72805 4.06578 7.72805 4.14114V6.40911C7.72805 7.1348 7.13455 7.72799 6.40916 7.72799H1.58886C0.863174 7.72799 0.269979 7.1345 0.269979 6.40911V1.59089C0.269979 0.8652 0.863471 0.272005 1.58886 0.272005H3.83917C3.91581 0.272005 3.97321 0.214088 3.97321 0.136169C3.97321 0.0592928 3.9132 0.000332454 3.83917 0.000332454L1.58881 0ZM5.62784 0C5.55172 0 5.4938 0.0589618 5.49276 0.135084C5.49456 0.210685 5.5512 0.270167 5.62784 0.270167H7.53812L3.76446 4.04591C3.71072 4.09965 3.71072 4.18406 3.76446 4.23758C3.7902 4.26332 3.82499 4.27653 3.85925 4.27653C3.89351 4.27653 3.92784 4.26332 3.95404 4.23705L7.72978 0.461311V2.37219C7.72978 2.44883 7.79031 2.50802 7.86434 2.50802C7.94099 2.50802 8.00018 2.44883 8.00018 2.37219V0.136129C8.00018 0.0594843 7.94099 0.000292383 7.86434 0.000292383L5.62784 0Z" />
          </svg>
        </a>{" "}
        and{" "}
        <a
          href="privacy_policy.html"
          target="_blank"
          className="text-secondary-blue-500"
        >
          Privacy Policy
          <svg
            className="icons inline-block ml-1"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.58881 0C0.710133 0 0 0.711944 0 1.58881V6.40911C0 7.28779 0.712166 8 1.58881 8H6.40911C7.28779 8 7.99792 7.28806 7.99792 6.41119V4.14114C7.99792 4.0645 7.93971 4.00658 7.86209 4.00658C7.78544 4.00658 7.72805 4.06578 7.72805 4.14114V6.40911C7.72805 7.1348 7.13455 7.72799 6.40916 7.72799H1.58886C0.863174 7.72799 0.269979 7.1345 0.269979 6.40911V1.59089C0.269979 0.8652 0.863471 0.272005 1.58886 0.272005H3.83917C3.91581 0.272005 3.97321 0.214088 3.97321 0.136169C3.97321 0.0592928 3.9132 0.000332454 3.83917 0.000332454L1.58881 0ZM5.62784 0C5.55172 0 5.4938 0.0589618 5.49276 0.135084C5.49456 0.210685 5.5512 0.270167 5.62784 0.270167H7.53812L3.76446 4.04591C3.71072 4.09965 3.71072 4.18406 3.76446 4.23758C3.7902 4.26332 3.82499 4.27653 3.85925 4.27653C3.89351 4.27653 3.92784 4.26332 3.95404 4.23705L7.72978 0.461311V2.37219C7.72978 2.44883 7.79031 2.50802 7.86434 2.50802C7.94099 2.50802 8.00018 2.44883 8.00018 2.37219V0.136129C8.00018 0.0594843 7.94099 0.000292383 7.86434 0.000292383L5.62784 0Z" />
          </svg>
        </a>
        . If you do not agree to these terms, please do not use our platform.
      </p>

      <h6>2. Use of the Platform</h6>
      <p>
        You agree to use the Ticet platform in accordance with the following
        guidelines:
      </p>
      <ul className="list-disc">
        <li>You must be at least 18 years old to use our services.</li>
        <li>
          You are responsible for maintaining the confidentiality of your
          account information.
        </li>
        <li>
          You agree not to use the platform for any unlawful or prohibited
          activities.
        </li>
        <li>
          You agree not to interfere with or disrupt the platform or servers.
        </li>
        <li>
          You agree not to upload or distribute any malicious or harmful
          content.
        </li>
      </ul>

      <h6>3. User Content</h6>
      <p>
        As a user, you may upload, post, or share content on the platform. By
        doing so, you grant Ticet Edu Limited a non-exclusive, royalty-free,
        worldwide license to use, modify, and distribute your content.
      </p>
      <p>
        You represent and warrant that you own or have the necessary rights to
        the content you share, and that it does not violate any third-party
        rights or applicable laws.
      </p>

      <h6>4. Intellectual Property</h6>
      <p>
        All content and materials on the Ticet platform, including text,
        graphics, logos, and software, are the property of Ticet Edu Limited or
        its licensors and are protected by intellectual property laws.
      </p>
      <p>
        You may not use, reproduce, or distribute any content from our platform
        without our prior written permission.
      </p>

      <h6>5. Payments and Subscriptions</h6>
      <p>
        Certain services on the Ticet platform may require payment. By
        purchasing a subscription or any other paid services, you agree to our
        pricing and payment terms.
      </p>
      <p>
        All payments are non-refundable, except as required by law. We reserve
        the right to change our pricing and subscription plans at any time.
      </p>

      <h6>6. Termination</h6>
      <p>
        We reserve the right to terminate or suspend your account at any time,
        without notice, for any violation of these Terms of Use or other
        policies. Upon termination, your right to use the platform will
        immediately cease.
      </p>

      <h6>7. Limitation of Liability</h6>
      <p>
        Ticet Edu Limited is not liable for any direct, indirect, incidental, or
        consequential damages arising from your use of the platform or any
        content provided therein. Your use of the platform is at your own risk.
      </p>

      <h6>8. Indemnification</h6>
      <p>
        You agree to indemnify and hold Ticet Edu Limited, its affiliates, and
        their respective officers, directors, employees, and agents harmless
        from any claims, losses, damages, liabilities, including legal fees,
        arising out of your use of the platform or any violation of these Terms
        of Use.
      </p>

      <h6>9. Modifications to Terms</h6>
      <p>
        We may update these Terms of Use from time to time. Any changes will be
        posted on this page, and your continued use of the platform after such
        changes constitutes your acceptance of the new terms.
      </p>

      <h6>10. Governing Law</h6>
      <p>
        These Terms of Use are governed by and construed in accordance with the
        laws of Kenya. Any disputes arising from these terms shall be resolved
        in the courts of Kenya.
      </p>

      <h6>11. Contact Information</h6>
      <p>
        If you have any questions or concerns about these Terms of Use, please
        contact us at:
      </p>
      <address>
        Ticet Edu Limited
        <br />
        Nairobi Garage, Parklands,
        <br />
        Nairobi, Kenya
        <br />
        Email: <a href="mailto:support@ticet.edu">support@ticet.edu</a>
      </address>

      <h6>12. Miscellaneous</h6>
      <p>
        If any provision of these Terms of Use is found to be invalid or
        unenforceable, the remaining provisions will continue to be valid and
        enforceable.
      </p>
      <p>
        Our failure to enforce any right or provision of these terms will not be
        considered a waiver of those rights.
      </p>
      <p>
        These Terms of Use constitute the entire agreement between you and Ticet
        Edu Limited regarding your use of the platform, superseding any prior
        agreements.
      </p>
      <br />
      <p>
        By using our platform, you acknowledge that you have read, understood,
        and agree to be bound by these Terms of Use.
      </p>
    </div>
  );
};

export default TermsAndConditions;

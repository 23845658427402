import React from 'react';

const ImageInput = ({id, bgColor, labelText, handleImageChange}) => {

  return (
      <div className={`${bgColor} relative rounded-full pb-1`}>
        <input
            id={id}
            type="file"
            accept="image/png,image/jpeg, image/jpg"
            className="text-xs rounded-full absolute z-10 w-full top-0 bottom-0 right-0 left-0 opacity-0 cursor-pointer"
            onChange={(e) => handleImageChange(e)}
        />
        <label htmlFor={id} className="text-xs rounded-full">{labelText}</label>
      </div>
  );
};

export default ImageInput;
import Cookies from 'js-cookie';

export const setJwtTokenCookie = async (token) => {
  await Cookies.set("A5T9U_2p8!L6S3X7r*Q", token, {path: '/', secure: true, sameSite: 'strict'});
};

export const getJwtTokenCookie = async () => {
  const token = await Cookies.get("A5T9U_2p8!L6S3X7r*Q");
  return token;
};

export const setRefreshTokenCookie = async (token) => {
  await Cookies.set("G5T9U_2p8!2SS387r*D", token, {path: '/', secure: true, sameSite: 'strict'});
};

export const getRefreshTokenCookie = async () => {
  const token = await Cookies.get("G5T9U_2p8!2SS387r*D");
  return token;
};
import React from 'react';
import ReactDOM from 'react-dom';
import AppRoutes from './appRoutes';
import reportWebVitals from './reportWebVitals';
import 'flowbite';
import { AuthProvider } from './utils/context/AuthProvider';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import configStore from './store';
import {Provider} from 'react-redux';
import './App.css';
import PageLayout from './PageLayout';

const store = configStore();

ReactDOM.render(
      <Provider store={store}>
        <PageLayout>
          <BrowserRouter>
            <AuthProvider>
              <Routes>
                <Route path="/*" element={<AppRoutes/>}/>
              </Routes>
            </AuthProvider>
          </BrowserRouter>
        </PageLayout>
      </Provider>,

  document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {
  SET_SCHOOL_INFO_FAILURE,
  SET_SCHOOL_INFO_SUCCESS
} from './constants'

const initialState = {
  payload: [],
  error: null
}

export default function setSchoolInfo(state=initialState, action) {
  const { type, payload, error } = action

  switch(type) {
    case SET_SCHOOL_INFO_SUCCESS:
      console.log("parent reducer-->", payload)
      return {
        ...state, payload
      };
    case SET_SCHOOL_INFO_FAILURE:
      return {
        ...state, error
      };
    default:
      return state;
  }
}

import React, {useState} from 'react';
import SignedInNavBar from '../../../components/navbars/signedInNavbar';
import PersonalLibraryHeader from '../../../components/common/personalLibraryHeader';
import PreviewPdf from '../../../utils/previewPDF';

const LibraryWorksheets = () => {

  let files = [
    {
      id: 1,
      title: 'KNEC - KCSE Agriculture Paper 1 2021',
      file: 'https://res.cloudinary.com/daktari/image/upload/v1683634306/infobrix/PDF%20docs/Ruby_Data_Structures_and_Algorithms_n9rwqu.pdf',
      description: 'Lorem ipsum dolor sit amet',
      subject: null,
      course_material: null,
    },
    {
      id: 2,
      title: 'KNEC - KCSE Business Paper 1 2019',
      file: 'https://res.cloudinary.com/daktari/image/upload/v1683715133/infobrix/PDF%20docs/BL-SBP-46271_bkjs4q.pdf',
      description: 'Lorem ipsum dolor sit amet',
      subject: null,
      course_material: null,
    },
    {
      id: 3,
      title: 'KNEC - KCSE Mathematics Paper 2 2020',
      file: 'https://res.cloudinary.com/daktari/image/upload/v1683634163/infobrix/PDF%20docs/JD-_Fullstack_Developer_buy2mv.pdf',
      description: 'Lorem ipsum dolor sit amet',
      subject: null,
      course_material: null,
    },
    {
      id: 4,
      title: 'KNEC - KCSE Geography Paper 1 2017',
      file: 'https://res.cloudinary.com/daktari/image/upload/v1683634306/infobrix/PDF%20docs/Ruby_Data_Structures_and_Algorithms_n9rwqu.pdf',
      description: 'Lorem ipsum dolor sit amet',
      subject: null,
      course_material: null,
    },
    {
      id: 5,
      title: 'KNEC - KCSE Kiswahili Paper 3 2010',
      file: 'https://res.cloudinary.com/daktari/image/upload/v1683715133/infobrix/PDF%20docs/BL-SBP-46271_bkjs4q.pdf',
      description: 'Lorem ipsum dolor sit amet',
      subject: null,
      course_material: null,
    },
    {
      id: 6,
      title: 'KNEC - KCSE Biology Paper 2 2022',
      file: 'https://res.cloudinary.com/daktari/image/upload/v1683634163/infobrix/PDF%20docs/JD-_Fullstack_Developer_buy2mv.pdf',
      description: 'Lorem ipsum dolor sit amet',
      subject: null,
      course_material: null,
    },
    {
      id: 7,
      title: 'KNEC - KCSE German Paper 3 2013',
      file: 'https://res.cloudinary.com/daktari/image/upload/v1683634306/infobrix/PDF%20docs/Ruby_Data_Structures_and_Algorithms_n9rwqu.pdf',
      description: 'Lorem ipsum dolor sit amet',
      subject: null,
      course_material: null,
    }
  ];

  const [hoveredId, setHoveredId] = useState(null);

  return (
      <div className="flex flex-col bg-gray-200 min-h-screen">
        <SignedInNavBar />
        <div className="mt-0 mx-0 lg:ml-14">
          <PersonalLibraryHeader
              name={'Pearson Academy Secondary School'}
              username={'perason'}
              location={'Nairobi, KE'}
              page={'timeline'}
              noOfFollowers={'0'}
              curriculumLevel={'cbc lower primary'}
              noOfUploads={'1,020'}
          />

          <div className="mt-4 mx-5 md:mr-5 md:ml-9">
            <div className="grid gap-4 mb-6 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
              {
                files.length > 0 && (
                  files.map(file => (
                    <div
                      className={"w-full rounded bg-i-sheet-background bg-opacity-20" +
                      " overflow-hidden pb-0 h-44"}
                      key={file.id}
                    >
                      <div className="overflow-hidden mt-3 mx-3"
                         style={{height: `${(hoveredId === file.id) ? "70px" : "110px"}`}}
                      >
                        <PreviewPdf file={file.file} />
                      </div>
                      <div className="p-2 bg-white h-full"
                           onMouseOver={() => setHoveredId(file.id)}
                           onMouseOut={() => setHoveredId(null)}
                      >
                        <div className="text-xs whitespace-nowrap overflow-x-hidden">{file.title}</div>
                        <div className="text-xs mt-2">Exercise</div>
                        <div className="text-xs mt-2">
                          {file.title}
                        </div>
                        <div className="text-xs mt-2">
                          {file.title}
                        </div>
                      </div>
                    </div>
                  ))
                )
              }
            </div>
          </div>
        </div>
      </div>
  );
};

export default LibraryWorksheets;
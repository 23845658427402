import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PreviewPdf = ({file}) => {

  return (
      <div>
        <Document file={file}>
          <Page pageNumber={1} />
        </Document>
      </div>
  );
};

export default PreviewPdf;

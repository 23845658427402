import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { authVerify } from './authVerify';
import { backgroundImage } from '../common';

const PrivateRoutes = () => {
  const [token, setToken] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const verifyAuth = async () => {
      try {
        const retrieved = await authVerify();
        setToken(retrieved);
      } catch (err) {
        console.log({ err });
      }
    };
    (async () => await verifyAuth())();
  }, []);
  
  return (
    <div className='body'>
      {token &&
        (token !== "missing token" ? (
          <Outlet />
        ) : (
          <Navigate to="/" replace state={{ from: location }} />
        ))}
    </div>
  );
};

export default PrivateRoutes;

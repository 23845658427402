import React, {useState} from 'react';
import BorderDateInput from '../../../../../components/common/borderDateInput';

const AffiliationItem = ({logo, name, username, location, fromDate, toDate, onDateChange}) => {
  const [affiliationInfo, setAffiliationInfoInfo] = useState({});
  const [formFilled, setFormFilled] = useState(true);

  const handleImageLoad = (event) => {
    event.target.parentElement.style.background = 'none';
  };

  const handleChange = (e, label) => {
    onDateChange(label, e.target.value)
  }

  return (
      <div className="grid xl:grid-cols-3 items-center mb-3">
        <div className="flex mr-2 col-span-1 mb-2 xl:mb-0">
          <div className={`h-12 min-w-12 bg-gray-200 text-center ro..unded-full mr-2`}>
            <img
              src={logo || '/images/school-icon.png'}
              alt={'logo'}
              className="w-10 h-10"
              onLoad={handleImageLoad}
            />
          </div>
          <div className="text-xs my-auto">
            <div className="font-semibold truncate overflow-hidden text-ellipsis w-48">{name}</div>
            <div className="flex">
              <div className="mr-1 lowercase truncate overflow-hidden text-ellipsis w-24">@{username}</div>
              <div>|</div>
              <div className="ml-1 truncate overflow-hidden text-ellipsis w-24">{location === '' ? 'Kenya' : location}</div>
            </div>
          </div>
        </div>
        <div className="mb-2 xl:mb-0">
          <BorderDateInput
            id={'fromDate'}
            name={'fromDate'}
            borderText={`From: ${(!formFilled && !affiliationInfo.fromDate) ? '*' : ''}`}
            bgColor={'bg-white'}
            handleInputChange={(e) => handleChange(e, 'fromDate')}
            value={fromDate}
          />
        </div>
        <div className="mb-2 xl:mb-0">
          <BorderDateInput
            id={'toDate'}
            name={'toDate'}
            borderText={`To: ${(!formFilled && !affiliationInfo.fromDate) ? '*' : ''}`}
            bgColor={'bg-white'}
            handleInputChange={(e) => handleChange(e, 'toDate')}
            value={toDate}
          />
        </div>
      </div>
  );
};

export default AffiliationItem;
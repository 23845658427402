import React, {useEffect, useState} from 'react';
import BorderedTextInput from '../../../../../components/common/borderedTextInput';
import BorderedTextArea from '../../../../../components/common/borderedTextArea';
import InternationalPhoneField from '../../../../../components/common/internationalPhoneField';
import SearchableDropDown from '../../../../../components/common/searchableDropDown';
import {getCountiesAction} from '../../../../../commonData/counties/action';
import {useDispatch, useSelector} from 'react-redux';
import BorderSelectInput from '../../../../../components/common/borderSelectInput';
import AffiliationItem from './affiliationItem';
import {getCountriesAction} from '../../../../../commonData/countries/action';
import {getCurriculaAction} from '../../../../../commonData/curricula/action';
import {getSchoolsAction} from '../../../../../commonData/schools/action';
import {createMissingSchoolAction} from '../../../../../commonData/missingSchool/action';
import {appendItemToArray, isObjEmpty, removeItemFromArray} from '../../../../../utils/common';
import {getCategoriesAction} from '../../../../../commonData/categories/action';
import {getAffiliationsAction, setAffiliationAction} from './action';

const InstitutionalAffiliations = () => {

  const dispatch = useDispatch();

  const countries = useSelector(state => state.getCountries.payload.data);
  const counties = useSelector(state => state.getCounties.payload.data);
  const schools = useSelector(state => state.getSchools.payload.content);
  const teacherPInfo = useSelector(state => state.setTeacherPersonalInfo.payload);
  const curricula = useSelector(state => state.getCurricula.payload);
  const categories = useSelector(state => state.getCategories.payload);
  const countriesLoading = useSelector(state => state.getCountries.loading);
  const countiesLoading = useSelector(state => state.getCounties.loading);
  const schoolsLoading = useSelector(state => state.getSchools.loading);
  const affiliations = useSelector(state => state.setAffiliation.affiliations);
  const [profileInfo, setProfileInfo] = useState({});
  const [formFilled, setFormFilled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedCurricula, setSelectedCurricula] = useState([]);
  const [newSchoolDetails, setNewSchoolDetails] = useState({});
  const [pageErrors, setPageErrors] = useState(null);
  const [selectedCurriculaLevels, setSelectedCurriculaLevels] = useState([]);
  const [savedCurriculaLevels, setSavedCurriculaLevels] = useState([]);
  const [currentAffiliation, setCurrentAffiliation] = useState({});

  const countriesOptions = [];
  const schoolsOptions = [];
  const countiesOptions = [];
  const categoriesOptions = [];
  const curriculaOptions = [];

  const handleShowModal = () => setShowModal(true)
  const handleHideModal = () => setShowModal(false)

  countries && countries.map(country => (
      countriesOptions.push({ label: country.name, value: country.id })
  ));

  schools && schools.map(school => (
      schoolsOptions.push({ label: school.name, value: school })
  ));

  counties && counties.map(county => (
      countiesOptions.push({ label: county.name, value: county.id })
  ));

  curricula['data'] && curricula['data'].map(curriculum => (
      curriculaOptions.push({label: curriculum.name, value: curriculum.id})
  ))

  categories && categories.map(category => (
      categoriesOptions.push({label: category.level, value: category.id, selected: true})
  ))

  useEffect(() => {
    dispatch(getCountriesAction());
  }, [dispatch])

  const handleCountryChange = code => {
    dispatch(getCountiesAction(code));
    dispatch(getCurriculaAction(code));
  }

  const handleCountyChange = countyId => {
    setProfileInfo({...profileInfo, 'countyId': countyId});
    dispatch(getSchoolsAction(countyId));
  }

  const handleSchoolChange = institutionId => {
    setProfileInfo({...profileInfo, 'institutionId': institutionId});
    console.log('institutionId-->', institutionId);
    setCurrentAffiliation({
      id: institutionId.id,
      logo: institutionId.logoUrl,
      name: institutionId.name,
      username: institutionId.email || institutionId.name.replace(/\s+/g, '_'),
      location: '',
      fromDate: '',
      toDate: ''
    })
  }

  const addAffiliation = e => {
    e.preventDefault();
    if (!isObjEmpty(currentAffiliation)) {
      dispatch(setAffiliationAction(currentAffiliation))
    }
  }

  const cancelAffiliation = () => {
    setCurrentAffiliation({})
  }

  console.log('new Affiliations', affiliations)

  const handleChange = e => {
    setProfileInfo({ ...profileInfo, [e.target.name]: e.target.value });
  }

  const [switchToggle, setSwitchToggle] = useState(false);

  const handleToggle = () => {
    setSwitchToggle(!switchToggle);
  };

  const addNewSchool = e => {
    e.preventDefault();
    console.log('New school details--', newSchoolDetails);
    if(newSchoolDetails.countyId && newSchoolDetails.name) {
      const schoolData = {
        countyId: newSchoolDetails.countyId,
        name: newSchoolDetails.name,
      }
      dispatch(createMissingSchoolAction(schoolData));
    } else {
      setPageErrors('Please enter the missing school name and select a county')
    }
  }

  const handleCurriculaOnChange = (e) => {
    const {value, checked } = e.target;
    let selectedItems = checked ? appendItemToArray(selectedCurricula, value) : removeItemFromArray(selectedCurricula, value);
    setSelectedCurricula(selectedItems);
    dispatch(getCategoriesAction(selectedItems));
  };

  useEffect(() => {
    if (categoriesOptions.length > 0) {
      setSelectedCurriculaLevels(categoriesOptions);
      setSavedCurriculaLevels(categoriesOptions);
    }
  }, [categories])

  const handleCurriculaLevelsOnChange = (e, index, action) => {
    e.preventDefault();
    let updatedItems = [...selectedCurriculaLevels];
    if (action === 'remove') {
      updatedItems[index].selected = false;
      setSavedCurriculaLevels(removeItemFromArray(savedCurriculaLevels, updatedItems[index]))
    } else {
      updatedItems[index].selected = true;
      setSavedCurriculaLevels([...savedCurriculaLevels, updatedItems[index]])
    }
    setSelectedCurriculaLevels(updatedItems);
    setProfileInfo({...profileInfo, 'curricula_levels': savedCurriculaLevels})
  }

  const dummyAffiliations = [
    {
      id: 1,
      logo: null,
      name: 'institutionId.name',
      username: 'institutionId.email',
      location: 'Kakamega'
    },
    {
      id: 2,
      logo: null,
      name: 'institutionId.name2',
      username: 'institutionId.email2',
      location: 'Kisumu'
    }
  ];

  const [affiliationsWithDates, setAffiliationsWithDates] = useState([]);

  useEffect(() => {
    setAffiliationsWithDates(affiliations);
  }, [affiliations]);


  const handleDateChange = (itemId, dateType, value) => {
    setAffiliationsWithDates(prevItems => (
        prevItems.map(item => {
          if (item.id === itemId) {
            return {
              ...item,
              [dateType]: value
            };
          }
          return item;
        })
    ));
  };

  console.log('Dates --', affiliationsWithDates)
  console.log('Affiliations --', affiliations)

  return (
      <div className="bg-white rounded-lg">
        {showModal && (
            <>
              <div className="fixed z-10 bg-gray-800 w-full h-full opacity-40"></div>
              <div className="fixed bg-white shadow z-30 w-1/2 pb-5 rounded" style={{ right: '25%', top: '20%' }}>
                <div className="flex p-4 pb-2">
                  <div className="w-full flex items-center">
                    <span>
                      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" rx="20" fill="#6750A4"/>
                        <g clipPath="url(#clip0_63355_1237)">
                          <path fillRule="evenodd" clipRule="evenodd" d="M21.4286 10H18.5714V18.5714H10V21.4286H18.5714V30H21.4286V21.4286H24.2857V18.5714H21.4286V10ZM27.1429 18.5714V21.4286H30V18.5714H27.1429Z" fill="white"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_63355_1237">
                            <rect width="20" height="20" fill="white" transform="translate(10 10)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <div className="text-lg font-semibold ml-3">Select School</div>
                  </div>
                  <div className="text-white text-xs rounded-full h-5 w-5 cursor-pointer flex items-center justify-center" onClick={handleHideModal}>
                    <span>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.94167 11.1639H11.1639V8.94167H8.94167V11.1639ZM19.4806 2.19611L17.9094 0.625L11.6239 6.91056L13.195 8.48167L19.4806 2.19611ZM0.625 17.9094L2.19611 19.4806L8.48167 13.1961L6.90944 11.625L0.625 17.9094ZM11.6239 13.1961L17.9094 19.4806L19.4806 17.9094L13.195 11.625L11.6239 13.1961ZM0.625 2.19722L6.91056 8.48167L8.48278 6.91056L2.19611 0.625L0.625 2.19722Z" fill="#4B444D"/>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="text-sm mt-2 border-t border-gray-200">
            <div className="p-4">
              <div className="mt-1">
                <form action="">
                  <div className="mt-3">
                    <SearchableDropDown
                      id="countryIso2Code"
                      name="countryIso2Code"
                      options={countriesOptions.length > 0 ? countriesOptions : []}
                      handleChange={handleCountryChange}
                      loading={countriesLoading}
                      borderText={`School Country ${(!formFilled && !teacherPInfo.institutionId) ? '*' : ''}`}
                      bgColor={'bg-white'}
                    />
                  </div>
                  <div className="mt-3">
                    <SearchableDropDown
                      id="county"
                      name="county"
                      options={countiesOptions.length > 0 ? countiesOptions : []}
                      handleChange={handleCountyChange}
                      loading={countiesLoading}
                      borderText={`County/State ${(!formFilled && !teacherPInfo.institutionId) ? '*' : ''}`}
                      bgColor={'bg-white'}
                    />
                  </div>
                  <div className="mt-3">
                    <SearchableDropDown
                      id="institutionId"
                      name="institutionId"
                      options={schoolsOptions.length > 0 ? schoolsOptions : []}
                      handleChange={handleSchoolChange}
                      loading={schoolsLoading}
                      borderText={`School ${(!formFilled && !teacherPInfo.institutionId) ? '*' : ''}`}
                      bgColor={'bg-white'}
                    />
                  </div>

                  <div className="mt-3 grid grid-cols-5 w-full text-xs px-1">
                    <div className="col-span-2 my-auto">
                      <div className="my-auto">Can't find your school?</div>
                    </div>
                    <div className="col-span-2 my-auto text-center">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        className="bg-transparent text-black border border-gray-400 rounded w-full my-auto text-xs"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="text-center my-auto">
                      <button
                          onClick={addNewSchool}
                          className="bg-gray-300 px-3 md:px-4 py-2 rounded-full md:flex my-auto ml-auto cursor-pointer"
                      >
                        <span className="md:mr-1">+</span>
                        <span className="hidden md:block">Add</span>
                      </button>
                    </div>
                  </div>
                  {pageErrors && <div className="text-red-500 text-xs italic text-center">{pageErrors}</div>}

                  {
                    curriculaOptions.length > 0 && (
                      <div className="mt-3">
                        <div className="text-sm">Select Curriculums</div>
                        <div className="flex justify-between mt-2">
                          {
                            curriculaOptions.map((curriculumOption, index) => (
                              <div className="flex items-center mb-4" key={index + 1}>
                                <input
                                  id={curriculumOption.label.replace(/\s+/g, '_')}
                                  type="checkbox"
                                  value={curriculumOption.value}
                                  className="w-6 h-6 text-i-purple-500 bg-white border-gray-300 rounded focus:ring-0 focus:outline-none"
                                  onChange={(e) => handleCurriculaOnChange(e, index)}
                                />
                                <label htmlFor={curriculumOption.label.replace(/\s+/g, '_')}
                                       className="ml-2 text-xs font-medium text-gray-900">{curriculumOption.label}</label>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    )
                  }

                  <div className="mt-3">
                    <span className="line block m-1">
                      <div className={`text-gray-400 border ${!formFilled && savedCurriculaLevels.length <= 0 ? 'border-i-orange-500' : 'border-gray-400'}  focus-within:border-i-purple-500 focus:text-i-purple-500 rounded relative`}>
                        <span className={`ml-2 bg-white relative py-0 px-1 bottom-4 text-xs ${!formFilled && savedCurriculaLevels.length <= 0 ? 'text-i-orange-500' : 'text-gray-400'}`}>
                          Curriculum Levels
                        </span>
                        <div>
                          { selectedCurriculaLevels.length > 0 ? (
                            <div className="p-2 text-xs grid grid-cols-2 gap-1">
                              {selectedCurriculaLevels.map((level, index) => (
                                <div className={`${level.selected ? 'bg-i-purple-500' : 'bg-gray-500'} text-xxs p-1 rounded flex justify-between`}
                                     key={index}>
                                  <span className="text-white">{level.label}</span>
                                  {level.selected ? (
                                    <button
                                      className="bg-gray-100 text-i-purple-600 rounded-full px-1 max-h-4 cursor-pointer"
                                      onClick={(e) => handleCurriculaLevelsOnChange(e, index, 'remove')}
                                    >
                                      x
                                    </button>
                                  ) : (
                                    <button
                                      className="bg-gray-100 text-gray-500 rounded-full px-1 max-h-4 cursor-pointer"
                                      onClick={(e) => handleCurriculaLevelsOnChange(e, index, 'add')}
                                    >
                                      +
                                    </button>
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : (
                              <div className="p-1 text-sm">Please select at least one curriculum to have the levels here</div>
                          )}
                        </div>
                      </div>
                    </span>
                  </div>
                </form>
                <form action="" className="mt-2 px-1">
                  <div className="mt-3 flex mx-4">
                    <div className="mr-auto" />
                    <div className="ml-auto">
                      <button className="bg-i-purple-50 px-9 py-2 text-xs text-i-purple-500 ml-auto rounded-full border border-gray-500 mr-3"
                              onClick={cancelAffiliation}
                      >Cancel</button>
                      <button className="bg-i-purple-500 px-9 py-2 text-xs text-white ml-auto rounded-full"
                              onClick={addAffiliation}
                      >Add</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
        )}
        <div className="border-b border-gray-200">
          <div className="font-semibold px-3 py-4">Institutional Affiliations</div>
        </div>
        <div className="p-3">
          <div className={'mt-3 w-full'}>
            <div className="w-full">
              <button
                  className="account-settings-button bg-white border border-gray-300 text-gray-700 w-full"
                  onClick={() => handleShowModal()}
              >
                <span className="mr-2">
                  <svg width="13" height="13" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_52244_11701)">
                      <path fillRule="evenodd" clipRule="evenodd" d="M36.5714 0H27.4286V27.4286H0V36.5714H27.4286V64H36.5714V36.5714H45.7143V27.4286H36.5714V0ZM54.8571 27.4286V36.5714H64V27.4286H54.8571Z" fill="black"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_52244_11701">
                        <rect width="64" height="64" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span>Add Affiliation (K-12 school only)</span>
              </button>
            </div>
          </div>

          <div className="mt-3">
            {
              (affiliations && affiliations.length > 0) && (
                  affiliations.map((affiliation) => (
                  <AffiliationItem
                    key={affiliation.id}
                    logo={affiliation.logo}
                    name={affiliation.name}
                    username={affiliation.username}
                    location={affiliation.location}
                    onDateChange={(dateType, value) => handleDateChange(affiliation.id, dateType, value)}
                  />
                ))
              )
            }
          </div>


          <div className={'grid sm:grid-cols-2 gap-3 mt-3'}>
            <button className="account-settings-button bg-gray-500">
              <span className="mr-2">
                <svg width="13" height="13" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M28.4454 36.0555H35.5565V28.9444H28.4454V36.0555ZM62.1698 7.35861L57.1423 2.33105L37.0285 22.4448L42.056 27.4724L62.1698 7.35861ZM1.83203 57.6413L6.85959 62.6688L26.9734 42.5586L21.9423 37.5311L1.83203 57.6413ZM37.0285 42.5586L57.1423 62.6688L62.1698 57.6413L42.056 37.5311L37.0285 42.5586ZM1.83203 7.36217L21.9458 27.4724L26.9769 22.4448L6.85959 2.33105L1.83203 7.36217Z" fill="white"/>
                </svg>
              </span>
              <span>Revert Changes</span>
            </button>
            <button className="account-settings-button">
              <span className="mr-2">
                <svg width="15" height="13" viewBox="0 0 75 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_52244_11754)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M22.4849 46.8678L7.63689 32.0251L0.212891 39.4491L22.4849 61.7211L59.5996 24.6064L52.1756 17.1771L22.4849 46.8678ZM67.0236 2.3291L59.5996 9.7531L67.0236 17.1771L74.4476 9.7531L67.0236 2.3291Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_52244_11754">
                      <rect width="74.6667" height="64" fill="white" transform="translate(0 0.5)"/>
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <span>Save</span>
            </button>
          </div>
        </div>
      </div>
  );
};

export default InstitutionalAffiliations;
import React, { useState } from "react";

const Reported = () => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [columnWidths, setColumnWidths] = useState([1, 1, 2.5, 1.5, 1, 1]);

  const [data, setData] = useState([
    {
      id: "SL0527891537",
      date: "12 Dec. 2019",
      file: "Fundamentals of Wave Characteristics - Grade 7",
      violation: "TOU 12.2 Suggestions of nudity",
      status: "Under Review",
    },
    {
      id: "SL0558491258",
      date: "13 Feb. 2022",
      file: "Physics of Wave Characteristics - Grade 7",
      violation: "TOU 12.2 Suggestive, but without nudity",
      status: "Pending",
    },
    {
      id: "SL0525261111",
      date: "13 Apr. 2022",
      file: "Exploring Wave Interference Phenomena - Grade 7",
      violation: "TOU 12.2 Suggestive, but without nudity",
      status: "Deleted by ticet",
    },
    {
      id: "SL0527541257",
      date: "13 Aug. 2023",
      file: "Wave-Particle - Grade 7",
      violation: "TOU 75.5 Academic Dishonesty",
      status: "Deleted by you",
    },
    {
      id: "SL0117891567",
      date: "13 May. 2021",
      file: "Understanding Light Reflection and Refraction - Grade 7",
      violation: "TOU 10.7 Hateful or abusive content",
      status: "Pending",
    },
    {
      id: "SL0557891005",
      date: "13 Jan. 2020",
      file: "Introduction to Physics",
      violation: "TOU 12.2 Suggestive, but without nudity",
      status: "Resolved",
    },
  ]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setData(sortedData);
  };

  const handleMouseDown = (e, index) => {
    const startX = e.clientX;
    const startWidth = columnWidths[index];

    const handleMouseMove = (e) => {
      const newWidth = startWidth + (e.clientX - startX) / 100;
      const newColumnWidths = [...columnWidths];
      newColumnWidths[index] = newWidth;
      setColumnWidths(newColumnWidths);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <div className="surface rounded-md w-full select-none">
      <div className="flex px-6 pt-12 pb-6 lg:pt-14 border-b-2 border-app-colors-border dark:border-app-colors-border-d">
        {["ID", "Date", "File", "Violation", "Status", "Actions"].map(
          (header, index) => (
            <div
              key={index}
              className={`group px-2 py-0.5 resizable-header border-2 border-app-colors-border dark:border-app-colors-border-d ${
                index === 5
                  ? "hidden sm:block"
                  : index === 3
                    ? "hidden md:block"
                    : index === 1 || index === 4
                      ? "hidden 2xl:block"
                      : ""
              }`}
              style={{
                flexGrow: columnWidths[index],
                flexBasis: 0,
                minWidth:
                  index === 0
                    ? "8rem"
                    : index === 1
                      ? "8rem"
                      : index === 2
                        ? "9rem"
                        : index === 3
                          ? "9rem"
                          : index === 4
                            ? "8rem"
                            : index === 5
                              ? "8rem"
                              : "auto",
                maxWidth:
                  index === 0
                    ? "8rem"
                    : index === 1
                      ? "8rem"
                      : index === 2
                        ? "40rem"
                        : index === 3
                          ? "40rem"
                          : index === 4
                            ? "9rem"
                            : index === 5
                              ? "8rem"
                              : "none",
              }}
            >
              {" "}
              <h6
                className={`w-full text-hover-active text-xs md:text-sm lg:text-base line-clamp-1 cursor-pointer ${sortConfig.key === header.toLowerCase().replace(" ", "") ? "text-black" : ""}`}
                onClick={() =>
                  handleSort(header.toLowerCase().replace(" ", ""))
                }
              >
                {header}
              </h6>
              {header !== "Actions" && (
                <div
                  className="resizer"
                  onMouseDown={(e) => handleMouseDown(e, index)}
                  style={{
                    cursor: "col-resize",
                    width: "5px",
                    height: "100%",
                    position: "absolute",
                    right: "0",
                    top: "0",
                    bottom: "0",
                  }}
                />
              )}
            </div>
          )
        )}
      </div>
      <div className="overflow-y-auto" style={{ maxHeight: "75vh" }}>
        {data.map((item, index) => (
          <div className="px-6">
            <div
              key={index}
              className="surface surface-hover group flex text-xs border-b border-app-colors-border dark:border-app-colors-border-d py-4 items-center"
            >
              <p
                className="pl-2 small"
                style={{
                  flexGrow: columnWidths[0],
                  flexBasis: 0,
                  minWidth: "8rem",
                  maxWidth: "8rem",
                }}
              >
                {item.id}
              </p>
              <p
                className="small pl-2 hidden 2xl:flex"
                style={{
                  flexGrow: columnWidths[1],
                  flexBasis: 0,
                  minWidth: "8rem",
                  maxWidth: "8rem",
                }}
              >
                {item.date}
              </p>
              <a
                href="{{...}}"
                className=" pl-2 text-base line-clamp-1"
                style={{
                  flexGrow: columnWidths[2],
                  flexBasis: 0,
                  minWidth: "9rem",
                  maxWidth: "40rem",
                }}
              >
                {item.file}
              </a>
              <p
                className="small hidden md:inline-block pl-2 text-base sm:overflow-hidden"
                style={{
                  flexGrow: columnWidths[3],
                  flexBasis: 0,
                  minWidth: "9rem",
                  maxWidth: "40rem",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {item.violation}
              </p>
              <p
                className="small pl-2 hidden 2xl:flex "
                style={{
                  flexGrow: columnWidths[4],
                  flexBasis: 0,
                  minWidth: "8rem",
                  maxWidth: "9rem",
                }}
              >
                {item.status}
              </p>
              <div
                className="pl-2 hidden sm:flex items-center justify-end flex-nowrap gap-2"
                style={{
                  flexGrow: columnWidths[5],
                  flexBasis: 0,
                  minWidth: "8rem",
                  maxWidth: "8rem",
                }}
              >
                <span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 0C4.477 0 0 4.474 0 9.993C0 14.471 2.946 18.259 7.008 19.531V17.413C4.077 16.225 2.008 13.357 2.008 10.001C2.008 5.583 5.59 2.002 10.008 2.002C14.426 2.002 18.008 5.583 18.008 10.001C18.008 13.357 15.938 16.227 13.008 17.413V19.523C17.061 18.248 20 14.465 20 9.993C20 4.474 15.521 0 10 0ZM9.008 15.001H11.008V9.001H9.008V15.001ZM9.008 7.001H11.008V5.001H9.008V7.001ZM9.008 20H11.008V18H9.008V20Z"
                      fill="#47AB2C"
                    />
                  </svg>
                </span>
                <span>
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 17.2083C0 18.7477 1.25236 20.0001 2.79183 20.0001H16.2977C17.8371 20.0001 19.0895 18.7477 19.0895 17.2083V12.9344C19.0895 12.456 18.7015 12.068 18.2231 12.068C17.7446 12.068 17.3566 12.456 17.3566 12.9344V17.2083C17.3566 17.7922 16.8816 18.2672 16.2977 18.2672H2.79183C2.20783 18.2672 1.73289 17.7922 1.73289 17.2083V4.66487C1.73289 4.08088 2.20788 3.60594 2.79183 3.60594H7.06565C7.54407 3.60594 7.93209 3.21794 7.93209 2.73949C7.93209 2.26107 7.5441 1.87305 7.06565 1.87305H2.79183C1.2524 1.87305 0 3.1254 0 4.66487V17.2083Z"
                      fill="#6750A4"
                    />
                    <path
                      d="M16.9822 0C16.3007 0 15.6602 0.265426 15.1782 0.747137L6.59073 9.33457C6.48501 9.44029 6.40834 9.5718 6.36845 9.71586L5.23901 13.7927C5.15564 14.0935 5.24056 14.4159 5.46129 14.6368C5.62598 14.8015 5.84722 14.8905 6.07398 14.8905C6.15117 14.8905 6.22887 14.8802 6.3052 14.8591L10.382 13.7296C10.5263 13.6897 10.6576 13.6131 10.7633 13.5073L19.3508 4.91992C19.8326 4.43807 20.0979 3.79734 20.0979 3.11591C20.0979 2.43448 19.8325 1.79393 19.3508 1.31208L18.7859 0.747183C18.3042 0.265331 17.6635 4.66707e-05 16.982 4.66707e-05L16.9822 0ZM16.9822 1.73284C17.2007 1.73284 17.4063 1.81794 17.5608 1.97249L18.1257 2.53738C18.2802 2.69193 18.3653 2.89736 18.3653 3.11601C18.3653 3.3345 18.2802 3.54011 18.1257 3.69464L17.8874 3.9329L16.1652 2.21071L16.4035 1.97244C16.5582 1.8179 16.7636 1.7328 16.9821 1.7328L16.9822 1.73284ZM8.45635 9.91979L14.9402 3.43598L16.6624 5.15818L10.1785 11.642L8.45635 9.91979ZM7.65372 11.5677L8.53064 12.4446L7.3178 12.7805L7.65372 11.5677Z"
                      fill="#6750A4"
                    />
                  </svg>
                </span>
                <span>
                  <svg
                    width="23"
                    height="20"
                    viewBox="0 0 23 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.7643 2.22222V0H14.5421H7.87543H5.65321V2.22222H0.0976562V4.44444H2.31988V17.7778C2.31988 19.0044 3.31543 20 4.5421 20H7.87543V17.7778H4.5421V4.44444H5.65321H7.87543V2.22222H14.5421V4.44444H16.7643H17.8754V17.7778H14.5421V20H17.8754C19.1021 20 20.0977 19.0044 20.0977 17.7778V4.44444H22.3199V2.22222H16.7643ZM10.0977 20H12.3199V17.7778H10.0977V20ZM10.0977 6.66667H7.87543V15.5556H10.0977V6.66667ZM14.5421 6.66667H12.3199V15.5556H14.5421V6.66667Z"
                      fill="#E74A2D"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reported;

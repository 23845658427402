import {
  SET_SCHOOL_INFO_FAILURE,
  SET_SCHOOL_INFO_SUCCESS
} from './constants'

export const setSchoolInfo = payload => ({
  type: SET_SCHOOL_INFO_SUCCESS,
  payload
});

export const setSchoolInfoFailure = error => ({
  type: SET_SCHOOL_INFO_FAILURE,
  error
});
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BorderedTextInput from "../../../../../components/common/borderedTextInput";

const LinkedAccounts = () => {
  const dispatch = useDispatch();
  const [linkedPageInfo, setLinkedPageInfo] = useState({});
  const [formFilled, setFormFilled] = useState(true);

  const handleChange = (e) => {
    setLinkedPageInfo({ ...linkedPageInfo, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <div className="surface rounded-md w-full">
        <div className="pt-12 pb-6 lg:pt-14 px-6 border-b-2 border-app-colors-border dark:border-app-colors-border-d">
          <h6>Linked Accounts and Subscriptions</h6>
        </div>
        <div className="px-6 py-8 flex flex-col gap-4">
          <div className="flex flex-col sm:flex-row flex-wrap justify-between md:items-center gap-4 pb-8 border-b border-app-colors-border dark:border-app-colors-border-d">
            <div className="w-full md:max-w-max lg:full 2xl:max-w-max">
              <div className=" flex items-center gap-2">
                <img
                  className="surface object-cover w-12 h-12 rounded-full t-ring ring-2"
                  src="/images/test-avatar.png"
                  alt="avatar"
                />
                <div>
                  <h6 className="text-base">Efe Uzoma Imani</h6>
                  <p className="smaller">@efeuzoma</p>
                </div>
              </div>
            </div>
            <div className="">
              <p className="text-base font-medium">Primary Account</p>
            </div>
            <div className="">
              <div className="flex gap-2">
                <p className="text-base font-medium">Account Type:</p>
                <p className="small pt-0.5">Other</p>
              </div>
            </div>

            <div className="">
              <div className="flex gap-2">
                <p className="text-base font-medium">Subscription:</p>
                <p className="small pt-0.5">Active</p>
              </div>
              <p className="smaller">Expires on March 23, 2024 at 12:23PM</p>
            </div>
          </div>

          <div>
            <div className="flex flex-col gap-2 pb-4 ">
              <p className="small">
                You can add more than one dependent or linked account
              </p>
              <p className="small">
                Dependent account: An account created by you for a user below 13
                years of age. As such, the user's account will be atttached to
                yours.
              </p>
              <p className="small">
                Linked account: A user with an independent account (13 years and
                above) but billing is directed to you.
              </p>
              <p className="small">
                <span className="font-semibold mr-1">N/B:</span>Your account
                will be automatically granted an active subscription if any
                dependent or linked student account has an active subscription,
                provided payment was made using your tokens.
              </p>
            </div>

            <div className="border-b border-t border-app-colors-border dark:border-app-colors-border-d">
              <div className="py-3 border-b border-app-colors-border dark:border-app-colors-border-d">
                <div className="grid grid-flow-row-dense grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-3 2xl:grid-cols-5 gap-3 items-center ">
                  <div className="flex gap-2 items-center">
                    <img
                      className="surface object-cover w-10 h-10 rounded-full t-ring ring-2"
                      src="/images/test-avatar.png"
                      alt="avatar"
                    />
                    <div>
                      <h6 className="text-sm line-clamp-1">Fatuma Bianca</h6>
                      <p className="smaller line-clamp-1">@fatumabianca</p>
                    </div>
                  </div>
                  <div className="hidden 2xl:flex">
                    <p className="small">Dependent Account</p>
                  </div>
                  <div className="hidden lg:flex xl:hidden 2xl:flex">
                    <p className="small">
                      <span className="font-medium">Account Type:</span> Student
                    </p>
                  </div>
                  <div className="hidden sm:flex">
                    <p className="small">
                      <span className="font-medium">Subscription:</span>{" "}
                      Inactive
                    </p>
                  </div>
                  <div className="flex gap-3 justify-end items-center">
                    <div>
                      <button className="rounded-button green-button text-sm h-12">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.64739 13.1386C3.12194 13.1386 2.71299 12.7883 2.5378 12.3211C2.3044 11.5618 2.1875 10.8029 2.1875 9.9855C2.1875 5.83932 5.57435 2.33599 9.72012 2.2191H9.89527V0.642354C9.89527 0.408955 10.0122 0.17515 10.2456 0.0582476C10.3625 6.27813e-07 10.479 0 10.5959 0C10.7128 0 10.8293 0.0582485 10.9462 0.116904L15.1506 2.80324C15.3257 2.92014 15.4426 3.15354 15.4426 3.38735C15.4426 3.62074 15.3257 3.85455 15.1506 3.97145L10.9462 6.65779C10.7128 6.77469 10.479 6.83294 10.2456 6.65779C10.0122 6.54088 9.89527 6.30748 9.89527 6.07368V4.49694H9.72012C6.80042 4.61384 4.52305 7.00767 4.52305 9.92737C4.52305 10.4528 4.5813 11.037 4.75645 11.5624C4.87336 11.8545 4.8147 12.1465 4.69821 12.4381C4.5813 12.7302 4.28925 12.9054 3.9976 13.0223C3.88111 13.1388 3.7643 13.1386 3.64739 13.1386ZM10.1291 17.7515H9.95398V19.3282C9.95398 19.5616 9.83708 19.7954 9.60368 19.9123C9.37028 20.0292 9.07823 20.0292 8.90308 19.9123L4.69871 17.226C4.52356 17.1091 4.40665 16.8757 4.40665 16.6419C4.40665 16.4085 4.52356 16.1747 4.69871 16.0578L8.90308 13.3714C9.01998 13.3132 9.13647 13.2545 9.25338 13.2545C9.37028 13.2545 9.48678 13.2545 9.60368 13.3128C9.83708 13.4297 9.95398 13.6631 9.95398 13.8969V15.415H10.1291C13.0488 15.2981 15.3844 12.9043 15.3844 9.9846C15.3844 9.45914 15.3261 8.87501 15.151 8.34956C15.0341 8.05751 15.0927 7.76546 15.2092 7.47382C15.3261 7.18177 15.6182 7.00662 15.9098 6.88971C16.2019 6.77281 16.4939 6.83147 16.7856 6.94796C17.0776 7.06486 17.2528 7.35692 17.3697 7.64856C17.6031 8.40781 17.72 9.1667 17.72 9.98412C17.7204 14.1893 14.3335 17.635 10.1291 17.7515Z"
                            fill="white"
                          />
                        </svg>
                        Renew
                      </button>
                    </div>
                    <div className="rounded-button primary-button p-0 w-12 h-12">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.4608 4.00078L15.208 4.00078L15.208 2.40078L13.4608 2.40078C13.1296 1.47038 12.2512 0.800781 11.208 0.800781C10.1648 0.800781 9.28639 1.47038 8.95519 2.40078L4.00799 2.40078L4.00799 4.00078L8.95679 4.00078C9.28639 4.93118 10.1648 5.60078 11.208 5.60078C12.2512 5.60078 13.1296 4.93118 13.4608 4.00078ZM10.408 4.00078L10.408 2.40078L12.008 2.40078L12.008 4.00078L10.408 4.00078ZM13.4608 13.6008L15.208 13.6008L15.208 12.0008L13.4608 12.0008C13.1296 11.0712 12.2512 10.4008 11.208 10.4008C10.1648 10.4008 9.28639 11.0712 8.95519 12.0008L0.807985 12.0008L0.807985 13.6008L8.95679 13.6008C9.28639 14.5304 10.1648 15.2008 11.208 15.2008C12.2512 15.2008 13.1296 14.5304 13.4608 13.6008ZM10.408 13.6008L10.408 12.0008L12.008 12.0008L12.008 13.6008L10.408 13.6008ZM7.06079 8.80078L15.208 8.80078L15.208 7.20078L7.06079 7.20078C6.72959 6.27038 5.85119 5.60078 4.80799 5.60078C3.76479 5.60078 2.88639 6.27038 2.55519 7.20078L0.807985 7.20078L0.807985 8.80078L2.55519 8.80078C2.88639 9.73038 3.76639 10.4008 4.80799 10.4008C5.85198 10.4008 6.72959 9.73038 7.06079 8.80078ZM4.00879 8.80078L4.00879 7.20078L5.60799 7.20078L5.60799 8.80078L4.00879 8.80078ZM0.807985 2.40078L0.807985 4.00078L2.40799 4.00078L2.40799 2.40078L0.807985 2.40078Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-3 border-b border-app-colors-border dark:border-app-colors-border-d">
                <div className="grid grid-flow-row-dense grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-3 2xl:grid-cols-5 gap-3 items-center ">
                  <div className="flex gap-2 items-center">
                    <img
                      className="surface object-cover w-10 h-10 rounded-full t-ring ring-2"
                      src="/images/test-avatar.png"
                      alt="avatar"
                    />
                    <div>
                      <h6 className="text-sm line-clamp-1">Fatuma Bianca</h6>
                      <p className="smaller line-clamp-1">@fatumabianca</p>
                    </div>
                  </div>
                  <div className="hidden 2xl:flex">
                    <p className="small">Linked Account</p>
                  </div>
                  <div className="hidden lg:flex xl:hidden 2xl:flex">
                    <p className="small">
                      <span className="font-medium">Account Type:</span> Teacher
                    </p>
                  </div>
                  <div className="hidden sm:flex">
                    <p className="small">
                      <span className="font-medium">Subscription:</span>{" "}
                      Inactive
                    </p>
                  </div>
                  <div className="flex gap-3 justify-end items-center">
                    <div>
                      <button className="rounded-button green-button text-sm h-12">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.64739 13.1386C3.12194 13.1386 2.71299 12.7883 2.5378 12.3211C2.3044 11.5618 2.1875 10.8029 2.1875 9.9855C2.1875 5.83932 5.57435 2.33599 9.72012 2.2191H9.89527V0.642354C9.89527 0.408955 10.0122 0.17515 10.2456 0.0582476C10.3625 6.27813e-07 10.479 0 10.5959 0C10.7128 0 10.8293 0.0582485 10.9462 0.116904L15.1506 2.80324C15.3257 2.92014 15.4426 3.15354 15.4426 3.38735C15.4426 3.62074 15.3257 3.85455 15.1506 3.97145L10.9462 6.65779C10.7128 6.77469 10.479 6.83294 10.2456 6.65779C10.0122 6.54088 9.89527 6.30748 9.89527 6.07368V4.49694H9.72012C6.80042 4.61384 4.52305 7.00767 4.52305 9.92737C4.52305 10.4528 4.5813 11.037 4.75645 11.5624C4.87336 11.8545 4.8147 12.1465 4.69821 12.4381C4.5813 12.7302 4.28925 12.9054 3.9976 13.0223C3.88111 13.1388 3.7643 13.1386 3.64739 13.1386ZM10.1291 17.7515H9.95398V19.3282C9.95398 19.5616 9.83708 19.7954 9.60368 19.9123C9.37028 20.0292 9.07823 20.0292 8.90308 19.9123L4.69871 17.226C4.52356 17.1091 4.40665 16.8757 4.40665 16.6419C4.40665 16.4085 4.52356 16.1747 4.69871 16.0578L8.90308 13.3714C9.01998 13.3132 9.13647 13.2545 9.25338 13.2545C9.37028 13.2545 9.48678 13.2545 9.60368 13.3128C9.83708 13.4297 9.95398 13.6631 9.95398 13.8969V15.415H10.1291C13.0488 15.2981 15.3844 12.9043 15.3844 9.9846C15.3844 9.45914 15.3261 8.87501 15.151 8.34956C15.0341 8.05751 15.0927 7.76546 15.2092 7.47382C15.3261 7.18177 15.6182 7.00662 15.9098 6.88971C16.2019 6.77281 16.4939 6.83147 16.7856 6.94796C17.0776 7.06486 17.2528 7.35692 17.3697 7.64856C17.6031 8.40781 17.72 9.1667 17.72 9.98412C17.7204 14.1893 14.3335 17.635 10.1291 17.7515Z"
                            fill="white"
                          />
                        </svg>
                        Renew
                      </button>
                    </div>
                    <div className="rounded-button red-button p-0 w-12 h-12">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 61 61"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M26.6133 33.7244H33.7244V26.6133H26.6133V33.7244ZM60.3378 5.02756L55.3102 0L35.1964 20.1138L40.224 25.1413L60.3378 5.02756ZM0 55.3102L5.02756 60.3378L25.1413 40.2276L20.1102 35.2L0 55.3102ZM35.1964 40.2276L55.3102 60.3378L60.3378 55.3102L40.224 35.2L35.1964 40.2276ZM0 5.03111L20.1138 25.1413L25.1449 20.1138L5.02756 0L0 5.03111Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-3 border-b border-app-colors-border dark:border-app-colors-border-d">
                <div className="grid grid-flow-row-dense grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-3 2xl:grid-cols-5 gap-3 items-center ">
                  <div className="flex gap-2 items-center">
                    <img
                      className="surface object-cover w-10 h-10 rounded-full t-ring ring-2"
                      src="/images/test-avatar.png"
                      alt="avatar"
                    />
                    <div>
                      <h6 className="text-sm line-clamp-1">Fatuma Bianca</h6>
                      <p className="smaller line-clamp-1">@fatumabianca</p>
                    </div>
                  </div>
                  <div className="hidden 2xl:flex">
                    <p className="small">Linked Account</p>
                  </div>
                  <div className="hidden lg:flex xl:hidden 2xl:flex">
                    <p className="small">
                      <span className="font-medium">Account Type:</span> Other
                    </p>
                  </div>
                  <div className="hidden sm:flex sm:flex-col">
                    <p className="small">
                      <span className="font-medium">Subscription:</span> Active
                    </p>
                    <p className="smaller">
                      Expires on March 23, 2024 at 12:23PM
                    </p>
                  </div>
                  <div className="flex gap-3 justify-end items-center">
                    <div className="rounded-button red-button p-0 w-12 h-12">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 61 61"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M26.6133 33.7244H33.7244V26.6133H26.6133V33.7244ZM60.3378 5.02756L55.3102 0L35.1964 20.1138L40.224 25.1413L60.3378 5.02756ZM0 55.3102L5.02756 60.3378L25.1413 40.2276L20.1102 35.2L0 55.3102ZM35.1964 40.2276L55.3102 60.3378L60.3378 55.3102L40.224 35.2L35.1964 40.2276ZM0 5.03111L20.1138 25.1413L25.1449 20.1138L5.02756 0L0 5.03111Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-8 flex justify-between gap-8">
              <div className="w-full">
                <BorderedTextInput
                  id={"username"}
                  name={"username"}
                  type={"text"}
                  borderText={`Username ${!formFilled && !linkedPageInfo.username ? "*" : ""}`}
                  handleInputChange={handleChange}
                />
              </div>

              <button className="pill-button blue-button px-8">Add</button>
            </div>
          </div>

          <button className="rounded-button primary-button">
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.5072 1.2168V3.384C15.1524 4.7034 16.2072 6.7266 16.2072 8.9991C16.2072 12.0195 14.346 14.6016 11.7072 15.6708V17.5707C15.3549 16.4232 18 13.0185 18 8.9928C18 5.6691 16.191 2.7738 13.5072 1.2168ZM1.8072 9C1.8072 6.7275 2.8629 4.7043 4.5072 3.384V1.2069C1.8162 2.7621 0 5.6628 0 8.9928C0 13.0239 2.6523 16.434 6.3072 17.5779V15.6708C3.6702 14.6025 1.8072 12.0195 1.8072 9ZM4.5072 8.9991H6.3072V7.2H4.5072V8.9991ZM11.7072 8.9991H13.5072V7.2H11.7072V8.9991ZM6.2991 10.8918C6.2991 11.8341 7.506 12.5973 8.9955 12.5973C10.4841 12.5973 11.6919 11.8341 11.6919 10.8918V10.8018H6.2991V10.8918ZM8.1072 5.4H9.9072V3.6H11.7072V1.8H9.9072V0H8.1072V1.8H6.3072V3.6H8.1072V5.4Z"
                  fill="white"
                />
              </svg>
            </span>
            <span>Create a student account for a minor</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LinkedAccounts;

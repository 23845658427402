import React, { useState } from "react";
import BorderedTextInput from "../../../../components/common/borderedTextInput";
import { useDispatch } from "react-redux";
import BorderSelectInput from "../../../../components/common/borderSelectInput";

const AccountDeletionSuspension = () => {
  const dispatch = useDispatch();

  const [accountDeletionInfo, setAccountDeletionInfo] = useState({});
  const [formFilled, setFormFilled] = useState(true);

  const handleChange = (e) => {
    setAccountDeletionInfo({ ...accountDeletionInfo, [e.target.name]: e.target.value });
  };

  return (
      <div className="h-full flex flex-col gap-4 md:gap-6 lg:gap-8">
        <div className="surface rounded-md w-full">
          <div className="flex justify-between items-end pt-12 pb-6 lg:pt-14 px-6 border-b-2 border-app-colors-border dark:border-app-colors-border-d">
            <h6>Account Deletion/Suspension</h6>
          </div>
          <div className="px-6 py-8 flex flex-col gap-8">
            <div className="flex flex-col gap-6">
              <div>
                <p className="small mb-2">
                  At ticet, we value your privacy and strive to provide you with options that suit your needs. With our data privacy settings, you have full control over your personal information. Customize what others can see, choose who can contact you, and decide the visibility of your profile within the platform.
                </p>
                <p className="small mb-2">
                  We understand that sometimes you may need a break from the platform without wanting to permanently delete your account. That's why we offer the option to temporarily suspend your account. Simply choose this option, and your account will be suspended until your next login, allowing you to take a break while keeping your account and data intact. Other users will continue to access your libraries, and if you're a verified content creator, you'll continue to earn income during this period.
                </p>
                <p className="small">
                  If you decide to permanently delete your account, we respect your choice. Account deletion takes effect after 3 days to ensure it's a deliberate decision. However, if you change your mind, you can cancel the deletion by logging in before the 3-day period elapses. Please note that for deleted accounts, all private, public, protected, and paid libraries will also be deleted. However, even though paid libraries will be deleted and made unavailable to other users, active subscribers will still view your library until their subscriptions expire. You can recover your account during this period.
                </p>
              </div>
              <div className="flex flex-col sm:flex-row gap-6 sm:items-center">
                <div className=" flex flex-col w-full">
                  <p className="small">
                    Your privacy and control are important to us. Choose the option that best suits your needs and preferences.
                  </p>
                </div>
                <div className="w-full lg:pt-0 xl:pt-9">
                  <BorderSelectInput
                      id={"option"}
                      name={"option"}
                      title={"option"}
                      options={["Paid", "Private", "Protected", "Public"]}
                      handleInputChange={(e) => {
                        handleChange(e);
                      }}
                      borderText={`Options ${!formFilled && !accountDeletionInfo.libraryType ? "*" : ""}`}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <button className="account-settings-button rounded-button gray-button w-full">
                <span className="">
                  <svg
                      width="13"
                      height="13"
                      viewBox="0 0 64 65"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.4454 36.0555H35.5565V28.9444H28.4454V36.0555ZM62.1698 7.35861L57.1423 2.33105L37.0285 22.4448L42.056 27.4724L62.1698 7.35861ZM1.83203 57.6413L6.85959 62.6688L26.9734 42.5586L21.9423 37.5311L1.83203 57.6413ZM37.0285 42.5586L57.1423 62.6688L62.1698 57.6413L42.056 37.5311L37.0285 42.5586ZM1.83203 7.36217L21.9458 27.4724L26.9769 22.4448L6.85959 2.33105L1.83203 7.36217Z"
                        fill="white"
                    />
                  </svg>
                </span>
                <span>Revert all Changes</span>
              </button>
              <button className="account-settings-button rounded-button green-button w-full">
                <span className="">
                  <svg
                    width="15"
                    height="13"
                    viewBox="0 0 75 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_52244_11754)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22.4849 46.8678L7.63689 32.0251L0.212891 39.4491L22.4849 61.7211L59.5996 24.6064L52.1756 17.1771L22.4849 46.8678ZM67.0236 2.3291L59.5996 9.7531L67.0236 17.1771L74.4476 9.7531L67.0236 2.3291Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_52244_11754">
                        <rect
                          width="74.6667"
                          height="64"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span>Save all Changes</span>
              </button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default AccountDeletionSuspension;

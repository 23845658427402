import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import NavBar from '../../../../components/navbars/signedOutNavbar';
import Footer from '../../../../components/footer';
import ROUTES from '../../../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { setStudentPersonalInfo } from './action';
import SearchableDropDown from '../../../../components/common/searchableDropDown';
import InformationAccordion from '../../../../components/common/informationAccordion';
import BorderedTextInput from '../../../../components/common/borderedTextInput';
import BorderDateInput from '../../../../components/common/borderDateInput';
import InternationalPhoneField from '../../../../components/common/internationalPhoneField';
import BorderSelectInput from '../../../../components/common/borderSelectInput';
import {backgroundImage, getFullName, reverseDateString} from '../../../../utils/common';
import {getNationalitiesAction} from '../../../../commonData/nationalities/action';


export default function StudentPersonalInfo() {

  const userInfo = useSelector(state => state.setUserInfo.payload);
  const accountInfo = useSelector(state => state.setAccountType.payload);
  const countries = useSelector(state => state.getNationalities.payload.data);
  const countriesOptions = [];

  countries && countries.map(country => (
    countriesOptions.push({ label: country.name, value: country })
  ))

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [studPersonalInfo, setStudPersonalInfo] = useState({});
  const [formFilled, setFormFilled] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true)
  const handleHideModal = () => setShowModal(false)

  const nextRouteChange = e => {
    e.preventDefault();
    if (studPersonalInfo.dateOfBirth && studPersonalInfo.gender && studPersonalInfo.countryIso2Code) {
      setFormFilled(true)
    } else {
      return setFormFilled(false)
    }
    if (studPersonalInfo.dateOfBirth && !isStudentAgeLegal(studPersonalInfo["dateOfBirth"]) && !studPersonalInfo.parentUsername) return handleShowModal();

    const info = {
      phoneNumber: studPersonalInfo.phoneNumber,
      dateOfBirth: reverseDateString(studPersonalInfo.dateOfBirth),
      gender: studPersonalInfo.gender,
      countryIso2Code: studPersonalInfo.countryIso2Code,
      parentUsername: studPersonalInfo.parentUsername,
      name: getFullName(studPersonalInfo.firstName, studPersonalInfo.otherNames),
      username: studPersonalInfo.username,
      email: studPersonalInfo.email,
      accountType: accountInfo,
    };

    dispatch(setStudentPersonalInfo(info))
    let path = ROUTES.studentSignUpSelectSchool;
    navigate(path);
  }

  const backRouteChange = () => {
    let path = ROUTES.accountType;
    navigate(path);
  }

  useEffect(() => {
    dispatch(getNationalitiesAction());
  }, [dispatch])

  const handleChange = e => {
    setStudPersonalInfo({ ...studPersonalInfo, [e.target.name]: e.target.value });
  }

  const handleCountryChange = code => {
    studPersonalInfo["countryIso2Code"] = code
  }

  const handlePhoneChange = phone => {
    setStudPersonalInfo({ ...studPersonalInfo, 'phoneNumber': phone });
    // dispatch(setStudentPersonalInfo(studPersonalInfo))
  }

  const isStudentAgeLegal = studentBirthday => {
    return moment().diff(studentBirthday, 'years') >= 13
  }

  const submitParentUsername = () => {
    studPersonalInfo.parentUsername && handleHideModal()
  }

  return (
    <div className="bg-i-purple-500 bg-no-repeat bg-cover bg-center flex flex-col w-full h-full min-h-screen bg-blend-overlay"
         style={{backgroundImage: `url(${backgroundImage})`}}>

      {showModal && (
        <>
          <div className="fixed z-10 bg-gray-800 w-full h-full opacity-40"></div>
          <div className="fixed bg-white shadow z-30 w-1/2 p-4 pb-5" style={{ right: '25%', top: '20%' }}>
            <div className="flex">
              <div className="w-full">
                <h2 className="tracking-widest">eLIMU INFOBRIX</h2>
                <h3 className="text-sm">Age Limit Restrictions</h3>
              </div>
            </div>
            <div className="divide-y-4 w-full h-0.5 bg-gray-300 mt-2"></div>
            <div className="text-sm mt-2 text-center">
              <p>
                You are below 13 years of age. Based on our Children's Online Privacy Protection Policy, your account must be linked to your parent's email address.
                Kindly send them an invite and proceed with the sign up process. If your parent's email is already registered, your account will be linked to his/hers.
              </p>
              <div className="flex w-80 mx-auto mt-3">
                <input
                  id="parentUsername"
                  name="parentUsername"
                  type="text"
                  placeholder="Parent's email address"
                  className="p-2 mt-2 border-1 flex-1 text-xs rounded-full"
                  value={studPersonalInfo.parentUsername ? studPersonalInfo.parentUsername : ""}
                  onChange={handleChange}
                />
                <button
                  className="bg-i-purple-500 text-white text-center focus:outline-none flex-2 py-1 px-4 mt-2 mr-0 -ml-7 sm:grid-cols-1 text-xs rounded-full cursor-pointer"
                  onClick={submitParentUsername}
                >
                  PROCEED
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <NavBar />
      <div className="flex flex-col-reverse lg:flex-row lg:space-x-2">
        <div className="lg:mt-52">
          <div>
            <InformationAccordion
              subTittle={'SOCIAL MEDIA LEARNING PLATFORM'}
              title1={'Collaborate'}
              title2={'Network'}
              title3={'Engage'}
              content1={<Fragment>
                <p>
                  eLIMU INFOBRIX incorporates social, collaboration, and education technologies into one platform
                  tailored for students, teachers, and school administrators in Kenya.
                </p>
                <p>
                  Access unlimited learning/teaching resources, share your thoughts, write blogs, earn badges,
                  and so much more.
                </p>
              </Fragment>}
              content2={<Fragment>
                <p>
                  eLIMU INFOBRIX incorporates social, collaboration, and education technologies into one platform
                  tailored for students, teachers, and school administrators in Kenya.
                </p>
                <p>
                  Access unlimited learning/teaching resources, share your thoughts, write blogs, earn badges,
                  and so much more.
                </p>
              </Fragment>}
              content3={<Fragment>
                <p>
                  eLIMU INFOBRIX incorporates social, collaboration, and education technologies into one platform
                  tailored for students, teachers, and school administrators in Kenya.
                </p>
                <p>
                  Access unlimited learning/teaching resources, share your thoughts, write blogs, earn badges,
                  and so much more.
                </p>
              </Fragment>}
            />
          </div>
          <div></div>
        </div>
        <div className="w-full">
          <div className="w-80 sm:w-96 md:w-128 ml-auto mr-auto lg:mr-14 mt-16 sm:mt-32">
            {!formFilled ? <div className="text-xs text-white text-center bg-ired mb-2 py-1">Please fill in the missing fields</div> : null}
            <div className="px-4 py-5 bg-i-purple-50 rounded-md">
              <h3 className="uppercase text-xl text-center">Personal Information</h3>
              <div className="mt-4">
                <form action="">
                  <div className="grid sm:grid-cols-2 lg:grid-cols-5">
                    <div className="lg:col-span-2">
                      <BorderedTextInput
                        type={'text'}
                        borderText={'First Name'}
                        value={userInfo.firstName && userInfo.firstName}
                        disabled={true}
                      />
                    </div>
                    <div className="lg:col-span-3">
                      <BorderedTextInput
                        type={'text'}
                        borderText={'Other Names'}
                        value={userInfo.lastName && userInfo.lastName}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="mt-3 grid sm:grid-cols-2">
                    <BorderedTextInput
                      type={'text'}
                      borderText={'Username'}
                      value={userInfo.username && userInfo.username}
                      disabled={true}
                    />
                    <BorderedTextInput
                      type={'text'}
                      borderText={'Email Address'}
                      value={userInfo.email && userInfo.email}
                      disabled={true}
                    />
                  </div>

                  <div className="mt-3 grid sm:grid-cols-2">
                    <InternationalPhoneField
                      value={studPersonalInfo.phoneNumber ? studPersonalInfo.phoneNumber : ""}
                      defaultCountry="KE"
                      borderText={`Phone Number ${(!formFilled && !studPersonalInfo.phone) ? '*' : ''}`}
                      handlePhoneChange={handlePhoneChange}
                    />
                    <BorderDateInput
                      id={'dateOfBirth'}
                      name={'dateOfBirth'}
                      borderText={`Date of Birth ${(!formFilled && !studPersonalInfo.dateOfBirth) ? '*' : ''}`}
                      handleInputChange={handleChange}
                      value={studPersonalInfo.dateOfBirth ? studPersonalInfo.dateOfBirth : ""}
                    />
                  </div>

                  <div className="mt-3 grid sm:grid-cols-2">
                    <SearchableDropDown
                      id="countryIso2Code"
                      name="countryIso2Code"
                      placeholder="NATIONALITY"
                      options={countriesOptions.length > 0 ? countriesOptions : []}
                      handleChange={handleCountryChange}
                      borderText={`Nationality ${(!formFilled && !studPersonalInfo.countryIso2Code) ? '*' : ''}`}
                    />
                    <BorderSelectInput
                      id={'gender'}
                      name={'gender'}
                      title={'gender'}
                      options={['male', 'female']}
                      handleInputChange={handleChange}
                      borderText={`Gender ${(!formFilled && !studPersonalInfo.gender) ? '*' : ''}`}
                    />
                  </div>
                  <div className="mt-3 flex mx-4">
                    <div className="mr-auto">
                      <button className="bg-i-purple-50 px-9 py-2 text-xs text-i-purple-500 ml-auto rounded-full border border-gray-500" onClick={backRouteChange}>Back</button>
                    </div>
                    <div className="ml-auto">
                      <button className="bg-i-purple-500 px-9 py-2 text-xs text-white ml-auto rounded-full"
                        onClick={e => nextRouteChange(e)}
                      >Next</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="mt-3 grid sm:grid-cols-2 sm:gap-2">
              <div className="mx-auto"></div>
              <div className="text-right">
                <button className="bg-i-purple-50 px-9 py-2 text-xs ml-auto rounded-full" onClick={nextRouteChange}>Log Out</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto flex-end">
        <Footer />
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { capitalize } from "../../utils/common";

const BorderSelectInput = ({
  borderText,
  id,
  name,
  title = "",
  options = [],
  handleInputChange,
  bgColor = "dark:bg-app-colors-surface-d bg-app-colors-surface",
}) => {
  const [focused, setFocused] = useState(false);
  const [dark, setDark] = useState(
    () => localStorage.getItem("color-theme") === "dark"
  );

  useEffect(() => {
    if (dark) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [dark]);

  const darkModeHandler = () => {
    const newDark = !dark;
    setDark(newDark);
    document.body.classList.toggle("dark", newDark);
    localStorage.setItem("color-theme", newDark ? "dark" : "light");
  };

  return (
    <div>
      <span className="block">
        <div
          className={`relative borders flex flex-col sm:h-10 lg:h-12 text-app-colors-p-small dark:text-app-colors-p-small-d focus-within:border-primary-main-700 focus:text-primary-main-700 rounded ${borderText.includes("*") ? "border-secondary-red-500 focus-within:border-secondary-red-500" : ""}`}
        >
          <div className="relative">
            <span
              className={`absolute inline-flex ml-2 ${bgColor} py-0 px-1 -top-3 text-xs md:text-sm ${focused ? "text-primary-main-700" : ""} ${borderText.includes("*") ? "text-secondary-red-500" : ""}`}
            >
              {borderText}
            </span>
          </div>

          <div className="flex flex-grow pt-3">
            <select
              id={id}
              name={name}
              className="surface surface-hover flex items-center pl-3 text-base font-medium text-app-colors-p-normal dark:text-app-colors-p-normal-d bg-transparent border-0 px-1 pt-0 focus:ring-0 w-full rounded line-clamp-1"
              onChange={handleInputChange}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              style={{
                backgroundColor: dark ? "#20232b" : "#f8fafc",
                color: dark ? "#fff5f3" : "#0f0f0f",
                border: dark ? "0px solid #6b7280" : "0px solid #d1d5db",
                "&:hover": {
                  backgroundColor: dark ? "#2d3138" : "#f5f5f5",
                },
              }}
            >
              <option className="surface surface-hover">Choose {title}</option>
              {options.length > 0 ? (
                options.map((option, index) => (
                  <option
                    value={option}
                    className="surface surface-hover"
                    key={index + 1}
                    style={{
                      backgroundColor: dark ? "#20232b" : "#f8fafc",
                      color: dark ? "#fff5f3" : "#0f0f0f",
                      "&:hover": {
                        backgroundColor: dark ? "#2d3138" : "#f5f5f5",
                        color: dark ? "#fff5f3" : "#0f0f0f",
                      },
                    }}
                  >
                    {capitalize(option)}
                  </option>
                ))
              ) : (
                <option>There are no options</option>
              )}
            </select>
          </div>
        </div>
      </span>
    </div>
  );
};

export default BorderSelectInput;

import React from "react";
import { truncateText } from "../../utils/common";

const WorkSpacePublishedItem = ({
  item = "",
  img = "/images/bg-birthdays.jpg",
  noOfItems = null,
}) => {
  return (
    <div className="group relative surface surface-hover rounded-md cursor-pointer aspect-ratio-4-3 sm:aspect-ratio-1-1">
      <div className="h-full overflow-hidden bg-no-repeat bg-cover bg-center rounded-md ">
        <img src={img} alt="" className="w-full h-full object-cover" />
      </div>
      <div className="min-h-2-lines absolute flex flex-col -bottom-0.5 w-full text-center px-2 py-4 surface border-t border-app-colors-border dark:border-app-colors-border-d rounded-b-md items-center flex-nowrap justify-center transition-all duration-1000">
        <div className="">
          <h6 className="item-title text-hover-active text-base line-clamp-2">
            {truncateText(item)}
            <span className="ml-2 text-base text-secondary-yellow-500">
              {noOfItems && `${noOfItems}`}
            </span>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default WorkSpacePublishedItem;

import React from 'react'
import SignedInNavBar from '../../../components/navbars/signedInNavbar';
import {getImageData} from '../../../utils/common';
import {useSelector} from 'react-redux';
import UserSpaceHeader from '../../../components/common/userSpaceHeader';
import ROUTES from '../../../utils/routes';
import {useNavigate} from 'react-router-dom';
import UserLibraryHeader from '../../../components/common/userLibraryHeader';


export default function UserSpaceTimeline() {

  const uploadInfo = useSelector(state => state.uploadImage);
  const profileImageData = getImageData(uploadInfo.payload, 'profile')
  const navigate = useNavigate();

  const membersRoutePage = () => {
    let path = ROUTES.accountSetUpLibraries
    navigate(path)
  }

  const libraryRoutePage = () => {
    let path = ROUTES.accountSetUpLibraries
    navigate(path)
  }

  const eventsRoutePage = () => {
    let path = ROUTES.accountSetUpLibraries
    navigate(path)
  }

  return (
      <div className="flex flex-col bg-gray-200 min-h-screen">
        <SignedInNavBar />
        <div className="mt-2 mx-2 lg:ml-16">
          <UserSpaceHeader
              name={'Pearson Academy Secondary School'}
              username={'perason'}
              location={'Nairobi, KE'}
              page={'timeline'}
              timelineRoute={null}
              membersRoute={membersRoutePage}
              libraryRoute={libraryRoutePage}
              eventsRoute={eventsRoutePage}
          />
          <div className="mt-5 text-black lg:grid lg:grid-cols-7 mb-5 text-xs">
            <div className="lg:col-span-2 lg:mr-5 hidden lg:block">
              <div className="bg-white rounded">
                <div>
                  <div className="border-b">
                    <div className="flex p-4">
                      <div>About Us</div>
                      <span className="ml-auto">
                        span
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="p-4">
                    <div>
                      <div className="font-bold">Intro:</div>
                      <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="font-bold">Vision:</div>
                      <div>
                        Lorem ipsum dolor sit amet, consectetur.
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="font-bold">Mission:</div>
                      <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="font-bold">Values:</div>
                      <div>
                        <ul>
                          <li>Lorem</li>
                          <li>Ipsum</li>
                          <li>Dolor</li>
                          <li>Sit amet</li>
                        </ul>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="font-bold">Curriculum:</div>
                      <div>
                        <ul>
                          <li>Kenya - CBC</li>
                          <li>Kenya - 844</li>
                        </ul>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="font-bold">Contact:</div>
                      <div>
                        <ul>
                          <li>Email: info@example.com</li>
                          <li>Tel: +254 712 345 678</li>
                        </ul>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="font-bold">Working hours:</div>
                      <div>
                        Mon-Fri 8AM to 5PM
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="font-bold">Address:</div>
                      <div>
                        <ul>
                          <li>Lorem ipsum</li>
                          <li>Dolor sit amet</li>
                        </ul>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="font-bold">Website:</div>
                      <div>
                        www.example.com
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="font-bold">Members:</div>
                      <div>
                        2,782
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 bg-white rounded">
                <div className="border-b">
                  <div className="p-4 font-bold">
                    Page favoured by (68)
                  </div>
                </div>
                <div className="p-4">
                  <div className="grid grid-cols-5">
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:col-span-3 lg:mr-5">
              <div className="bg-white p-4 rounded text-xs">
                <div className="flex">
                  <div className="flex">
                    <img src={profileImageData ? profileImageData['assetUrl'] : "/images/test-avatar.png"} className="mr-2 rounded-full h-12"/>
                    <div className="my-auto">
                      <div className="flex">
                        <div className="font-bold mr-2">Pearson Academy Secondary school</div>
                        <div className="font-light">leveled up</div>
                      </div>
                      <div className="flex font-light text-xs text-gray-500">
                        <span className="mr-2">svg</span>
                        <span className="mr-2">.</span>
                        <span>Yesterday at 6:30 pm</span>
                      </div>
                    </div>
                  </div>
                  <div className="ml-auto">
                    <button id="dropdownDefault" data-dropdown-toggle="dropdown"
                            type="button">...</button>
                    <div id="dropdown"
                         className="hidden z-10 bg-white rounded divide-y divide-gray-100 shadow">
                      <ul className="py-1 text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
                        <li>
                          <a href="#"
                             className="block py-2 px-4 hover:bg-gray-100">Dashboard</a>
                        </li>
                        <li>
                          <a href="#"
                             className="block py-2 px-4 hover:bg-gray-100">Settings</a>
                        </li>
                        <li>
                          <a href="#"
                             className="block py-2 px-4 hover:bg-gray-100">Earnings</a>
                        </li>
                        <li>
                          <a href="#"
                             className="block py-2 px-4 hover:bg-gray-100">Sign out</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex mt-3 text-gray-500">
                  <img src={profileImageData ? profileImageData['assetUrl'] : "/images/test-avatar.png"} className="mr-2 rounded-full h-16"/>
                  <div className="my-auto">
                    <div className="flex">
                      <div className="text-pink-500 mr-2">Excelsior</div>
                      <div className="font-light mr-2">Level 6</div>
                      <div className="font-light">Emojis</div>
                    </div>
                    <div className="flex font-light">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </div>
                  </div>
                </div>
                <div className="flex mt-3 text-gray-700">
                  <span className="mr-2">
                    svg
                  </span>
                  <span className="mr-2">
                    you and
                  </span>
                  <span className="mr-2">
                    svg
                  </span>
                  <span>
                    liked this
                  </span>
                </div>
              </div>

              <div className="bg-white mt-5 rounded p-4">
                <div className="flex">
                  <img src={profileImageData ? profileImageData['assetUrl'] : "/images/test-avatar.png"} className="mr-2 rounded-full h-12"/>
                  <div className="my-auto">
                    <div className="flex">
                      <div className="font-bold mr-2">Pearson Academy Secondary school</div>
                      <div className="font-light">updated their <span className="text-i-purple-500 font-bold">library</span></div>
                    </div>
                    <div className="flex font-light text-xs text-gray-500">
                      <span className="mr-2">svg</span>
                      <span className="mr-2">.</span>
                      <span>Yesterday at 6:30 pm</span>
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <UserLibraryHeader
                      name={'Pearson Academy Secondary school'}
                      username={'pearsonacademy'}
                      category={'CBC LOWER PRIMARY'}
                      noOfFollowers={'1.3K'}
                      noOfUploads={'239'}
                  />
                  <div className="flex mt-3 text-gray-700">
                    <span className="mr-2">
                      svg
                    </span>
                      <span className="mr-2">
                      you and
                    </span>
                      <span className="mr-2">
                      svg
                    </span>
                      <span>
                      liked this
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:col-span-2 hidden lg:block">
              <div className="p-0 rounded text-xs">
                <div className="text-white bg-gradient-to-r from-i-purple-500 to-i-purple-400 p-4 rounded">
                  <span>span</span>
                  <div className="flex mt-2">
                    <div>Profile completion</div>
                    <div className="ml-auto">80%</div>
                  </div>
                  <div className="mt-2">
                    <div className="w-full bg-gray-200 rounded-full h-1.5 mb-2">
                      <div className="bg-i-purple-300 h-1.5 rounded-full" style={{width: '80%'}}></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 bg-white rounded">
                <div className="border-b">
                  <div className="p-4 font-bold">
                    Badges
                  </div>
                </div>
                <div className="p-4">
                  <div className="grid grid-cols-5">
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                    <div className="p-1">
                      <img src="/images/test-avatar.png" alt=""/>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 bg-white rounded">
                <div className="border-b">
                  <div className="p-4 font-bold">
                    Popular uploads
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <div className="grid grid-cols-4 p-4 border-b border-gray-100">
                        <div className="col-span-1">
                          <img src="/images/test-avatar.png" alt=""/>
                        </div>
                        <div className="col-span-3 ml-3 my-auto">
                          <div>KNEC Biology Paper 3 - 2017</div>
                          <div>Worksheet</div>
                        </div>
                      </div>

                      <div className="grid grid-cols-4 p-4 border-b border-gray-100">
                        <div className="col-span-1">
                          <img src="/images/test-avatar.png" alt=""/>
                        </div>
                        <div className="col-span-3 ml-3 my-auto">
                          <div>KNEC Biology Paper 3 - 2017</div>
                          <div>Worksheet</div>
                        </div>
                      </div>

                      <div className="grid grid-cols-4 p-4 border-b border-gray-100">
                        <div className="col-span-1">
                          <img src="/images/test-avatar.png" alt=""/>
                        </div>
                        <div className="col-span-3 ml-3 my-auto">
                          <div>KNEC Biology Paper 3 - 2017</div>
                          <div>Worksheet</div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
  )
}

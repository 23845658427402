import {
    SET_TEACHER_PERSONALINFO_FAILURE,
    SET_TEACHER_PERSONALINFO_SUCCESS
} from './constants'

export const setTeacherPersonalInfo = payload => ({
    type: SET_TEACHER_PERSONALINFO_SUCCESS,
    payload
});

export const setTeacherInfoFailure = error => ({
    type: SET_TEACHER_PERSONALINFO_FAILURE,
    error
});
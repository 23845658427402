import axios from 'axios';
import { CLIENT_API_URL } from '../../../../utils/constants';

import {
  CREATE_PARENT_FAILURE,
  CREATE_PARENT_LOADING,
  CREATE_PARENT_SUCCESS
} from './constants'

export const createParentLoading = () => {
  return {
    type: CREATE_PARENT_LOADING
  }
}

export const createParentSuccess = message => {
  return {
    type: CREATE_PARENT_SUCCESS,
    message
  }
}

export const createParentFailure = error => {
  return {
    type: CREATE_PARENT_FAILURE,
    error
  }
}

export const createParent = ({ countryIso2Code, dateOfBirth, parentUsername, gender, phoneNumber, schoolIds, nationalIdNumber, nationalIdUrl }) => dispatch => {
  const parentDetails = { countryIso2Code, dateOfBirth, parentUsername, gender, phoneNumber, schoolIds, nationalIdNumber, nationalIdUrl }

  dispatch(createParentLoading())
  console.log('Parent details -->', parentDetails)
  axios.post(`${CLIENT_API_URL}/accounts/parents`, parentDetails, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  })
  .then(res => {
    console.log('New Parent -->', res);
    dispatch(createParentSuccess(['Parent registered successfully', res.data]));
  })
  .catch(error => {
    dispatch(createParentFailure({
      message: 'An error occurred while registering parent'
    }))
  })
}
import React, { Fragment, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import UploadIcon from "@mui/icons-material/Upload";

import NavBar from "../../../../components/navbars/signedOutNavbar";
import Footer from "../../../../components/footer";
import ROUTES from "../../../../utils/routes";
import { useDispatch, useSelector } from "react-redux";
import { createSchool } from "../newSchool/action";
import BorderImageInput from "../../../../components/common/borderImageInput";
import { backgroundImage, getImageData } from "../../../../utils/common";
import InformationAccordion from "../../../../components/common/informationAccordion";
import BorderedTextInput from "../../../../components/common/borderedTextInput";
import InternationalPhoneField from "../../../../components/common/internationalPhoneField";

export default function SchoolAuthentication() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [schInfo, setSchInfo] = useState({});
  const [formFilled, setFormFilled] = useState(true);

  const schoolInfo = useSelector((state) => state.setSchoolInfo.payload);
  const uploadInfo = useSelector((state) => state.uploadImage);
  const accountInfo = useSelector((state) => state.setAccountType.payload);
  const userInfo = useSelector((state) => state.setUserInfo.payload);
  const apiRegInfo = useSelector((state) => state.createSchool.message);
  const schoolLogoImageData = getImageData(uploadInfo.payload, "logoUrl");
  const schoolGateImageData = getImageData(uploadInfo.payload, "gateUrl");

  const registerSchool = (e) => {
    e.preventDefault();
    const details = {
      ...schoolInfo,
      institutionId: schoolInfo.institutionId.id,
      curriculaLevelsIds:
        schoolInfo.curricula_levels.length > 0 &&
        schoolInfo.curricula_levels.map((item) => item.value),
    };
    console.log("school details--", details);
    dispatch(createSchool(details));
  };

  const backRouteChange = () => {
    let path = ROUTES.schoolSignUpSchoolDetails;
    navigate(path);
  };

  console.log("School info", schoolInfo);

  return apiRegInfo[0] &&
    apiRegInfo[0] === "Institution registered successfully" ? (
    <Navigate to={ROUTES.userSpaceLibrary} />
  ) : (
    <div
      className="bg-primary-900 dark:bg-primary-950 bg-no-repeat bg-cover bg-center w-full h-full min-h-screen bg-blend-overlay"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="flex flex-col items-center w-full h-full min-h-screen">
        <NavBar />
        <div className="flex flex-grow w-full max-w-9xl px-4">
          <div className="flex flex-col lg:flex-row gap-12">
            <div className="w-full lg:order-last flex flex-1 h-full justify-center lg:justify-end items-center">
              <div className="flex flex-col gap-4 w-full sm:max-w-md">
                
                <div className="border border-gray-400 dark:border-gray-700 surface flex flex-col gap-6 p-2 sm:p-3 md:p-4 lg:p-5 rounded-md">
                  <div className="flex justify-center w-full">
                    <h6 className="py-2 uppercase">Authentication</h6>
                  </div>
                  <div className="flex flex-col gap-6">
                    <form className="flex flex-col gap-6">
                      <BorderedTextInput
                        type={"text"}
                        borderText={"Name"}
                        value={
                          schoolInfo.institutionId &&
                          schoolInfo.institutionId.name
                        }
                        disabled={true}
                      />
                      <div className="flex flex-col sm:flex-row gap-6">
                        <BorderedTextInput
                          type={"text"}
                          borderText={"Email Address"}
                          value={userInfo.email && userInfo.email}
                          disabled={true}
                        />
                        <div className="mt-3 sm:mt-0">
                          <BorderedTextInput
                            type={"text"}
                            borderText={"Account Type"}
                            value={accountInfo}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="">
                        <span className="block">
                          <div
                            className={`borders flex flex-col min-h-fit max-h-40 text-app-colors-p-small dark:text-app-colors-p-small-d focus-within:border-primary-main-700 focus:text-primary-main-700 borders rounded relative`}
                          >
                            <div className="relative">
                              <span
                                className={` absolute py-0 px-1 -top-3 inline-flex ml-2 surface text-xs md:text-sm `}
                              >
                                Curricula
                              </span>
                            </div>

                            <div className="overflow-y-auto my-2 pl-3">
                              {schoolInfo.curricula_levels && (
                                <ol className="list-decimal pl-4 my-2">
                                  {schoolInfo.curricula_levels.length > 0 &&
                                    schoolInfo.curricula_levels.map((level) => (
                                      <li
                                        className="font-medium text-sm"
                                        key={level.value}
                                      >
                                        {level.label}
                                      </li>
                                    ))}
                                </ol>
                              )}
                            </div>
                          </div>
                        </span>
                      </div>
                    </form>
                    <div className="flex justify-between">
                      <button
                        className="outline-pill-button primary-light-button px-9"
                        onClick={backRouteChange}
                      >
                        Back
                      </button>
                      <button
                        className="pill-button primary-button px-9"
                        onClick={(e) => registerSchool(e)}
                      >
                        Finish
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-1 justify-center self-end">
              <InformationAccordion />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

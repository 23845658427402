import React, { useState } from "react";

const contentA = (
  <p className="small">
    ticet edu enhances learning for both learners and educators with
    personalized learning paths, adaptive modules, and multimedia resources for
    active engagement. Learners benefit from tailored experiences, interactive
    lessons, and diverse materials, fostering deeper understanding and
    retention. Educators utilize robust course creation tools, communication
    features, and virtual classrooms to design engaging experiences, provide
    timely feedback, and foster collaborative learning environments. ticet edu
    promotes a culture of lifelong learning, empowering users to achieve their
    educational goals effectively.
  </p>
);

const contentB = (
  <p className="small">
    ticet edu facilitates user collaboration and resource-sharing by providing a
    centralized platform to upload, access, and share educational content such
    as articles, lecture notes, course modules, videos, and presentations. The
    platform allows users to co-create and curate resource collections and share
    them with their peers, enabling them to access knowledge and insights.
    Additionally, ticet edu offers features for discussions, commenting, and
    feedback, allowing users to provide input and engage in meaningful
    conversations around shared resources.
  </p>
);

const contentC = (
  <p className="small">
    ticet edu can help users engage by providing a dynamic and interactive
    learning experience. The platform offers features for gamification, quizzes,
    and interactive exercises, keeping users motivated and interested in the
    learning process. It also provides features for creating and joining groups,
    participating in discussions, and collaborating on projects, enabling users
    to connect with their peers and contribute to a community of learners. These
    opportunities allow users to connect and collaborate with peers, fostering a
    sense of community and shared learning.
  </p>
);

const InformationAccordion = ({
  subTittle = "SOCIAL LEARNING PLATFORM",
  title1 = "Collaborate",
  content1 = contentA,
  title2 = "Engage",
  content2 = contentB,
  title3 = "Learn",
  content3 = contentC,
}) => {
  const [expandedSection, setExpandedSection] = useState(1);

  const handleToggle = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const getButtonClasses = (section) => {
    const baseClasses =
      "group surface surface-hover items-center py-3 px-2 w-full text-left focus:outline-none";
    const activeClasses =
      "bg-secondary-yellow-500 dark:bg-secondary-yellow-500 text-white dark:text-white";
    return expandedSection === section
      ? `${baseClasses} ${activeClasses}`
      : baseClasses;
  };

  return (
    <div className="flex flex-col">
      <div className="mb-4">
        <title className="text-white dark:text-white">ticet edu</title>
        <h5 className="text-white dark:text-white">{subTittle}</h5>
        <div className="">
          <p className="text-white dark:text-white">
            ticet edu is a social learning platform combining social,
            collaboration, and education technologies to create an all-in-one
            platform for students, teachers, school administrators, and other
            key stakeholders in the education sector. With unlimited learning
            and teaching resources, users can share their thoughts, write blogs,
            and earn badges to showcase their progress. The platform encourages
            social interactions, promotes collaborative learning, and
            facilitates user knowledge-sharing.
          </p>
        </div>
      </div>
      <div id="accordion-flush">
        {[
          { title: title1, content: content1, id: 1 },
          { title: title2, content: content2, id: 2 },
          { title: title3, content: content3, id: 3 },
        ].map(({ title, content, id }) => (
          <div key={id}>
            <h2 id={`accordion-flush-heading-${id}`}>
              <button
                type="button"
                className={getButtonClasses(id)}
                onClick={() => handleToggle(id)}
                aria-expanded={expandedSection === id}
                aria-controls={`accordion-flush-body-${id}`}
              >
                <h6>{title}</h6>
              </button>
            </h2>
            <div
              id={`accordion-flush-body-${id}`}
              className={expandedSection === id ? "block" : "hidden"}
              aria-labelledby={`accordion-flush-heading-${id}`}
            >
              <div className="py-3 px-2 surface">{content}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InformationAccordion;

import React from 'react';
import ConnectionListItem from '../../../../components/common/connectionListItem';

function Connections() {
  return (
    <div>
      <div className="bg-white p-4 rounded-md text-sm">
        <div className="flex gap-2 border-b border-gray-200 py-4">
          <div className="font-semibold">Connections</div>
          <div className="text-i-green-500">728</div>
        </div>
        <div className="mt-4">
          <div className="font-semibold">Blocked Users:</div>
          <div className="bg-white p-4 border-l-8 border-i-blue-400 mt-4 text-gray-600">
            Nothing to show
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="grid md:grid-cols-2 gap-2">
          <div>
            <div className="bg-white p-4 border-l-8 border-green-400 flex gap-2">
              <div>Incoming</div>
              <div>3</div>
            </div>
            <div className="mt-4 px-1">
              <div className="bg-white p-4 border-l-8 border-iblue text-gray-600">
                Nothing to show
              </div>
            </div>
          </div>

          <div className="">
            <div className="bg-white p-4 border-l-8 border-red-400">
              Outgoing
            </div>
            <div className="mt-4 px-1 overflow-auto">
              <ConnectionListItem fullName="John Doe" username="johndoe"/>
              <ConnectionListItem fullName="John Doe" username="johndoe"/>
              <ConnectionListItem fullName="John Doe" username="johndoe"/>
              <ConnectionListItem fullName="John Doe" username="johndoe"/>
              <ConnectionListItem fullName="John Doe" username="johndoe"/>
              <ConnectionListItem fullName="John Doe" username="johndoe"/>
              <ConnectionListItem fullName="John Doe" username="johndoe"/>
              <ConnectionListItem fullName="John Doe" username="johndoe"/>
              <ConnectionListItem fullName="John Doe" username="johndoe"/>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Connections;
import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  GET_COUNTRY_LOADING,
  GET_COUNTRY_FAILURE,
  GET_COUNTRY_SUCCESS
} from './constants';

export const getCountriesLoading = () => ({
  type: GET_COUNTRY_LOADING
});

export const getCountriesSuccess = payload => ({
  type: GET_COUNTRY_SUCCESS,
  payload
});

export const getCountriesFailure = error => ({
  type: GET_COUNTRY_FAILURE,
  error
});

export const getCountriesAction = () => dispatch => {
  // console.log('auth--->', AuthGiver)
  dispatch(getCountriesLoading());
  axios.get(`${CLIENT_API_URL}/countries/activated`, {
  })
  .then(res => {
    console.log('country info--->', res.data)
    dispatch(getCountriesSuccess(res.data));
  })
  .catch(error => {
    console.log('Countries action failed -->', error);
    dispatch(getCountriesFailure({
      message: 'An error occurred while fetching countries'
    }))
  })
}

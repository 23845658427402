import React from "react";
import WorkSpaceItem from "../../../../components/common/workSpaceItem";

const Create = () => {
  return (
    <div>
      <div className="grid auto-rows-max gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
        <WorkSpaceItem
          item={"Worksheets"}
          img={"/images/worksheet-cover.jpeg"}
          itemDescription={
            "Upload dynamic documents (.pdf, .pages, .doc, .otd, .ppt, .epub, .txt etc.). Best for sharing lecture notes and written/hands-on practices."
          }
        />

        <WorkSpaceItem
          item={"Courses"}
          img={"/images/coming-soon.png"}
          itemDescription={
            "Create comprehensive courses to deliver structured learning paths. Best for providing a series of lessons and activities on a specific subject."
          }
        />

        <WorkSpaceItem
          item={"Flashcards"}
          img={"/images/coming-soon.png"}
          itemDescription={
            "Create interactive presentations. Ideal for summarizing and reinforcing key concepts of a sub-strand."
          }
        />

        <WorkSpaceItem
          item={"Quizzes"}
          img={"/images/coming-soon.png"}
          itemDescription={
            "Create interactive formative assessments (matching, fill the blanks and multiple-choice questions). Ideal for evaluating mastery of a sub-strand."
          }
        />

        <WorkSpaceItem
          item={"Exercises"}
          img={"/images/coming-soon.png"}
          itemDescription={
            "Create timed interactive summative assessments (multiple-choice and essay questions). Ideal for evaluating mastery of multiple sub-strands."
          }
        />

        <WorkSpaceItem
          item={"Lesson Plans & Schemes of Work"}
          img={"/images/coming-soon.png"}
          itemDescription={
            "Create structured guides detailing educational activities, objectives, and resources to  facilitate effective teaching and learning."
          }
        />
      </div>
    </div>
  );
};

export default Create;

import React from 'react';
import {useSelector} from 'react-redux';
import {getImageData} from '../../utils/common';

const UserLibraryHeader = ({name, username, category, noOfFollowers, noOfUploads}) => {

  const uploadInfo = useSelector(state => state.uploadImage);
  const profileImageData = getImageData(uploadInfo.payload, 'profile')

  return (
      <div className="bg-white">
        <div className="bg-i-purple-300">
          <div className="h-32 sm:h-36 mt-0 rounded-t-lg" style={{background: `url("/images/library_bg.png")`}}>
          </div>
        </div>
        <div>
          <div className="absolute">
            <div className="h-20 w-20 sm:h-24 sm:w-24 rounded-full bg-white -bottom-10 md:-bottom-12 left-0 md:left-4 absolute p-0.5">
              <img src={profileImageData ? profileImageData['assetUrl'] : "/images/test-avatar.png"} alt="profile" className="mr-2 rounded-full"/>
            </div>
          </div>
          <div className="mt-2 flex">
            <div className="ml-20 md:ml-32 mr-0 md:mr-1">
              <div className="font-bold">{name}</div>
              <div className="text-xs">{`@${username}`}</div>
            </div>
            <div className="uppercase mt-1 mr-0 md:mr-1">{category}</div>
            <div className="mr-0 md:mr-1 text-center">
              <div>{noOfFollowers}</div>
              <div>Followers</div>
            </div>
            <div className="mr-0 md:mr-1 text-center">
              <div>{noOfUploads}</div>
              <div>Uploads</div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default UserLibraryHeader;
import React from "react";

const LibraryClusterHeader = () => {
  return (
    <div className="mt-16 lg:mt-20 ml-0">
      <div
        className={` pt-8 flex h-full px-4 md:px-6 lg:px-8 text-center justify-center bg-primary-950 bg-cover bg-center animate-pulse`}
        style={{
          backgroundImage: `url("/images/header-bg-library.png")`,
          backgroundRepeat: "repeat-x",
          animation: "slide 150s linear infinite",
        }}
      >
        <div className="flex flex-col items-center max-w-8xl">
          <div className="flex flex-col items-center gap-4 max-w-5xl">
            <title className="text-app-colors-heading-d dark:text-app-colors-heading-d mb-2 font-medium">
              ticet edu Library
            </title>
            <p className=" text-app-colors-heading-d dark:text-app-colors-heading-d mb-2">
              ticet edu library brings people and information together. Here you
              can update you library and access libraries belonging to other
              community members. The community library allows you to share and
              access resources, as well as gain knowledge beyond school
              boundaries.
            </p>
          </div>
          <img
            src="/images/libraries-bottom.webp"
            alt=""
            className="flex w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default LibraryClusterHeader;

import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
// import AuthGiver from '../../utils/context/AuthGiver';
import {
  GET_NATIONALITY_LOADING,
  GET_NATIONALITY_FAILURE,
  GET_NATIONALITY_SUCCESS
} from './constants';

export const getNationalityLoading = () => ({
  type: GET_NATIONALITY_LOADING
});

export const getNationalitySuccess = payload => ({
  type: GET_NATIONALITY_SUCCESS,
  payload
});

export const getNationalityFailure = error => ({
  type: GET_NATIONALITY_FAILURE,
  error
});

export const getNationalitiesAction = () => dispatch => {
  // console.log('auth--->', AuthGiver)
  dispatch(getNationalityLoading());
  axios.get(`${CLIENT_API_URL}/countries/nationalities`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  })
  .then(res => {
    console.log('nationality info--->', res.data)
    dispatch(getNationalitySuccess(res.data));
  })
  .catch(error => {
    console.log('Nationality action failed -->', error);
    dispatch(getNationalityFailure({
      message: 'An error occurred while fetching nationalities'
    }))
  })
}

import React from "react";

const SubjectsHeader = ({ subjectName, subjectDescription }) => {
  return (
    <div
      className={`mt-16 lg:mt-20 ml-0 pt-8 flex flex-col h-72 w-full px-4 md:px-6 lg:px-8 items-center justify-center bg-primary-900 dark:bg-primary-950 bg- bg-blend-overlay bg-center`}
      style={{
        backgroundImage: `url("/images/bg-section1.png")`,
        backgroundRepeat: "repeat-x",
      }}
    >
      <div className="flex w-full max-w-8xl">
        <title className="text-app-colors-heading-d dark:text-app-colors-heading-d mb-2 font-medium">
          {subjectName}
        </title>
        {/*<p className="text-sm mt-3 sm:mt-5 leading-6">{subjectDescription}</p>*/}
      </div>
    </div>
  );
};

export default SubjectsHeader;

import {
  GET_NATIONALITY_LOADING,
  GET_NATIONALITY_FAILURE,
  GET_NATIONALITY_SUCCESS
} from './constants';

const initialState = {
  loading: false,
  payload: [],
  error: null
};

export default function getNationalities(state = initialState, action) {
  const { type, payload, error } = action;

  switch(type) {
    case GET_NATIONALITY_LOADING:
      return { ...state, loading: true };
    case GET_NATIONALITY_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      };
    case GET_NATIONALITY_FAILURE:
      return {
        ...state,
        loading: false,
        error
      };
    default:
      return state;
  }
}
import React, {useEffect, useState} from 'react'
import AccountSetUpNavbar from '../../../components/navbars/accountSetUpNavBar';
import {useNavigate} from 'react-router-dom';
import ROUTES from '../../../utils/routes';
import SubjectsCard from '../../../components/common/subjectsCard';
import {useDispatch} from 'react-redux';
import CropImage from '../../../components/common/cropImage';
import {uploadImageAction} from '../../../commonData/imageUpload/action';
import AccountSetupTop from '../../../components/common/accountSetupTop';
import ImageInput from '../../../components/common/imageInput';


export default function AccountSetUpSubjects() {

  const [toggleState, setToggleState] = useState(1);
  const [toggleModalState, setToggleModalState] = useState(1);
  const [avatarUrl, setAvatarUrl] = useState("/images/purple_full_logo.png")
  const [showModal, setShowModal] = useState(false);
  const [editor, setEditor] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const [scaleValue, setScaleValue] = useState(1)

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const setEditorRef = editor => setEditor(editor)

  useEffect(() => {
    handleShowModal();
  }, [])

  const handleShowModal = () => setShowModal(true)
  const handleHideModal = () => setShowModal(false)


  const toggleTab = (index) => {
    setToggleState(index);
  };

  const toggleModalTab = (index) => {
    setToggleModalState(index);
  };

  const profileImageChange = event => {
    const file = event.target.files[0];
    const { type } = file;
    if(type.endsWith('png') || type.endsWith('jpeg') || file.endsWith('jpg')) {
      setSelectedImage(file)
    }
  }

  const uploadPresetAvatar = async (fileUrl) => {
    if (editor !== null) {

    }
  }

  const onCrop = async () => {
    if(editor !== null) {
      const url = editor.getImageScaledToCanvas().toDataURL();
      const file = 'data:image/jpeg;base64,' + url;
      const files = [file];
      await selectedImage && dispatch(uploadImageAction(files, 'profile'))
      if(selectedImage !== null)  {
        handleHideModal();
      }
    }
  }

  const onScaleChange = (event) => {
    const value = parseFloat(event.target.value)
    setScaleValue(value)
  }

  const connectionsRoutePage = () => {
    let path = ROUTES.accountSetUpConnections
    navigate(path)
  }

  const librariesRoutePage = () => {
    let path = ROUTES.accountSetUpLibraries
    navigate(path)
  }

  return (
      <div className="flex flex-col bg-gray-200 min-h-screen">

        {showModal && (
            <>
              <div className="fixed z-10 bg-gray-800 w-full h-full opacity-40"></div>
              <div className="fixed bg-white rounded shadow z-30 mx-auto w-72 sm:w-96 md:w-128 inset-x-0 top-56">

                <div className="flex justify-between items-start py-2 px-4 rounded-t border-b">
                  <div className="tracking-wider">
                    <h3 className="text-xl text-gray-900 text-xl">
                      eLIMU INFOBRIX
                    </h3>
                    <div className="text-xs">Profile Picture/Avatar</div>
                  </div>
                  <button onClick={handleHideModal}>X</button>
                </div>

                <div className="text-center px-4">
                  <div className="grid grid-cols-2 mt-2">
                    <div>
                      <button
                          className={`focus:outline-none w-full px-4 py-2 text-xs rounded-l-lg ${toggleModalState === 1 ? 'tabs bg-i-purple-500 text-white border border-i-purple-500' : 'border border-gray-300'}`}
                          onClick={() => toggleModalTab(1)}
                      >
                        Avatar
                      </button>
                    </div>

                    <div>
                      <button
                          className={`focus:outline-none w-full px-4 py-2 text-xs rounded-r-lg ${toggleModalState === 2 ? 'tabs bg-i-purple-500 text-white border border-i-purple-500' : 'border border-gray-300'}`}
                          onClick={() => toggleModalTab(2)}
                      >
                        Profile Picture
                      </button>
                    </div>
                  </div>
                </div>

                <div className="my-4">
                  <div className={`${(toggleModalState === 1) ? "block" : "hidden"}`}>
                    <div className="md:grid grid-cols-2 px-4">
                      <div className="w-44 mx-auto">
                        <svg viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-44 h-24 sm:h-28 mb-4 sm:mb-0">
                          <g clipPath="url(#clip0_52244_11762)">
                            <path d="M48 6.9C53.2928 6.9 57.6 11.2072 57.6 16.5C57.6 21.7928 53.2928 26.1 48 26.1C42.7072 26.1 38.4 21.7928 38.4 16.5C38.4 11.2072 42.7072 6.9 48 6.9ZM48 0.5C39.1648 0.5 32 7.6648 32 16.5C32 25.3384 39.1648 32.5 48 32.5C56.8384 32.5 64 25.3384 64 16.5C64 7.6648 56.8384 0.5 48 0.5Z" fill="#808080"/>
                            <path d="M44.8008 58.1001H51.2008V64.5001H44.8008V58.1001Z" fill="#808080"/>
                            <path d="M25.5992 51.6999C25.5992 48.1671 28.4696 45.2999 31.9992 45.2999H63.9992V38.8999H31.9992C24.9304 38.8999 19.1992 44.6311 19.1992 51.6999V64.4999H38.3992V58.0999H25.5992V51.6999Z" fill="#808080"/>
                            <path d="M57.5996 58.1001H63.9996V64.5001H57.5996V58.1001Z" fill="#808080"/>
                            <path d="M0 6.8999H25.6V13.2999H0V6.8999Z" fill="#808080"/>
                            <path d="M0 19.7002H19.2V26.1002H0V19.7002Z" fill="#808080"/>
                            <path d="M0 32.5H12.8V38.9H0V32.5Z" fill="#808080"/>
                            <path d="M0 45.2998H6.4V51.6998H0V45.2998Z" fill="#808080"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_52244_11762">
                              <rect width="64" height="64" fill="white" transform="translate(0 0.5)"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div>
                        <div className="flex grid grid-cols-6 gap-2 mb-2">
                          <img src="/images/test-avatar.png" alt="" onClick={() => setAvatarUrl("/images/test-avatar.png")}/>
                          <img src="/images/test-avatar.png" alt="" onClick={() => setAvatarUrl("/images/test-avatar.png")}/>
                          <img src="/images/test-avatar.png" alt="" onClick={() => setAvatarUrl("/images/test-avatar.png")}/>
                          <img src="/images/test-avatar.png" alt="" onClick={() => setAvatarUrl("/images/test-avatar.png")}/>
                          <img src="/images/test-avatar.png" alt="" onClick={() => setAvatarUrl("/images/test-avatar.png")}/>
                          <img src="/images/test-avatar.png" alt="" onClick={() => setAvatarUrl("/images/test-avatar.png")}/>
                        </div>
                        <div className="flex grid grid-cols-6 gap-2 mb-2">
                          <img src="/images/user-avatar.png" alt="" onClick={() => setAvatarUrl("/images/user-avatar.png")}/>
                          <img src="/images/user-avatar.png" alt="" onClick={() => setAvatarUrl("/images/user-avatar.png")}/>
                          <img src="/images/user-avatar.png" alt="" onClick={() => setAvatarUrl("/images/user-avatar.png")}/>
                          <img src="/images/user-avatar.png" alt="" onClick={() => setAvatarUrl("/images/user-avatar.png")}/>
                          <img src="/images/user-avatar.png" alt="" onClick={() => setAvatarUrl("/images/user-avatar.png")}/>
                          <img src="/images/user-avatar.png" alt="" onClick={() => setAvatarUrl("/images/user-avatar.png")}/>
                        </div>
                        <div className="flex grid grid-cols-6 gap-2 mb-2">
                          <img src="/images/test-avatar.png" alt="" onClick={() => setAvatarUrl("/images/test-avatar.png")}/>
                          <img src="/images/test-avatar.png" alt="" onClick={() => setAvatarUrl("/images/test-avatar.png")}/>
                          <img src="/images/test-avatar.png" alt="" onClick={() => setAvatarUrl("/images/test-avatar.png")}/>
                          <img src="/images/test-avatar.png" alt="" onClick={() => setAvatarUrl("/images/test-avatar.png")}/>
                          <img src="/images/test-avatar.png" alt="" onClick={() => setAvatarUrl("/images/test-avatar.png")}/>
                          <img src="/images/test-avatar.png" alt="" onClick={() => setAvatarUrl("/images/test-avatar.png")}/>
                        </div>
                        <div className="grid grid-cols-2">
                          <div></div>
                          <button className="text-xs text-white bg-i-purple-500 w-full rounded-full py-2 mt-2" onClick={onCrop}>Continue</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`px-2 ${(toggleModalState === 2) ? "block" : "hidden"}`}>
                    <div className="md:grid grid-cols-2">
                      <CropImage
                        imageSrc={selectedImage}
                        setEditorRef={setEditorRef}
                        scaleValue={scaleValue}
                        onScaleChange={(e) => onScaleChange(e)}
                      />

                      <div>
                        <div className="text-xs text-center py-20">Zoom, drag, and crop frame to adjust photo.</div>
                        <div className="grid grid-cols-2">
                          <div className="border border-gray-300 rounded-full text-center">
                            <ImageInput
                                id={'profilePic'}
                                labelText={'Upload Picture'}
                                bgColor={'bg-white'}
                                handleImageChange={profileImageChange}
                            />
                          </div>

                          <div className="text-right">
                            <button className="bg-gray-300 text-gray-800 text-xs px-5 py-2 rounded-full"
                                    onClick={onCrop}>Continue</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
        )}
        <AccountSetUpNavbar />
        <div>
          <div className="bg-i-blue-400">
            <AccountSetupTop
                themeColor={'blue'}
                page={'subjects'}
                subjectsPageRoute={null}
                connectionsPageRoute={connectionsRoutePage}
                librariesPageRoute={librariesRoutePage}
            />
          </div>
          <div className="mt-10 mx-4 lg:mx-10 text-sm">
            <div className="flex">
              <div>
                <button
                    className={`focus:outline-none rounded-l-lg p-4 text-xs flex ${toggleState === 1 ? 'tabs' +
                        ' bg-i-purple-500 text-white border border-i-purple-500' : 'border border-gray-400'}`}
                    onClick={() => toggleTab(1)}
                >
                  <span className="my-auto sm:mr-1">
                    <svg width="11" height="13" viewBox="0 0 56 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M32.0545 3.5H17.2222C10.5178 3.5 7.16559 3.5 5.0828 5.5828C3 7.66559 3 11.0178 3 17.7222V53.2778C3 59.9822 3 63.3344 5.0828 65.4172C7.16559 67.5 10.5178 67.5 17.2222 67.5H38.5556C45.26 67.5 48.6122 67.5 50.695 65.4172C52.7778 63.3344 52.7778 59.9822 52.7778 53.2778V24.2233C52.7778 22.77 52.7778 22.0433 52.5071 21.3899C52.2365 20.7365 51.7226 20.2226 50.695 19.195L37.0828 5.5828C36.0551 4.55513 35.5413 4.0413 34.8879 3.77065C34.2345 3.5 33.5078 3.5 32.0545 3.5Z" stroke={`${ toggleState === 1 ? 'white' : '#1e293b'}`} strokeWidth="6"/>
                      <path d="M17.2227 39.0557L38.556 39.0557" stroke={`${ toggleState === 1 ? 'white' : '#1e293b'}`} strokeWidth="6" strokeLinecap="round"/>
                      <path d="M17.2227 53.2778L31.4449 53.2778" stroke={`${ toggleState === 1 ? 'white' : '#1e293b'}`} strokeWidth="6" strokeLinecap="round"/>
                      <path d="M31.4453 3.5V17.7222C31.4453 21.0744 31.4453 22.7505 32.4867 23.7919C33.5281 24.8333 35.2042 24.8333 38.5564 24.8333H52.7786" stroke={`${ toggleState === 1 ? 'white' : '#1e293b'}`} strokeWidth="6"/>
                    </svg>
                  </span>
                  <span className="mr-1 hidden sm:block">My Subjects</span>
                  <span className="my-auto ml-2 sm:ml-0">0</span>
                </button>
              </div>

              <div>
                <button
                    className={`focus:outline-none rounded-r-lg p-4 text-xs flex ${toggleState === 2 ? 'tabs' +
                        ' bg-i-purple-500 text-white border border-i-purple-500' : 'border border-gray-400'}`}
                    onClick={() => toggleTab(2)}
                >
                  <span className="my-auto sm:mr-1">
                    <svg width="12" height="13" viewBox="0 0 63 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.2227 39.0557L38.556 39.0557" stroke={`${ toggleState === 2 ? 'white' : '#1e293b'}`} strokeWidth="6" strokeLinecap="round"/>
                      <path d="M17.2227 24.8335L31.4449 24.8335" stroke={`${ toggleState === 2 ? 'white' : '#1e293b'}`} strokeWidth="6" strokeLinecap="round"/>
                      <path d="M17.2227 53.2778L31.4449 53.2778" stroke={`${ toggleState === 2 ? 'white' : '#1e293b'}`} strokeWidth="6" strokeLinecap="round"/>
                      <path d="M52.7778 39.0556V61.5C52.7778 64.3284 52.7778 65.7426 51.8991 66.6213C51.0204 67.5 49.6062 67.5 46.7778 67.5H8.99999C6.17157 67.5 4.75736 67.5 3.87868 66.6213C3 65.7426 3 64.3284 3 61.5V9.5C3 6.67157 3 5.25736 3.87868 4.37868C4.75736 3.5 6.17157 3.5 9 3.5H24.3333" stroke={`${ toggleState === 2 ? 'white' : '#1e293b'}`} strokeWidth="6"/>
                      <path d="M49.2227 3.5L49.2227 24.8333" stroke={`${ toggleState === 2 ? 'white' : '#1e293b'}`} strokeWidth="6" strokeLinecap="round"/>
                      <path d="M59.8887 14.1665L38.5553 14.1665" stroke={`${ toggleState === 2 ? 'white' : '#1e293b'}`} strokeWidth="6" strokeLinecap="round"/>
                    </svg>
                  </span>
                  <span className="hidden sm:block">Suggested Subjects</span>
                  <span className="invisible sm:hidden">0</span>
                </button>
              </div>

              <div className="mt-auto mb-auto ml-auto flex">
                <button className="rounded-full text-white bg-i-purple-500 py-2 px-2.5 mr-3">
                  <svg width="13" height="13" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_52244_11695)">
                      <path fillRule="evenodd" clipRule="evenodd" d="M14.2222 7.76178V0H7.11111V7.76533C2.976 9.23378 0 13.1378 0 17.7778C0 22.4178 2.976 26.3218 7.11111 27.7902V49.7778H14.2222V27.7867C18.3573 26.3182 21.3333 22.4142 21.3333 17.7778C21.3333 13.1413 18.3573 9.23378 14.2222 7.76178ZM14.2222 21.3333H7.11111V14.2222H14.2222V21.3333ZM56.8889 7.76178V0H49.7778V7.76533C45.6462 9.23378 42.6667 13.1378 42.6667 17.7778C42.6667 22.4178 45.6462 26.3182 49.7778 27.7902V64H56.8889V27.7867C61.0204 26.3182 64 22.4142 64 17.7778C64 13.1413 61.0204 9.23378 56.8889 7.76178ZM56.8889 21.3333H49.7778V14.2222H56.8889V21.3333ZM35.5556 36.2098V0H28.4444V36.2098C24.3093 37.6782 21.3333 41.5822 21.3333 46.2222C21.3333 50.8587 24.3093 54.7627 28.4444 56.2347V64H35.5556V56.2347C39.6871 54.7627 42.6667 50.8551 42.6667 46.2222C42.6667 41.5822 39.6871 37.6782 35.5556 36.2098ZM35.5556 49.7742H28.4444V42.6667H35.5556V49.7742ZM7.11111 64H14.2222V56.8889H7.11111V64Z" fill="white"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_52244_11695">
                        <rect width="64" height="64" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <div className="flex">
                  <input type="text" id="small-input"
                         className="border-0 p-2 w-10 sm:w-full rounded bg-white text-xs focus:ring-i-purple-500 rounded-lg"
                         placeholder={'Search Subject ...'}
                  />
                </div>
              </div>

            </div>

            <div className="mt-4">
              <div className={`${(toggleState === 1) ? "block" : "hidden"}`}>
                <div className="bg-white p-4 border-l-8 border-i-purple-500 rounded-lg">
                  No subjects to show
                </div>
              </div>

              <div className={`${(toggleState === 2) ? "block" : "hidden"}`}>
                <div className="flex grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 gap-6 mb-6">
                  <SubjectsCard subjectName="English" buttonLabel="Add Subject"/>
                  <SubjectsCard subjectName="English" buttonLabel="Remove Subject"/>
                  <SubjectsCard subjectName="English" buttonLabel="Add Subject"/>
                  <SubjectsCard subjectName="English" buttonLabel="Remove Subject"/>
                  <SubjectsCard subjectName="English" buttonLabel="Remove Subject"/>
                  <SubjectsCard subjectName="English" buttonLabel="Add Subject"/>
                  <SubjectsCard subjectName="English" buttonLabel="Remove Subject"/>
                  <SubjectsCard subjectName="English" buttonLabel="Add Subject"/>
                </div>
              </div>


            </div>
          </div>


        </div>
      </div>
  )
}

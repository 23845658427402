import axios from 'axios';
import {CLIENT_API_URL} from './constants';
import useAuth from './hooks/useAuth';

export const reverseDateString = str => {
  return str.toString().split("-").reverse().join("-");
};

export const customRedirect = redirectUrl => {
  window.location = redirectUrl;
};

export const getFullName = (firstName, lastName) => {
  if(firstName && lastName) return (firstName + ' ' + lastName);
  if(firstName && !lastName) return (firstName);
  if(lastName && !firstName) return (lastName);
  return '';
}

export const getImageData = (imageArray, id) => {
  let result = imageArray.find(ar => ar['id'] === id);
  return result ? result : false
}

export const backgroundImage = '/images/ticet_bg1.png';

export const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getOptions = (arr, keyName) => {
  const result = []
  arr.map(item => (
      result.push(item[keyName])
  ));
  return result;
}

export const AuthGiver = () => {
  const {auth} = useAuth();
  return `sasa -- ${auth?.accessToken}`;
};

export const validateEmail = email => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return false;
  } else {
    return true;
  }
}

// export const removeItemFromArray = (array, item) => array.slice(0, item-1).concat(array.slice(item, array.length))

export const removeItemFromArray = (array, item) => {
  const index = array.indexOf(item);

  if (index !== -1) {
    array.splice(index, 1);
  }

  return array;
}

export const appendItemToArray = (array, item) => {
  return [...array, item];
}

export const isObjEmpty = obj => {
  return Object.keys(obj).length === 0;
}

export const findUserRole = (array) => {
  const roleItem = array.find(item => typeof item === 'string' && item.startsWith('ROLE_'));
  return roleItem || null;
}

export const obscurePhoneNumber = (phoneNumber) => {
  const lastFourChars = phoneNumber.slice(-4);
  const xString = 'X'.repeat(phoneNumber.length - 4);
  return xString + '-' + lastFourChars;
};

export const truncateText = (str, maxLength=50) => {
  return str.length <= maxLength ? str : str.substring(0, maxLength - 3) + "...";
};
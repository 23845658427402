import React, {useState} from 'react'
import AccountSetUpNavbar from '../../../components/navbars/accountSetUpNavBar';
import ConnectionsCard from '../../../components/common/connectionsCard';
import ConnectionListItem from '../../../components/common/connectionListItem';
import {useNavigate} from 'react-router-dom';
import ROUTES from '../../../utils/routes';
import AccountSetupTop from '../../../components/common/accountSetupTop';


export default function AccountSetUpConnections() {

  const [toggleState, setToggleState] = useState(1);
  const navigate = useNavigate()

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const subjectsRoutePage = () => {
    let path = ROUTES.accountSetUpSubjects
    navigate(path)
  }

  const librariesRoutePage = () => {
    let path = ROUTES.accountSetUpLibraries
    navigate(path)
  }

  return (
      <div className="flex flex-col bg-gray-200 min-h-screen">
        <AccountSetUpNavbar />
        <div>
          <div className="bg-i-green-400">
            <AccountSetupTop
              themeColor={'green'}
              page={'connections'}
              subjectsPageRoute={subjectsRoutePage}
              connectionsPageRoute={null}
              librariesPageRoute={librariesRoutePage}
            />
          </div>


          <div className="mt-10 mx-4 lg:mx-10 text-sm">
            <div className="flex">
              <div>
                <button
                    className={`focus:outline-none rounded-l-lg p-2 text-xs flex ${toggleState === 1 ? 'tabs bg-i-purple-500 text-white border border-i-purple-500' : 'border border-gray-400'}`}
                    onClick={() => toggleTab(1)}
                >
                  <span className="my-auto sm:mr-1">
                    <svg width="13" height="13" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_52244_11758)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M32 0.5C14.3264 0.5 0 14.82 0 32.4776C0 46.8104 9.4304 58.932 22.4256 63.0024V56.2216C13.0464 52.4232 6.4256 43.2424 6.4256 32.5032C6.4256 18.3624 17.888 6.9 32.0256 6.9C46.1632 6.9 57.6256 18.3624 57.6256 32.5032C57.6256 43.2424 51.0016 52.4232 41.6256 56.2216V62.9768C54.5952 58.8968 64 46.788 64 32.4776C64 14.82 49.6704 0.5 32 0.5ZM22.4224 26.1H16.0224V32.5032H22.4224V26.1ZM41.6256 26.1V32.5032H48.0256V26.1H41.6256ZM22.3968 39.2296C22.3968 42.58 26.688 45.2936 31.984 45.2936C37.28 45.2936 41.5712 42.58 41.5712 39.2296V38.9096H22.3968V39.2296ZM28.8256 64.5032H35.2256V58.1032H28.8256V64.5032Z" fill={`${ toggleState === 1 ? 'white' : '#1e293b'}`}/>
                      </g>
                      <defs>
                        <clipPath id="clip0_52244_11758">
                          <rect width="64" height="64" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span className="mr-1 hidden sm:block">My Connections</span>
                  <span className="my-auto ml-2 sm:ml-0">0</span>
                </button>
              </div>

              <div>
                <button
                    className={`focus:outline-none p-2 text-xs flex ${toggleState === 2 ? 'tabs bg-i-purple-500 text-white border border-i-purple-500' : 'border-t border-b border-gray-400'}`}
                    onClick={() => toggleTab(2)}
                >
                  <span className="my-auto sm:mr-1">

                    <svg width="13" height="13" viewBox="0 0 67 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_52244_11756)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M26.6589 11.2148C11.9309 11.2148 -0.0078125 23.1454 -0.0078125 37.8654C-0.0078125 49.8068 7.85086 59.9108 18.6802 63.2975V57.6521C10.8642 54.4841 5.34686 46.8335 5.34686 37.8814C5.34686 26.1028 14.8989 16.5508 26.6802 16.5508C38.4615 16.5508 48.0135 26.1028 48.0135 37.8814C48.0135 46.8335 42.4962 54.4868 34.6802 57.6521V63.2814C45.4882 59.8815 53.3255 49.7908 53.3255 37.8654C53.3255 23.1454 41.3869 11.2148 26.6589 11.2148ZM18.6802 32.5508H13.3469V37.8814H18.6802V32.5508ZM34.6802 32.5508V37.8814H40.0135V32.5508H34.6802ZM18.6562 43.4895C18.6562 46.2788 22.2322 48.5428 26.6455 48.5428C31.0589 48.5428 34.6349 46.2788 34.6349 43.4895V43.2201H18.6562V43.4895ZM24.0135 64.5508H29.3469V59.2148H24.0135V64.5508ZM27.6109 5.88145H45.3469C45.3469 5.88145 48.3362 6.71611 49.7122 7.27611L54.5069 3.68145C51.0135 1.69478 46.9842 0.550781 42.6802 0.550781C36.9682 0.550781 31.7309 2.55345 27.6109 5.88145ZM64.9442 15.6201L60.6829 19.8788C61.0722 21.3801 61.3442 22.9241 61.3442 24.5508C61.3442 28.0628 60.3682 31.3348 58.6775 34.1294V42.4094C63.5815 38.0201 66.6775 31.6494 66.6775 24.5481C66.6802 21.3881 66.0562 18.3828 64.9442 15.6201ZM61.3469 8.55078H56.0135V13.8841H61.3469V8.55078Z" fill={`${ toggleState === 2 ? 'white' : '#1e293b'}`}/>
                      </g>
                      <defs>
                        <clipPath id="clip0_52244_11756">
                          <rect width="66.6667" height="64" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span className="hidden sm:block">Suggested Connections</span>
                  <span className="invisible sm:hidden">0</span>
                </button>
              </div>

              <div>
                <button
                    className={`focus:outline-none rounded-r-lg p-2 text-xs flex ${toggleState === 3 ? 'tabs bg-i-purple-500 text-white border border-i-purple-500' : 'border border-gray-400'}`}
                    onClick={() => toggleTab(3)}
                >
                  <span className="my-auto sm:mr-1">
                    <svg width="13" height="13" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-white">
                      <g clipPath="url(#clip0_52244_11757)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M48.0256 4.8264V12.532C53.8752 17.2232 57.6256 24.4168 57.6256 32.4968C57.6256 43.236 51.008 52.4168 41.6256 56.2184V62.9736C54.5952 58.8936 64 46.788 64 32.4744C64 20.6568 57.568 10.3624 48.0256 4.8264ZM6.4256 32.5C6.4256 24.42 10.1792 17.2264 16.0256 12.532V4.7912C6.4576 10.3208 0 20.6344 0 32.4744C0 46.8072 9.4304 58.932 22.4256 62.9992V56.2184C13.0496 52.42 6.4256 43.236 6.4256 32.5ZM16.0256 32.4968H22.4256V26.1H16.0256V32.4968ZM41.6256 32.4968H48.0256V26.1H41.6256V32.4968ZM22.3968 39.2264C22.3968 42.5768 26.688 45.2904 31.984 45.2904C37.2768 45.2904 41.5712 42.5768 41.5712 39.2264V38.9064H22.3968V39.2264ZM28.8256 19.7H35.2256V13.3H41.6256V6.9H35.2256V0.5H28.8256V6.9H22.4256V13.3H28.8256V19.7Z" fill={`${ toggleState === 3 ? 'white' : '#1e293b'}`}/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M28.8008 58.1001H35.2008V64.5001H28.8008V58.1001Z" fill={`${ toggleState === 3 ? 'white' : '#1e293b'}`}/>
                      </g>
                      <defs>
                        <clipPath id="clip0_52244_11757">
                          <rect width="64" height="64" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span className="hidden sm:block">Connection Requests</span>
                  <span className="invisible sm:hidden">0</span>
                </button>
              </div>

              <div className="mt-auto mb-auto ml-auto flex">
                <button className="rounded-full text-white bg-i-purple-500 py-2 px-2.5 mr-3">
                  <svg width="13" height="13" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_52244_11695)">
                      <path fillRule="evenodd" clipRule="evenodd" d="M14.2222 7.76178V0H7.11111V7.76533C2.976 9.23378 0 13.1378 0 17.7778C0 22.4178 2.976 26.3218 7.11111 27.7902V49.7778H14.2222V27.7867C18.3573 26.3182 21.3333 22.4142 21.3333 17.7778C21.3333 13.1413 18.3573 9.23378 14.2222 7.76178ZM14.2222 21.3333H7.11111V14.2222H14.2222V21.3333ZM56.8889 7.76178V0H49.7778V7.76533C45.6462 9.23378 42.6667 13.1378 42.6667 17.7778C42.6667 22.4178 45.6462 26.3182 49.7778 27.7902V64H56.8889V27.7867C61.0204 26.3182 64 22.4142 64 17.7778C64 13.1413 61.0204 9.23378 56.8889 7.76178ZM56.8889 21.3333H49.7778V14.2222H56.8889V21.3333ZM35.5556 36.2098V0H28.4444V36.2098C24.3093 37.6782 21.3333 41.5822 21.3333 46.2222C21.3333 50.8587 24.3093 54.7627 28.4444 56.2347V64H35.5556V56.2347C39.6871 54.7627 42.6667 50.8551 42.6667 46.2222C42.6667 41.5822 39.6871 37.6782 35.5556 36.2098ZM35.5556 49.7742H28.4444V42.6667H35.5556V49.7742ZM7.11111 64H14.2222V56.8889H7.11111V64Z" fill="white"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_52244_11695">
                        <rect width="64" height="64" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <div className="flex">
                  <input type="text" id="small-input"
                         className="border-0 p-2 w-10 sm:w-full rounded bg-white text-xs focus:ring-i-purple-500 rounded-lg"
                         placeholder={'Search Connection ...'}
                  />
                </div>
              </div>

            </div>

            <div className="mt-4">
              <div className={`${(toggleState === 1) ? "block" : "hidden"}`}>
                <div className="flex grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-6">
                  <ConnectionsCard fullName="Daktari Dindi" username="daktari" buttonLabel="Add Connection"/>
                  <ConnectionsCard fullName="Daktari Dindi" username="daktari" buttonLabel="Add Connection"/>
                  <ConnectionsCard fullName="Daktari Dindi" username="daktari" buttonLabel="Add Connection"/>
                  <ConnectionsCard fullName="Daktari Dindi" username="daktari" buttonLabel="Add Connection"/>
                </div>
                <div className="flex grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-6">
                  <ConnectionsCard fullName="Daktari Dindi" username="daktari" buttonLabel="Add Connection"/>
                  <ConnectionsCard fullName="Daktari Dindi" username="daktari" buttonLabel="Add Connection"/>
                  <ConnectionsCard fullName="Daktari Dindi" username="daktari" buttonLabel="Add Connection"/>
                  <ConnectionsCard fullName="Daktari Dindi" username="daktari" buttonLabel="Add Connection"/>
                </div>
                <div className="flex grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-6">
                  <ConnectionsCard fullName="Daktari Dindi" username="daktari" buttonLabel="Add Connection"/>
                  <ConnectionsCard fullName="Daktari Dindi" username="daktari" buttonLabel="Add Connection"/>
                  <ConnectionsCard fullName="Daktari Dindi" username="daktari" buttonLabel="Add Connection"/>
                  <ConnectionsCard fullName="Daktari Dindi" username="daktari" buttonLabel="Add Connection"/>
                </div>

              </div>

              <div className={`${(toggleState === 2) ? "block" : "hidden"}`}>
                <div className="bg-white p-4 border-l-8 border-iblue">
                  No connections to show
                </div>
              </div>

              <div className={`${(toggleState === 3) ? "block" : "hidden"}`}>
                <div className="grid md:grid-cols-2 gap-2">
                  <div>
                    <div className="bg-white p-4 border-l-8 border-green-400">
                      Incoming Connections
                    </div>
                    <div className="mt-4 px-1">
                      <div className="bg-white p-4 border-l-8 border-iblue">
                        No connections to show
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="bg-white p-4 border-l-8 border-red-400">
                      Outgoing Connections
                    </div>
                    <div className="mt-4 px-1 overflow-auto h-96">
                      <ConnectionListItem fullName="Victor Jambo" username="jambo"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo"/>
                      <ConnectionListItem fullName="Victor Jambo" username="jambo"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

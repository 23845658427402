import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const InternationalPhoneField = ({
  value,
  handlePhoneChange,
  defaultCountry,
  borderText,
  bgColor = "dark:bg-app-colors-surface-d bg-app-colors-surface",
}) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true);
    e.target.style.boxShadow = "0 0 0";
  };

  return (
    <div>
      <span className="block">
        <div
          className={`relative borders flex flex-col sm:h-10 lg:h-12 text-app-colors-p-small dark:text-app-colors-p-small-d focus-within:border-primary-main-700 focus:text-primary-main-700 rounded ${borderText.includes("*") ? "border-secondary-red-500 focus-within:border-secondary-red-500" : ""}`}
        >
          <div className="relative">
            <span
              className={`absolute inline-flex ml-2 ${bgColor} py-0 px-1 -top-3 text-xs md:text-sm ${focused ? "text-primary-main-700" : ""} ${borderText.includes("*") ? "text-secondary-red-500" : ""}`}
            >
              {borderText}
            </span>
          </div>

          <div className="flex flex-grow pt-1">
            <PhoneInput
              value={value}
              defaultCountry={defaultCountry}
              className="ml-3 text-base font-medium text-app-colors-p-normal dark:text-app-colors-p-normal-d bg-transparent border-0 px-1 pt-0 focus:ring-0 w-full rounded line-clamp-1"
              onChange={(phone) => handlePhoneChange(phone)}
              ref={(el) => {
                if (el) {
                  el.style.setProperty("border", "none", "important");
                  el.style.setProperty("boxShadow", "none", "important");
                  el.style.setProperty(
                    "background",
                    "transparent",
                    "important"
                  );
                  
                }
              }}
              onFocus={(e) => handleFocus(e)}
              onBlur={() => setFocused(false)}
            />
          </div>
        </div>
      </span>
    </div>
  );
};

export default InternationalPhoneField;

import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  GET_SCHOOLS_LOADING,
  GET_SCHOOLS_FAILURE,
  GET_SCHOOLS_SUCCESS
} from './constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const getSchoolsLoading = () => ({
  type: GET_SCHOOLS_LOADING
});

export const getSchoolsSuccess = payload => ({
  type: GET_SCHOOLS_SUCCESS,
  payload
});

export const getSchoolsFailure = error => ({
  type: GET_SCHOOLS_FAILURE,
  error
});

export const getSchoolsAction = (countyId) => async dispatch => {
  dispatch(getSchoolsLoading());

  axios.get(`${CLIENT_API_URL}/institutions?countyId=${countyId}&page=1&size=5000`, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
  .then(res => {
    console.log('Schools action called --');
    console.log('schools -->', res.data.data);
    dispatch(getSchoolsSuccess(res.data.data));
  })
  .catch(error => {
    console.log('Schools action failed --');
    dispatch(getSchoolsFailure({
      message: 'An error occurred while fetching schools'
    }))
  })
}
import React from 'react'
import AvatarEditor from 'react-avatar-editor';

const CropImage = ({imageSrc, setEditorRef, scaleValue, onScaleChange}) => {
  return (
    <div className="text-center px-2 ">
      <div className={`${imageSrc === null ? '' : 'hidden'} text-center`}>
        <img src="/images/person-circle.svg" alt="person-avatar" className="fixed w-36 ml-12 sm:ml-24 md:ml-10 mt-10 md:mt-6"/>
      </div>
      <AvatarEditor
        image={imageSrc}
        width={180}
        height={180}
        border={0}
        color={[255, 255, 255, 0.8]} // RGBA
        scale={scaleValue}
        rotate={0}
        borderRadius={250}
        ref={setEditorRef}
        className={`${imageSrc && 'border border-gray-300'} rounded-full`}
      />
      <input
        type="range"
        value={scaleValue}
        min="1"
        max="10"
        onChange={onScaleChange}
        className={`${imageSrc === null ? 'hidden' : 'w-full'} sm:mt-5`}
      />
    </div>
  )
}

export default CropImage;
import {
  USER_LOGIN_FAILURE,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_LOADING
} from './constants';
import axios from '../../utils/api/axios'
import {CLIENT_API_URL} from '../../utils/constants';
import {setJwtTokenCookie, setRefreshTokenCookie} from '../../utils/cookieHelper';

export const userLoginLoading = () => {
  return {
    type: USER_LOGIN_LOADING
  }
}

export const userLoginSuccess = result => {
  return {
    type: USER_LOGIN_SUCCESS,
    result
  }
}

export const userLoginFailure = error => {
  return {
    type: USER_LOGIN_FAILURE,
    error
  }
}

export const userLoginAction = ({ username, password }) => async dispatch => {
  const userDetails = { username, password }

  dispatch(userLoginLoading())

  try {
      await axios.post(`${CLIENT_API_URL}/users/login`,
        JSON.stringify(userDetails),
        {
          headers: { 'Content-Type': 'application/json' },
        }).then(resp => {
          console.log('Login Successful!', resp.data.data);
          setJwtTokenCookie(resp.data.data.access_token);
          setRefreshTokenCookie(resp.data.data.refresh_token);
          dispatch(userLoginSuccess(resp.data));
        }).catch(() => {
          dispatch(userLoginFailure({
            message: 'Username and password combination is invalid.'
          }))
    })
  } catch (err) {
    if(!err?.response) {
      dispatch(userLoginFailure({
        message: 'No server response'
      }))
    } else {
      dispatch(userLoginFailure({
        message: 'An error occurred while logging in user'
      }))
    }
  }
}